<template>
 <div>
          <div class="container">
              <div class="header-items">
                <!-- Your content here -->
                Monitor
              </div>
              <div class="header-items-profile">
                <!-- Your content here -->
                <div class="vl"></div>
                Hello,<span class="spaceContainer"></span>{{ user?.name }}
              </div>
        </div>
        <hr >
   </div>

    <!-- <nav
      class="bg-white border-black-200 ml-16 px-2 sm:px-4 py-2.5 rounded dark:bg-blue-600 border-b-2 "
    >
  
      <div class="flex flex-wrap items-stretch justify-between ">
          <div class="grid grid-rows-1 mr-5">
            <div class="row-span-1 text-2xl text-tiffany mt-3  ml-16">
                Monitor
        <div class="grid grid-cols-12">
          <div class="col-span-7">
            <div
          class="flex items-start"
        >
           </div>
      </div>
          <div class="col-span-5"></div>
        </div>
          </div>
          </div> -->
        <!-- <BurgerMenu class="cursor-pointer" /> -->
        <!-- <div class="flex items-center md:order-2">
          <div class="flex flex-col mr-4">
            <div class=" ">{{ user?.name }}</div>
            <div class="text-xs text-right">{{ user?.role }}</div>
          </div>
          <button
            type="button"
            class="flex mr-3 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
            id="user-menu-button"
            aria-expanded="false"
            data-dropdown-toggle="user-dropdown"
            data-dropdown-placement="bottom"
          >
            <span class="sr-only">Open user menu</span>
            <img
              class="w-8 h-8 rounded-full"
              :src="`${user?.avatar}`"
              alt="user photo"
            />
          </button>
   -->
          <!-- Dropdown menu -->
          <!-- <div
            class="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600"
            id="user-dropdown"
          >
            <div class="px-4 py-3">
              <span class="block text-sm text-gray-900 dark:text-white"
                >Bonnie Green</span
              >
              <span
                class="block text-sm font-medium text-gray-500 truncate dark:text-gray-400"
                >name@flowbite.com</span
              >
            </div> -->
            <!-- header links here -->
            <!-- <ul class="py-2" aria-labelledby="user-menu-button">
            <li>
            <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Dashboard</a>
            </li>
            <li>
            <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Settings</a>
            </li>
            <li>
            <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Earnings</a>
            </li>
            <li>
            <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Sign out</a>
            </li>
        </ul> -->
          <!-- </div>
          <button
            data-collapse-toggle="mobile-menu-2"
            type="button"
            class="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="mobile-menu-2"
            aria-expanded="false"
          >
            <span class="sr-only">Open main menu</span>
            <svg
              class="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <div
          class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
          id="mobile-menu-2"
        > -->
          <!-- <ul class="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
          <li>
            <a href="#" class="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white" aria-current="page">Home</a>
          </li>
          <li>
            <a href="#" class="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">About</a>
          </li>
          <li>
            <a href="#" class="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Services</a>
          </li>
          <li>
            <a href="#" class="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Pricing</a>
          </li>
          <li>
            <a href="#" class="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Contact</a>
          </li>
        </ul> -->
        <!-- </div>
      </div>
    </nav> -->
  </template>
<style scoped>  
 .container {
  position: relative;
  padding: 20px;
  width: 100%;
  height: auto;
  max-width: 93%;
  
  display: flex;
  justify-content: space-between;
  left: 7%;
  align-items: center;
  background-color: rgb(255, 255, 255);
  
  
}

.header-items{
  font-size: 20px;
  color: rgb(102, 201, 250);
}
.header-items-profile{
  display: flex;
  font-size: 14px;
  text-align: center;
  color: rgb(169, 169, 169);
}
.vl {
  border-left: .5px solid rgb(189, 186, 186);
  margin-right: 20px;
  height: 20px;
}
.spaceContainer{
  padding-left:9px ;
}
  </style>
 <script>
  import BurgerMenu from "../ui/BurgerMenu.vue";
  export default {
    components: { BurgerMenu },
    data() {
      return {
        user: null,
      };
    },
    mounted() {
      this.user = {
        name: localStorage.getItem("fullname"),
        role: localStorage.getItem("role"),
        avatar: localStorage.getItem("avatar"),
      };
      console.log(this.user);
    },
    //   data(){
    //    navigation : [
    //   { name: "Dashboard", href: "#", current: true },
    //   { name: "Team", href: "#", current: false },
    //   { name: "Projects", href: "#", current: false },
    //   { name: "Calendar", href: "#", current: false },
    // ];
    //   },
  };
  </script>
  <!-- <style>
  .custom-shadow {
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.07);
  }
  .bg-tiffany{
    background-color:#81d8d0;
  }
  .text-tiffany{
    text-emphasis-color:#81d8d0;
  }
  .border-gray{
    background-color:#808080
  ;
  }
  .border-r-tiffany{
    background-color:#81d8d0;
  }
  </style> -->
  <!-- <script>
  import BurgerMenu from "../ui/BurgerMenu.vue";
  export default {
    components: { BurgerMenu },
    data() {
      return {
        user: null,
      };
    },
    mounted() {
      this.user = {
        name: localStorage.getItem("fullname"),
        role: localStorage.getItem("role"),
        avatar: localStorage.getItem("avatar"),
      };
      console.log(this.user);
    },
    //   data(){
    //    navigation : [
    //   { name: "Dashboard", href: "#", current: true },
    //   { name: "Team", href: "#", current: false },
    //   { name: "Projects", href: "#", current: false },
    //   { name: "Calendar", href: "#", current: false },
    // ];
    //   },
  };
  </script> -->
  