const getSidebarMenuIcons = (key, status) => {
    if (status) {
        return sidebarMenuIcons[key]('#117abf');
    }
    return sidebarMenuIcons[key]('#ffffff');
}

module.exports = {
    getSidebarMenuIcons
}

const sidebarMenuIcons = {
    'dashboard': (fillColor) => `<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="23" height="23" viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="${fillColor}" stroke="none">
        <path d="M415 4786 c-67 -29 -105 -106 -91 -181 9 -47 59 -102 104 -115 25 -8
        329 -10 954 -8 908 3 917 3 944 24 53 39 69 71 69 134 0 63 -16 95 -69 134
        -27 21 -36 21 -954 23 -755 2 -933 0 -957 -11z"/>
        <path d="M2815 4786 c-41 -18 -83 -69 -90 -109 -3 -18 -4 -442 -3 -944 3 -903
        3 -912 24 -939 11 -15 33 -37 48 -48 27 -21 34 -21 966 -21 932 0 939 0 966
        21 15 11 37 33 48 48 21 27 21 34 21 966 0 932 0 939 -21 966 -11 15 -33 37
        -48 48 -27 21 -36 21 -954 23 -755 2 -933 0 -957 -11z m1665 -1026 l0 -720
        -720 0 -720 0 0 720 0 720 720 0 720 0 0 -720z"/>
        <path d="M415 3666 c-67 -29 -105 -106 -91 -181 9 -47 59 -102 104 -115 25 -8
        329 -10 954 -8 908 3 917 3 944 24 53 39 69 71 69 134 0 63 -16 95 -69 134
        -27 21 -36 21 -954 23 -755 2 -933 0 -957 -11z"/>
        <path d="M415 2386 c-41 -18 -83 -69 -90 -109 -3 -18 -4 -442 -3 -944 3 -903
        3 -912 24 -939 11 -15 33 -37 48 -48 27 -21 34 -21 966 -21 932 0 939 0 966
        21 15 11 37 33 48 48 21 27 21 34 21 966 0 932 0 939 -21 966 -11 15 -33 37
        -48 48 -27 21 -36 21 -954 23 -755 2 -933 0 -957 -11z m1665 -1026 l0 -720
        -720 0 -720 0 0 720 0 720 720 0 720 0 0 -720z"/>
        <path d="M2815 1906 c-67 -29 -105 -106 -91 -181 9 -47 59 -102 104 -115 25
        -8 329 -10 954 -8 908 3 917 3 944 24 53 39 69 71 69 134 0 63 -16 95 -69 134
        -27 21 -36 21 -954 23 -755 2 -933 0 -957 -11z"/>
        <path d="M2815 786 c-67 -29 -105 -106 -91 -181 9 -47 59 -102 104 -115 25 -8
        329 -10 954 -8 908 3 917 3 944 24 53 39 69 71 69 134 0 63 -16 95 -69 134
        -27 21 -36 21 -954 23 -755 2 -933 0 -957 -11z"/>
        </g>
    </svg>`,
    'monitor': (fillColor) => `<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="23" height="23" viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="${fillColor}" stroke="none">
        <path d="M415 4786 c-67 -29 -105 -106 -91 -181 9 -47 59 -102 104 -115 25 -8
        329 -10 954 -8 908 3 917 3 944 24 53 39 69 71 69 134 0 63 -16 95 -69 134
        -27 21 -36 21 -954 23 -755 2 -933 0 -957 -11z"/>
        <path d="M2815 4786 c-41 -18 -83 -69 -90 -109 -3 -18 -4 -442 -3 -944 3 -903
        3 -912 24 -939 11 -15 33 -37 48 -48 27 -21 34 -21 966 -21 932 0 939 0 966
        21 15 11 37 33 48 48 21 27 21 34 21 966 0 932 0 939 -21 966 -11 15 -33 37
        -48 48 -27 21 -36 21 -954 23 -755 2 -933 0 -957 -11z m1665 -1026 l0 -720
        -720 0 -720 0 0 720 0 720 720 0 720 0 0 -720z"/>
        <path d="M415 3666 c-67 -29 -105 -106 -91 -181 9 -47 59 -102 104 -115 25 -8
        329 -10 954 -8 908 3 917 3 944 24 53 39 69 71 69 134 0 63 -16 95 -69 134
        -27 21 -36 21 -954 23 -755 2 -933 0 -957 -11z"/>
        <path d="M415 2386 c-41 -18 -83 -69 -90 -109 -3 -18 -4 -442 -3 -944 3 -903
        3 -912 24 -939 11 -15 33 -37 48 -48 27 -21 34 -21 966 -21 932 0 939 0 966
        21 15 11 37 33 48 48 21 27 21 34 21 966 0 932 0 939 -21 966 -11 15 -33 37
        -48 48 -27 21 -36 21 -954 23 -755 2 -933 0 -957 -11z m1665 -1026 l0 -720
        -720 0 -720 0 0 720 0 720 720 0 720 0 0 -720z"/>
        <path d="M2815 1906 c-67 -29 -105 -106 -91 -181 9 -47 59 -102 104 -115 25
        -8 329 -10 954 -8 908 3 917 3 944 24 53 39 69 71 69 134 0 63 -16 95 -69 134
        -27 21 -36 21 -954 23 -755 2 -933 0 -957 -11z"/>
        <path d="M2815 786 c-67 -29 -105 -106 -91 -181 9 -47 59 -102 104 -115 25 -8
        329 -10 954 -8 908 3 917 3 944 24 53 39 69 71 69 134 0 63 -16 95 -69 134
        -27 21 -36 21 -954 23 -755 2 -933 0 -957 -11z"/>
        </g>
    </svg>`,
    'my-team': (fillColor) => `<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="23" height="23" viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="${fillColor}" stroke="none">
        <path d="M2495 4946 c-11 -8 -67 -108 -124 -224 l-104 -209 -217 -31 c-119
        -17 -228 -36 -243 -43 -35 -16 -61 -71 -53 -114 5 -26 41 -67 173 -195 l166
        -161 -39 -228 c-34 -203 -37 -230 -25 -259 15 -36 55 -62 97 -62 23 0 158 66
        375 183 l56 30 193 -102 c106 -57 207 -105 225 -108 37 -6 89 17 105 46 16 31
        12 79 -25 294 l-35 201 170 171 c147 148 170 175 170 202 0 44 -15 79 -42 97
        -13 9 -117 28 -249 47 l-226 32 -98 204 c-59 121 -109 212 -123 224 -29 23
        -98 26 -127 5z m213 -609 c15 -14 61 -25 166 -40 80 -12 146 -24 146 -28 0 -3
        -47 -51 -104 -107 -68 -66 -106 -111 -110 -130 -6 -23 17 -188 40 -290 4 -21
        -8 -17 -133 48 -111 57 -144 70 -168 65 -17 -3 -85 -33 -150 -68 -66 -35 -122
        -60 -123 -55 -2 5 6 67 18 139 13 74 19 145 16 164 -5 27 -30 56 -113 134 -59
        55 -105 102 -102 104 2 2 63 12 134 22 193 26 184 19 263 183 l67 139 66 -131
        c37 -72 76 -139 87 -149z"/>
        <path d="M789 4041 c-13 -10 -55 -82 -93 -158 l-69 -140 -155 -22 c-86 -12
        -164 -27 -174 -32 -25 -13 -48 -62 -48 -100 0 -28 17 -49 120 -153 l119 -121
        -25 -153 c-14 -84 -23 -164 -20 -178 4 -14 18 -37 31 -50 41 -41 81 -33 240
        48 l141 71 135 -71 c154 -82 188 -88 238 -46 39 32 40 66 9 251 l-23 132 112
        106 c138 130 158 179 100 243 -25 27 -56 36 -220 58 l-129 17 -74 148 c-40 82
        -82 153 -93 159 -32 16 -96 12 -122 -9z m157 -477 c13 -9 58 -21 99 -27 l74
        -11 -59 -62 c-66 -69 -71 -85 -50 -194 6 -29 8 -55 6 -57 -2 -2 -34 12 -71 31
        -37 20 -78 36 -91 36 -14 0 -56 -16 -95 -36 l-71 -35 8 53 c19 117 15 131 -55
        202 l-63 63 69 8 c104 11 126 25 170 110 l38 74 33 -70 c18 -38 44 -76 58 -85z"/>
        <path d="M4189 4041 c-13 -10 -55 -82 -93 -158 l-69 -140 -155 -22 c-86 -12
        -164 -27 -174 -32 -25 -13 -48 -62 -48 -100 0 -28 17 -49 120 -153 l119 -121
        -25 -153 c-14 -84 -23 -164 -20 -178 4 -14 18 -37 31 -50 41 -41 81 -33 240
        48 l141 71 135 -71 c154 -82 188 -88 238 -46 39 32 40 66 9 251 l-23 132 112
        106 c138 130 158 179 100 243 -25 27 -56 36 -220 58 l-129 17 -74 148 c-40 82
        -82 153 -93 159 -32 16 -96 12 -122 -9z m157 -477 c13 -9 58 -21 99 -27 l74
        -11 -59 -62 c-66 -69 -71 -85 -50 -194 6 -29 8 -55 6 -57 -2 -2 -34 12 -71 31
        -37 20 -78 36 -91 36 -14 0 -56 -16 -95 -36 l-71 -35 8 53 c19 117 15 131 -55
        202 l-63 63 69 8 c104 11 126 25 170 110 l38 74 33 -70 c18 -38 44 -76 58 -85z"/>
        <path d="M2385 3146 c-157 -36 -272 -101 -391 -220 -102 -102 -157 -189 -201
        -321 -25 -74 -27 -92 -27 -245 0 -153 2 -171 27 -245 15 -44 46 -114 68 -155
        57 -102 197 -242 299 -299 79 -43 213 -91 257 -91 45 0 19 -10 -65 -25 -264
        -46 -508 -180 -685 -377 l-66 -73 -33 31 c-48 46 -145 109 -219 143 -68 32
        -227 81 -263 81 -11 0 -27 4 -35 9 -10 6 -1 11 27 16 23 4 78 24 123 46 151
        73 270 221 313 386 21 78 21 228 0 306 -54 208 -223 377 -431 431 -78 21 -228
        21 -306 0 -208 -54 -377 -223 -431 -431 -21 -78 -21 -228 0 -306 53 -204 215
        -369 419 -428 56 -16 58 -17 30 -23 -198 -43 -328 -100 -447 -194 -125 -99
        -209 -206 -273 -345 -59 -128 -67 -172 -72 -397 l-5 -205 27 -28 28 -27 2507
        0 2507 0 28 27 27 28 -5 205 c-5 225 -13 269 -72 397 -64 139 -148 246 -273
        345 -119 94 -249 151 -447 194 -28 6 -26 7 30 23 204 59 366 224 419 428 21
        78 21 228 0 306 -54 208 -223 377 -431 431 -78 21 -228 21 -306 0 -208 -54
        -377 -223 -431 -431 -9 -34 -16 -102 -16 -153 0 -172 56 -308 175 -427 77 -77
        194 -144 277 -158 28 -5 37 -10 27 -16 -8 -5 -24 -9 -35 -9 -36 0 -195 -49
        -263 -81 -74 -34 -171 -97 -219 -143 l-33 -31 -66 73 c-179 198 -413 327 -688
        378 l-90 17 60 13 c150 31 271 98 391 218 102 102 157 189 201 321 25 74 27
        92 27 245 0 153 -2 171 -27 245 -44 131 -99 219 -201 321 -102 102 -191 158
        -321 200 -67 23 -102 27 -220 30 -95 2 -159 -1 -200 -10z m320 -211 c105 -27
        183 -73 270 -160 124 -123 175 -245 175 -415 0 -170 -51 -292 -175 -415 -123
        -124 -245 -175 -415 -175 -170 0 -292 51 -415 175 -124 123 -175 245 -175 415
        0 170 51 292 175 415 85 86 164 133 265 159 73 19 221 20 295 1z m-1609 -620
        c76 -37 152 -114 190 -190 26 -55 29 -69 29 -165 0 -96 -3 -110 -29 -165 -38
        -77 -114 -153 -191 -191 -55 -26 -69 -29 -165 -29 -96 0 -110 3 -165 29 -77
        38 -153 114 -191 191 -26 55 -29 69 -29 165 0 94 3 111 27 160 36 73 86 130
        148 170 75 48 122 60 226 57 76 -2 100 -7 150 -32z m3260 0 c76 -37 152 -114
        190 -190 26 -55 29 -69 29 -165 0 -96 -3 -110 -29 -165 -38 -77 -114 -153
        -191 -191 -55 -26 -69 -29 -165 -29 -96 0 -110 3 -165 29 -77 38 -153 114
        -191 191 -26 55 -29 69 -29 165 0 94 3 111 27 160 36 73 86 130 148 170 75 48
        122 60 226 57 76 -2 100 -7 150 -32z m-1537 -995 c148 -42 304 -129 415 -231
        183 -167 316 -451 316 -676 l0 -53 -990 0 -990 0 0 53 c0 180 84 399 217 567
        132 168 321 288 538 344 94 24 101 25 265 21 122 -2 171 -8 229 -25z m-1647
        -207 c105 -36 180 -80 256 -149 l57 -52 -14 -33 c-72 -178 -111 -337 -111
        -454 l0 -65 -581 0 -581 0 7 98 c11 163 48 272 129 386 102 142 242 239 418
        287 60 16 97 18 203 15 112 -3 142 -8 217 -33z m3241 4 c236 -77 422 -272 481
        -504 8 -32 18 -102 21 -155 l7 -98 -581 0 -581 0 0 65 c0 117 -39 276 -111
        454 l-14 33 57 52 c72 66 148 111 238 142 109 38 144 43 280 40 101 -2 140 -8
        203 -29z"/>
        <path d="M2486 1134 c-21 -21 -26 -36 -26 -74 0 -66 34 -100 100 -100 66 0
        100 34 100 100 0 38 -5 53 -26 74 -21 21 -36 26 -74 26 -38 0 -53 -5 -74 -26z"/>
        <path d="M2874 1016 c-26 -26 -34 -42 -34 -71 0 -34 9 -46 103 -139 63 -61
        116 -123 135 -158 38 -68 64 -88 115 -88 52 0 85 24 97 71 9 33 8 47 -10 85
        -31 72 -102 165 -179 236 -115 107 -169 122 -227 64z"/>
        </g>
    </svg>`,
    'manage-employee': (fillColor) => `<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="23" height="23" viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="${fillColor}" stroke="none">
        <path d="M2235 4959 c-45 -12 -110 -72 -134 -122 -19 -39 -21 -61 -21 -228 l0
        -184 -82 -28 c-46 -16 -117 -46 -158 -67 l-75 -38 -130 128 c-115 113 -136
        130 -184 144 -68 20 -94 20 -161 0 -49 -15 -71 -33 -252 -212 -224 -221 -248
        -256 -248 -356 1 -91 24 -130 159 -269 l121 -124 -39 -86 c-22 -47 -49 -117
        -62 -155 l-22 -71 -198 -3 -197 -3 -43 -30 c-24 -17 -57 -50 -74 -74 l-30 -43
        -3 -311 c-2 -263 0 -318 13 -353 28 -73 104 -133 188 -148 l37 -7 0 86 c0 80
        -1 86 -23 94 -50 19 -52 29 -55 270 -4 252 1 304 29 332 18 18 35 19 253 19
        l234 0 12 53 c30 125 108 311 177 420 l25 39 -166 167 c-137 139 -166 172
        -166 196 0 24 31 60 188 217 103 104 197 191 209 194 35 9 60 -11 224 -173
        l156 -154 53 31 c118 69 257 128 393 166 l57 15 0 207 c0 216 8 275 40 292 25
        13 535 13 560 0 32 -17 40 -76 40 -292 l0 -207 58 -15 c135 -38 274 -97 392
        -166 l53 -31 156 154 c164 162 189 182 224 173 12 -3 106 -90 210 -194 156
        -157 187 -193 187 -217 0 -24 -29 -57 -166 -195 l-166 -167 31 -50 c56 -90
        119 -236 153 -350 l33 -113 233 0 c216 0 233 -1 251 -19 28 -28 33 -80 29
        -332 -3 -241 -5 -251 -55 -270 -22 -8 -23 -14 -23 -94 l0 -86 38 7 c81 15 150
        67 183 139 17 36 19 71 19 344 0 289 -1 307 -21 348 -25 53 -86 106 -138 121
        -23 7 -109 12 -213 12 l-174 0 -18 58 c-10 31 -38 102 -62 156 l-44 99 121
        124 c135 139 158 178 159 269 0 100 -24 135 -248 356 -181 179 -203 197 -252
        212 -67 20 -93 20 -161 0 -48 -14 -69 -31 -184 -143 l-130 -128 -75 38 c-41
        20 -112 50 -157 66 l-83 28 0 184 c0 167 -2 189 -21 228 -25 53 -86 106 -138
        121 -46 14 -599 15 -646 1z"/>
        <path d="M2357 3915 c-459 -87 -815 -441 -902 -898 -8 -43 -15 -110 -15 -149
        l0 -69 83 3 82 3 7 86 c30 394 317 734 710 841 120 33 356 33 476 0 393 -107
        680 -447 710 -841 l7 -86 83 -3 82 -3 0 69 c0 247 -129 541 -324 735 -134 134
        -329 246 -511 293 -127 33 -364 42 -488 19z"/>
        <path d="M2420 3111 c-139 -45 -236 -129 -298 -258 -33 -71 -37 -87 -40 -179
        -6 -160 34 -266 140 -372 176 -176 479 -183 659 -15 118 109 164 224 157 390
        -3 88 -7 106 -41 176 -83 176 -246 278 -441 276 -49 0 -103 -7 -136 -18z m272
        -179 c157 -77 224 -267 148 -420 -118 -234 -442 -234 -560 0 -81 164 1 366
        177 433 58 22 177 15 235 -13z"/>
        <path d="M1060 2791 c-139 -45 -236 -129 -298 -258 -33 -71 -37 -87 -40 -179
        -6 -160 34 -266 140 -372 176 -176 479 -183 659 -15 118 109 164 224 157 390
        -3 88 -7 106 -41 176 -83 176 -246 278 -441 276 -49 0 -103 -7 -136 -18z m272
        -179 c157 -77 224 -267 148 -420 -118 -234 -442 -234 -560 0 -81 164 1 366
        177 433 58 22 177 15 235 -13z"/>
        <path d="M3780 2791 c-139 -45 -236 -129 -298 -258 -33 -71 -37 -87 -40 -179
        -6 -160 34 -266 140 -372 176 -176 479 -183 659 -15 118 109 164 224 157 390
        -3 88 -7 106 -41 176 -83 176 -246 278 -441 276 -49 0 -103 -7 -136 -18z m272
        -179 c157 -77 224 -267 148 -420 -118 -234 -442 -234 -560 0 -81 164 1 366
        177 433 58 22 177 15 235 -13z"/>
        <path d="M2062 2071 c-145 -36 -289 -128 -383 -244 l-46 -58 -424 1 c-394 0
        -430 -1 -504 -20 -212 -53 -380 -190 -475 -385 -65 -134 -72 -179 -68 -494 3
        -258 5 -280 26 -336 47 -126 143 -237 262 -303 118 -65 120 -66 618 -70 l452
        -3 0 85 0 86 -360 0 -360 0 0 440 0 440 -80 0 -80 0 0 -434 0 -435 -30 9 c-56
        16 -131 64 -176 112 -96 103 -119 206 -111 493 4 147 9 193 27 250 44 140 145
        264 269 329 121 63 143 66 559 66 l374 0 -6 -22 c-24 -86 -27 -148 -24 -618
        l3 -505 27 -57 c54 -116 169 -204 297 -228 29 -5 112 -10 182 -10 l129 0 0
        645 0 645 -80 0 -80 0 0 -560 0 -560 -48 0 c-108 0 -190 45 -238 132 l-29 53
        0 470 0 470 28 78 c51 145 144 257 267 321 121 64 140 66 580 66 435 0 456 -2
        572 -61 73 -37 167 -123 214 -196 19 -28 46 -87 61 -130 l28 -78 0 -470 0
        -470 -29 -53 c-48 -87 -130 -132 -238 -132 l-48 0 0 560 0 560 -80 0 -80 0 0
        -645 0 -645 129 0 c70 0 153 5 182 10 128 24 243 112 297 228 l27 57 3 507 c2
        430 0 516 -13 567 -8 32 -15 62 -15 65 0 3 168 6 373 6 409 0 433 -3 549 -61
        73 -37 167 -123 214 -196 19 -28 45 -87 60 -130 23 -69 27 -98 31 -258 8 -288
        -15 -391 -111 -493 -45 -48 -120 -96 -176 -112 l-30 -9 0 435 0 434 -80 0 -80
        0 0 -440 0 -440 -360 0 -360 0 0 -86 0 -85 453 3 c400 4 458 6 507 22 181 58
        321 196 375 368 24 76 25 90 25 342 0 229 -3 273 -20 340 -64 252 -267 451
        -525 516 -74 19 -110 20 -504 20 l-424 -1 -54 66 c-96 116 -223 195 -377 235
        -74 19 -109 20 -500 19 -369 0 -430 -3 -494 -18z"/>
        <path d="M2240 245 l0 -85 320 0 320 0 0 85 0 85 -320 0 -320 0 0 -85z"/>
        </g>
    </svg>`,
    'attendance': (fillColor) => `<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="23" height="23" viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="${fillColor}" stroke="none">
        <path d="M1904 5059 c-509 -49 -874 -375 -979 -874 -26 -122 -31 -328 -11
        -462 38 -255 131 -482 287 -700 33 -45 59 -83 59 -86 0 -2 -21 -12 -47 -22
        -83 -31 -261 -123 -346 -178 -458 -299 -751 -779 -842 -1377 -20 -137 -30
        -420 -17 -538 12 -114 19 -123 165 -210 511 -303 990 -476 1498 -538 162 -21
        526 -23 679 -5 350 41 670 125 990 261 74 31 152 66 174 77 38 20 39 20 115 1
        100 -26 347 -35 462 -18 185 26 391 115 551 235 384 289 558 788 439 1257
        -115 455 -485 804 -948 894 -119 23 -330 24 -446 1 -96 -19 -235 -66 -310
        -103 l-48 -25 -106 71 c-115 77 -223 135 -345 185 l-77 31 57 77 c291 392 385
        900 244 1317 -61 179 -150 319 -283 443 -175 165 -395 260 -668 287 -113 11
        -128 11 -247 -1z m346 -255 c516 -106 784 -606 635 -1189 -97 -381 -409 -753
        -709 -844 -128 -39 -266 -24 -395 44 -253 134 -478 418 -580 732 -45 141 -56
        214 -56 383 1 126 5 172 23 245 29 117 106 273 176 357 198 236 554 343 906
        272z m-714 -2120 c275 -197 568 -227 847 -88 58 29 122 70 209 133 23 17 77 3
        228 -61 96 -40 273 -139 285 -158 4 -6 -30 -49 -74 -95 -294 -307 -403 -751
        -285 -1164 33 -116 119 -288 193 -387 67 -90 189 -210 269 -264 28 -19 52 -37
        52 -40 0 -8 -173 -75 -297 -116 -679 -222 -1367 -202 -2039 62 -164 64 -363
        160 -531 256 -170 97 -159 72 -150 327 11 319 71 571 197 821 186 370 518 661
        910 800 47 16 90 30 97 30 6 0 47 -25 89 -56z m2489 -135 c227 -28 434 -134
        592 -302 80 -86 116 -139 169 -251 66 -140 88 -242 88 -411 0 -111 -4 -158
        -22 -229 -73 -291 -289 -547 -560 -661 -143 -60 -199 -70 -387 -70 -147 0
        -181 3 -249 23 -241 69 -455 233 -580 440 -56 94 -110 244 -127 352 -16 104
        -6 294 21 395 110 412 491 712 920 724 25 0 86 -4 135 -10z"/>
        <path d="M4395 2029 c-11 -6 -155 -146 -320 -310 l-300 -299 -165 166 c-91 91
        -177 172 -192 180 -99 52 -207 -63 -154 -164 18 -32 432 -445 463 -461 28 -14
        68 -14 96 0 31 16 715 698 733 731 18 35 18 62 -2 104 -27 56 -105 82 -159 53z"/>
        </g>
    </svg>`,
    'task': (fillColor) => `<svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 512 512" version="1.1">
        <path d="" stroke="none" fill="#080404" fill-rule="evenodd"/>
        <path d="M 235.324 2.873 C 221.017 7.853, 208.584 18.897, 202.814 31.750 L 200.906 36 181.703 36.022 C 165.027 36.041, 161.951 36.293, 158.331 37.937 C 156.038 38.978, 153.675 40.739, 153.081 41.849 C 152.486 42.960, 152 49.041, 152 55.366 L 152 66.863 123.250 67.206 C 96.048 67.531, 94.191 67.673, 88.764 69.841 C 70.925 76.969, 58.673 91.695, 
        55.423 109.916 C 54.320 116.097, 54.069 149.500, 54.066 290.500 C 54.063 394.007, 54.443 466.439, 55.012 470.817 C 57.380 489.054, 70.163 503.488, 89.302 509.535 L 97.103 512 255.802 511.988 C 391.814 511.978, 415.285 511.767, 419.992 510.515 C 440.006 505.189, 454.552 489.581, 456.988 470.817 C 457.557 466.439, 457.937 394.007, 457.934 290.500 C 457.931 149.500, 
        457.680 116.097, 456.577 109.916 C 453.327 91.695, 441.075 76.969, 423.236 69.841 C 417.809 67.673, 415.952 67.531, 388.750 67.206 L 360 66.863 360 55.068 C 360 43.672, 359.911 43.184, 357.377 40.649 C 353.421 36.694, 349.218 36.012, 328.760 36.006 L 310.021 36 306.760 29.250 C 302.462 20.351, 292.783 10.604, 283.947 6.278 C 274.817 1.807, 266.302 0.030, 
        254 0.028 C 245.039 0.027, 242.302 0.444, 235.324 2.873 M 245.300 24.862 C 234.224 28.689, 226.802 36.469, 222.907 48.335 C 221.353 53.067, 219.699 55.936, 217.315 58.028 L 213.930 61 195.465 61 L 177 61 177 85 L 177 109 256 109 L 335 109 335 85 L 335 61 316.250 60.996 C 291.934 60.991, 291.844 60.945, 286.534 45.826 C 283.002 35.768, 274.134 27.425, 
        263.934 24.562 C 256.765 22.550, 251.759 22.630, 245.300 24.862 M 98.994 92.934 C 91.565 95.194, 85.376 100.706, 82.079 108 C 80.057 112.471, 80.044 113.651, 80.022 291.282 C 80.004 440.427, 80.227 470.608, 81.370 473.343 C 82.123 475.146, 84.651 478.257, 86.986 480.256 C 95.440 487.493, 83.085 487, 256.141 487 C 409.309 487, 412.014 486.967, 
        417.253 485.006 C 423.002 482.856, 429.219 477.259, 430.940 472.685 C 431.672 470.740, 431.993 414.615, 431.978 291.185 C 431.956 113.652, 431.943 112.471, 429.921 108 C 427.431 102.491, 422.816 97.536, 417.500 94.662 C 413.732 92.625, 411.950 92.482, 386.750 92.190 L 360 91.881 359.996 108.190 C 359.992 126.485, 359.192 129.209, 
        352.794 132.701 C 349.705 134.388, 343.937 134.519, 260 134.805 C 210.775 134.972, 168.089 134.822, 165.142 134.469 C 160.981 133.972, 158.971 133.084, 156.142 130.494 L 152.500 127.158 152.186 109.579 L 151.871 92 126.686 92.086 C 112.833 92.133, 100.372 92.515, 98.994 92.934 M 194.338 172.285 C 193.149 172.953, 183.448 181.895, 
        172.781 192.156 L 153.386 210.813 143.440 200.998 C 132.182 189.888, 129.038 188.465, 122.553 191.542 C 118.087 193.661, 116 197.458, 116 203.465 C 116 207.974, 116.175 208.213, 130.621 223.409 C 138.663 231.868, 146.334 239.287, 147.668 239.895 C 151.220 241.513, 156.129 241.234, 159.116 239.243 C 164.130 235.903, 211.211 190.575, 
        212.102 188.231 C 214.498 181.931, 212.038 175.622, 205.961 172.480 C 202.527 170.704, 197.308 170.617, 194.338 172.285 M 236.898 203.283 C 232.111 206.498, 230.115 212.388, 232.071 217.527 C 232.858 219.594, 234.771 222.234, 236.324 223.393 C 239.126 225.485, 239.664 225.502, 312.632 225.769 C 379.270 226.013, 386.442 225.884, 
        389.603 224.384 C 393.760 222.411, 396.250 218.055, 396.179 212.879 C 396.112 208.018, 394.643 205.363, 390.686 202.950 C 387.608 201.073, 385.013 201.007, 313.898 201.004 L 240.296 201 236.898 203.283 M 195.500 269.401 C 193.850 270.165, 183.725 279.158, 173 289.387 C 162.275 299.615, 153.203 307.987, 152.840 307.992 C 152.477 307.996, 
        148.652 304.247, 144.340 299.660 C 134.127 288.796, 132.230 287.601, 126.263 288.274 C 119.649 289.019, 116 293.141, 116 299.866 C 116 306.103, 117.990 308.802, 134.629 325.133 C 148.590 338.835, 151.133 340.252, 157.146 337.678 C 160.641 336.183, 209.589 290.409, 211.715 286.648 C 214.474 281.768, 212.478 273.853, 207.697 270.721 C 204.110 268.371, 
        198.934 267.810, 195.500 269.401 M 240.258 298.705 C 237.107 299.312, 232.285 304.428, 231.501 307.993 C 230.589 312.148, 232.564 317.455, 236.143 320.467 L 239.153 323 314 323 L 388.847 323 391.894 320.436 C 399.372 314.144, 397.105 302.636, 387.685 299.067 C 384.955 298.032, 245.520 297.690, 240.258 298.705 M 193.856 367.757 C 192.403 368.716, 182.690 377.684, 
        172.273 387.686 L 153.332 405.871 143.938 396.438 C 134.442 386.902, 130.088 384.279, 125.766 385.490 C 118.694 387.473, 116 391.052, 116 398.462 C 116 402.959, 116.202 403.237, 130.250 418.093 C 138.088 426.381, 145.715 433.800, 147.200 434.581 C 150.476 436.302, 155.736 436.364, 158.636 434.715 C 159.810 434.047, 172.265 422.573, 186.313 409.219 C 213.142 383.714, 
        213.920 382.717, 212.581 375.582 C 211.139 367.894, 200.432 363.420, 193.856 367.757 M 236.898 398.283 C 232.111 401.498, 230.115 407.388, 232.071 412.527 C 232.858 414.594, 234.771 417.234, 236.324 418.393 C 239.126 420.485, 239.664 420.502, 312.632 420.769 C 379.270 421.013, 386.442 420.884, 389.603 419.384 C 398.244 415.284, 398.426 401.858, 389.901 397.449 C 387.529 396.222, 375.837 396, 313.698 396 L 240.296 396 236.898 398.283" 
        stroke="none" fill="${fillColor}" fill-rule="evenodd"/>
    </svg>`,
    'poi': (fillColor) => `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="23" height="23" viewBox="0 0 122.88 120.666" enable-background="new 0 0 122.88 120.666" xml:space="preserve">
        <g fill="${fillColor}"><path d="M26.942,46.192c0.04,0.035,0.079,0.072,0.117,0.11l21.135,19.64c0.753,0.7,1.013,1.731,0.766,2.661h0.002 c-1.81,6.775-3.228,14.056-2.693,20.8c0.492,6.213,2.675,12.026,7.847,16.608l18.362-18.362c0.959-0.959,2.461-1.043,3.515-0.253 l35.886,
        22.268L89.485,73.576c-0.668-1.079-0.477-2.447,0.385-3.307l-0.007-0.007l18.362-18.361 c-4.582-5.172-10.395-7.354-16.608-7.846c-6.744-0.535-14.025,0.884-20.801,2.692c-0.969,0.259-1.956-0.046-2.613-0.715 L47.102,26.391c-0.062-0.058-0.121-0.118-0.177-0.181L26.942,
        46.192L26.942,46.192z M1.19,40.442L40.432,1.2l0.01,0.01 c0.021-0.021,0.043-0.042,0.064-0.062C41.312,0.393,42.301,0,43.31,0c1.023,0,2.023,0.391,2.838,1.2l11.66,11.569 c0.775,0.769,1.133,1.822,1.133,
        2.831c0,0.988-0.36,2.042-1.018,2.78l-5.234,5.872l18.18,16.924 c6.854-1.752,14.174-3.038,21.168-2.484c7.783,0.617,15.087,3.478,20.822,10.377l0.015,0.018l0.003-0.003 c-0.007-0.013-0.015-0.028,0.007-0.007c0.156,0.149,0.071,0.056,0.198,
        0.183c0.259,0.259,0.485,0.509,0.674,0.746 c0.494,
        0.622,0.749,1.276,0.749,1.954c0,0.638-0.258,1.33-0.763,1.965c-0.127,0.159-0.297,0.352-0.508,0.57 c-0.048,0.057-0.099,0.112-0.152,0.166L95.174,72.568l27.276,43.958c0.544,0.843,0.591,1.957,0.029,
        2.866 c-0.778,1.261-2.432,1.651-3.691,0.873L74.782,92.96l-17.908,17.909l-0.005-0.005c-0.286,0.285-0.533,0.508-0.729,0.664 c-0.637,0.506-1.331,0.764-1.967,
        0.764c-0.679,0-1.332-0.255-1.954-0.749c-0.237-0.188-0.487-0.415-0.747-0.674 c-0.126-0.126-0.034-0.042-0.183-0.198c-0.017-0.018,0.016,0.006-0.008-0.014l0.005-0.006l-0.005-0.005 c-6.899-5.736-9.76-13.04-10.377-20.822c-0.551-6.951,
        0.716-14.225,2.452-21.041L25.128,51.844l-6.747,6.079 c-0.739,0.667-1.799,1.018-2.784,1.018c-1.013,0-2.082-0.362-2.837-1.123L1.19,46.158C0.405,45.366,0,44.335,0,43.301 C0,42.271,0.39,41.243,1.19,40.442L1.19,40.442z M112.875,49.085c0.015,0.016,0.009,0.007,0.003-0.003L112.875,49.085 L112.875,49.085z"/>
        </g>
    </svg>`,
    'geo-fence': (fillColor) => `<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="23" height="23" viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="${fillColor}" stroke="none">
        <path d="M2390 4810 c-456 -57 -835 -322 -1039 -728 -107 -213 -161 -493 -132
        -680 42 -271 291 -792 704 -1472 41 -69 75 -126 74 -127 -1 0 -67 -8 -147 -17
        -671 -76 -1190 -249 -1412 -470 -29 -30 -67 -78 -83 -107 -27 -49 -30 -63 -30
        -144 0 -81 3 -95 30 -144 167 -303 815 -529 1725 -602 200 -16 760 -16 960 0
        911 73 1558 299 1725 602 27 49 30 63 30 144 0 69 -5 98 -19 125 -100 188
        -351 338 -757 455 -219 63 -586 128 -866 153 l-83 7 94 155 c414 688 645 1173
        686 1445 21 136 -8 366 -66 527 -69 191 -165 341 -318 494 -248 249 -537 375
        -890 388 -61 3 -145 1 -186 -4z m405 -114 c337 -73 641 -297 810 -596 117
        -206 181 -486 155 -673 -40 -279 -281 -773 -777 -1587 -274 -448 -440 -713
        -448 -712 -10 0 -149 221 -455 723 -498 816 -744 1322 -775 1599 -9 76 10 247
        39 356 135 504 585 879 1094 913 116 7 261 -2 357 -23z m400 -2996 c548 -51
        1059 -191 1315 -363 120 -80 200 -189 200 -272 0 -196 -314 -395 -825 -523
        -396 -100 -792 -142 -1325 -142 -533 0 -929 42 -1325 142 -511 128 -825 327
        -825 523 0 84 80 192 203 274 188 127 565 249 967 315 155 25 401 56 452 56
        24 0 45 -31 236 -342 115 -189 221 -356 236 -371 25 -27 28 -28 49 -14 12 8
        34 34 48 58 78 128 338 553 375 610 37 59 45 66 70 63 16 -2 83 -8 149 -14z"/>
        <path d="M2421 4170 c-146 -31 -280 -106 -370 -207 -63 -71 -126 -190 -146
        -278 -20 -86 -20 -224 0 -310 8 -37 33 -102 55 -144 147 -283 468 -414 775
        -317 213 67 374 241 431 466 24 96 16 272 -18 362 -74 198 -230 349 -423 408
        -79 24 -234 35 -304 20z m283 -110 c176 -55 328 -218 371 -401 71 -303 -124
        -610 -430 -674 -88 -18 -132 -18 -219 0 -190 40 -341 168 -413 351 -25 62 -27
        82 -28 194 0 115 3 131 29 195 81 197 244 327 451 359 53 8 174 -4 239 -24z"/>
        </g> 
    </svg>`,
    'visit': (fillColor) => `<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="23" height="23" viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="${fillColor}" stroke="none">
        <path d="M415 4786 c-67 -29 -105 -106 -91 -181 9 -47 59 -102 104 -115 25 -8
        329 -10 954 -8 908 3 917 3 944 24 53 39 69 71 69 134 0 63 -16 95 -69 134
        -27 21 -36 21 -954 23 -755 2 -933 0 -957 -11z"/>
        <path d="M2815 4786 c-41 -18 -83 -69 -90 -109 -3 -18 -4 -442 -3 -944 3 -903
        3 -912 24 -939 11 -15 33 -37 48 -48 27 -21 34 -21 966 -21 932 0 939 0 966
        21 15 11 37 33 48 48 21 27 21 34 21 966 0 932 0 939 -21 966 -11 15 -33 37
        -48 48 -27 21 -36 21 -954 23 -755 2 -933 0 -957 -11z m1665 -1026 l0 -720
        -720 0 -720 0 0 720 0 720 720 0 720 0 0 -720z"/>
        <path d="M415 3666 c-67 -29 -105 -106 -91 -181 9 -47 59 -102 104 -115 25 -8
        329 -10 954 -8 908 3 917 3 944 24 53 39 69 71 69 134 0 63 -16 95 -69 134
        -27 21 -36 21 -954 23 -755 2 -933 0 -957 -11z"/>
        <path d="M415 2386 c-41 -18 -83 -69 -90 -109 -3 -18 -4 -442 -3 -944 3 -903
        3 -912 24 -939 11 -15 33 -37 48 -48 27 -21 34 -21 966 -21 932 0 939 0 966
        21 15 11 37 33 48 48 21 27 21 34 21 966 0 932 0 939 -21 966 -11 15 -33 37
        -48 48 -27 21 -36 21 -954 23 -755 2 -933 0 -957 -11z m1665 -1026 l0 -720
        -720 0 -720 0 0 720 0 720 720 0 720 0 0 -720z"/>
        <path d="M2815 1906 c-67 -29 -105 -106 -91 -181 9 -47 59 -102 104 -115 25
        -8 329 -10 954 -8 908 3 917 3 944 24 53 39 69 71 69 134 0 63 -16 95 -69 134
        -27 21 -36 21 -954 23 -755 2 -933 0 -957 -11z"/>
        <path d="M2815 786 c-67 -29 -105 -106 -91 -181 9 -47 59 -102 104 -115 25 -8
        329 -10 954 -8 908 3 917 3 944 24 53 39 69 71 69 134 0 63 -16 95 -69 134
        -27 21 -36 21 -954 23 -755 2 -933 0 -957 -11z"/>
        </g>
    </svg>`,
    'leave': (fillColor) => `<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="23" height="23" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="${fillColor}" stroke="none">
        <path d="M307 4469 c-94 -22 -191 -92 -246 -177 -64 -101 -61 -8 -61 -1732 0
        -996 4 -1581 10 -1608 28 -119 103 -213 218 -269 l76 -38 864 -3 c758 -2 872
        -1 925 13 137 35 234 126 282 264 l12 36 1245 5 1245 5 69 35 c49 25 79 49
        106 85 70 91 69 83 66 736 l-3 584 -27 50 c-14 28 -47 70 -73 94 -90 83 71 75
        -1690 81 l-1570 5 -6 140 c-8 186 -36 259 -141 364 -107 107 -247 148 -468
        138 -212 -11 -338 -81 -434 -240 -50 -84 -66 -168 -66 -341 l0 -146 -245 0
        -245 0 0 783 c0 508 4 795 10 819 16 56 69 119 125 147 l49 26 866 0 c824 0
        867 -1 900 -19 45 -23 97 -73 118 -111 15 -27 18 -99 22 -686 5 -628 6 -658
        24 -678 24 -26 76 -28 105 -3 l22 17 -3 675 -3 675 -32 60 c-57 109 -143 180
        -258 209 -51 14 -171 16 -905 15 -465 -1 -862 -5 -883 -10z m1085 -1364 c62
        -22 142 -95 176 -159 24 -46 27 -63 30 -187 l4 -135 -40 -12 c-57 -17 -136
        -92 -169 -160 l-28 -57 -3 -505 -3 -505 -159 -161 c-88 -88 -160 -168 -160
        -177 0 -29 22 -65 47 -76 17 -8 195 -11 583 -11 308 0 560 -2 560 -5 0 -32
        -72 -111 -130 -141 -33 -18 -76 -19 -900 -19 l-866 0 -49 26 c-56 28 -109 91
        -125 147 -6 24 -10 285 -10 735 l0 697 479 0 c493 0 531 3 551 40 14 27 12 63
        -4 86 -13 17 -31 19 -198 24 l-183 5 0 160 c0 151 1 163 26 215 14 31 40 72
        57 91 37 42 115 87 174 99 68 14 285 5 340 -15z m3498 -647 c18 -13 43 -36 54
        -51 21 -28 21 -38 24 -596 l2 -567 -23 -44 c-16 -30 -37 -51 -67 -67 l-43 -23
        -1770 0 -1771 0 107 109 107 108 0 509 c0 509 0 510 23 554 12 25 37 55 56 67
        l34 23 1617 0 1617 0 33 -22z"/>
        <path d="M421 1964 c-12 -15 -21 -36 -21 -48 0 -27 33 -64 63 -71 12 -3 172
        -5 355 -3 l334 3 19 24 c25 30 24 76 -1 101 -19 19 -33 20 -374 20 l-355 0
        -20 -26z"/>
        <path d="M421 1644 c-12 -15 -21 -37 -21 -50 0 -28 30 -61 62 -69 13 -3 173
        -5 356 -3 l334 3 19 24 c25 30 24 76 -1 101 -19 19 -33 20 -374 20 l-355 0
        -20 -26z"/>
        <path d="M421 1324 c-26 -33 -27 -65 -2 -95 19 -24 19 -24 256 -24 237 0 237
        0 256 24 26 32 24 73 -4 99 -23 21 -31 22 -254 22 l-232 0 -20 -26z"/>
        <path d="M1883 2198 c-41 -20 -44 -46 -41 -405 3 -319 4 -342 22 -362 18 -20
        29 -21 168 -21 135 0 150 2 168 20 25 25 26 71 1 101 -17 22 -29 24 -115 27
        l-96 4 0 303 0 304 -26 20 c-29 23 -48 25 -81 9z"/>
        <path d="M2403 2200 c-53 -22 -54 -29 -51 -405 l3 -347 24 -19 c21 -17 41 -19
        187 -19 151 0 166 2 184 20 33 33 30 75 -6 108 -23 20 -35 22 -135 22 l-109 0
        0 85 0 85 99 0 c89 0 103 2 125 23 36 32 39 74 6 107 -17 17 -33 20 -125 20
        l-106 0 3 88 3 87 116 3 c101 2 119 5 134 22 25 28 23 76 -4 103 -20 21 -32
        22 -174 24 -83 1 -162 -2 -174 -7z"/>
        <path d="M3188 2195 c-20 -11 -35 -37 -62 -107 -153 -397 -226 -596 -226 -614
        0 -30 41 -64 77 -64 38 0 62 26 89 98 l21 57 145 3 145 3 23 -63 c29 -82 44
        -98 90 -98 45 0 70 24 70 67 0 17 -54 176 -121 354 -95 254 -127 330 -150 352
        -33 30 -61 33 -101 12z m103 -397 l28 -78 -84 0 c-47 0 -85 2 -85 5 0 5 31 89
        71 190 l11 30 15 -35 c9 -19 28 -70 44 -112z"/>
        <path d="M3574 2198 c-12 -5 -27 -22 -34 -36 -10 -23 2 -64 106 -366 78 -225
        126 -349 141 -363 14 -15 34 -23 57 -23 66 0 75 16 196 383 101 305 111 341
        100 367 -15 36 -62 57 -95 42 -34 -16 -44 -38 -125 -291 -43 -132 -76 -218
        -80 -210 -4 8 -42 115 -85 239 -91 265 -110 293 -181 258z"/>
        <path d="M4320 2198 c-48 -25 -50 -41 -50 -384 0 -331 4 -375 40 -394 25 -14
        326 -13 351 1 48 25 44 105 -7 128 -14 6 -72 11 -129 11 l-105 0 0 85 0 85 95
        0 c106 0 139 10 155 49 12 30 1 73 -22 88 -7 4 -60 10 -118 13 l-105 5 0 85 0
        85 116 3 c126 3 140 9 154 63 5 20 0 33 -18 55 l-25 29 -154 3 c-110 2 -160
        -1 -178 -10z"/>
        <path d="M970 3978 c-33 -17 -51 -35 -68 -68 -22 -42 -23 -55 -20 -175 3 -118
        6 -136 31 -187 30 -62 83 -115 149 -150 63 -33 203 -33 270 0 60 30 120 90
        150 150 20 41 23 65 26 192 3 138 2 147 -20 179 -50 74 -57 76 -276 79 -189 3
        -199 2 -242 -20z m390 -237 c0 -96 -3 -112 -23 -142 -39 -58 -83 -81 -148 -77
        -114 8 -158 73 -159 231 l0 97 165 0 165 0 0 -109z"/>
        </g>
    </svg>`,
    'expense': (fillColor) => `<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="23" height="23" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="${fillColor}" stroke="none">
        <path d="M4420 5031 c-14 -5 -36 -14 -49 -22 -27 -16 -427 -547 -442 -586 -22
        -60 3 -126 63 -163 25 -15 51 -20 106 -20 l72 0 0 -42 c0 -65 -27 -165 -61
        -229 -40 -74 -102 -138 -189 -195 -72 -46 -88 -71 -76 -118 8 -34 360 -296
        397 -296 36 0 164 96 251 188 151 161 254 366 288 572 21 133 11 120 89 120
        80 0 125 23 154 80 35 68 26 86 -186 372 -108 145 -206 274 -218 287 -46 49
        -133 72 -199 52z m255 -388 l176 -238 -87 -5 c-102 -6 -124 -23 -124 -95 0
        -147 -56 -343 -136 -477 -48 -79 -178 -228 -235 -269 l-28 -20 -88 66 -89 66
        76 77 c132 134 200 315 187 499 -10 133 -27 152 -139 153 -43 0 -78 2 -78 5 0
        3 79 111 175 240 123 165 180 235 194 235 14 0 71 -70 196 -237z"/>
        <path d="M2379 4707 c-25 -7 -66 -25 -90 -39 -25 -15 -395 -333 -822 -707
        l-777 -681 -48 0 c-210 0 -412 -126 -502 -314 -21 -45 -43 -108 -49 -140 -15
        -83 -15 -2209 0 -2292 35 -194 186 -361 389 -430 53 -18 123 -19 1880 -19
        1757 0 1827 1 1880 19 182 62 305 182 372 363 20 54 22 81 26 354 l4 297 54 7
        c74 9 182 65 234 121 23 25 56 75 73 112 l32 67 0 255 0 255 -32 67 c-17 37
        -50 87 -73 112 -52 56 -160 112 -235 121 l-55 7 0 262 c0 144 -5 289 -11 322
        -17 97 -78 211 -154 287 -76 77 -190 138 -289 156 -33 6 -178 11 -322 11
        l-263 0 -3 288 c-3 273 -4 290 -26 344 -34 84 -107 160 -200 205 -72 36 -81
        38 -172 38 -95 0 -96 0 -204 -52 -59 -29 -110 -53 -112 -53 -2 0 -4 69 -4 153
        0 175 -13 242 -63 326 -66 110 -183 180 -315 187 -45 2 -96 -1 -123 -9z m207
        -175 c45 -23 84 -63 110 -112 16 -30 19 -63 22 -242 l3 -207 -691 -346 -691
        -345 -200 2 -200 3 695 610 c404 354 718 622 748 638 45 25 61 28 109 24 30
        -3 73 -14 95 -25z m695 -547 c25 -9 62 -32 83 -52 71 -69 71 -67 74 -375 l3
        -278 -868 1 -868 0 650 326 c358 180 684 341 725 360 84 37 133 41 201 18z
        m971 -907 c91 -45 147 -103 191 -196 l32 -67 3 -287 3 -287 -453 -3 -453 -3
        -60 -28 c-107 -50 -172 -135 -211 -278 -26 -92 -26 -406 0 -498 39 -143 104
        -228 211 -278 l60 -28 453 -3 453 -3 -3 -287 -3 -287 -32 -67 c-44 -93 -100
        -151 -191 -196 l-76 -37 -1815 0 -1816 0 -67 32 c-93 44 -151 100 -196 191
        l-37 76 0 1135 0 1136 32 67 c17 37 50 87 72 111 44 48 135 101 197 116 23 5
        773 8 1835 8 l1795 -2 76 -37z m462 -1010 c55 -16 138 -99 154 -154 16 -56 16
        -412 0 -468 -16 -55 -99 -138 -154 -154 -54 -16 -1060 -17 -1117 -1 -59 16
        -103 68 -129 152 -20 63 -23 93 -23 237 0 144 3 174 23 237 25 82 70 136 125
        152 51 15 1071 14 1121 -1z"/>
        <path d="M3845 1991 c-92 -24 -173 -90 -215 -176 -19 -41 -24 -66 -24 -135 -1
        -78 2 -90 33 -148 38 -70 70 -100 145 -140 43 -22 64 -26 136 -26 72 0 93 4
        136 26 75 40 107 70 145 140 31 58 34 70 33 148 0 72 -4 93 -26 136 -39 75
        -70 107 -137 143 -65 34 -164 49 -226 32z m138 -165 c103 -43 128 -177 48
        -257 -65 -65 -157 -65 -222 0 -124 123 13 325 174 257z"/>
        </g>
    </svg>`,
    'customer': (fillColor) => `<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="23" height="23" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="${fillColor}" stroke="none">
        <path d="M1322 5061 c-79 -29 -131 -63 -189 -121 -153 -154 -174 -406 -49
        -580 l35 -49 -42 -21 c-161 -84 -284 -246 -322 -425 -17 -81 -21 -455 -5 -485
        20 -37 57 -40 576 -40 l504 0 0 -114 c0 -99 3 -117 21 -140 l20 -26 689 0 689
        0 20 26 c18 23 21 41 21 140 l0 114 504 0 c519 0 556 3 576 40 16 30 12 404
        -5 485 -38 178 -161 341 -321 424 l-42 22 38 57 c156 235 69 542 -188 670 -76
        37 -77 37 -201 37 -125 0 -127 0 -202 -37 -90 -44 -182 -136 -222 -220 -71
        -149 -57 -317 38 -458 l33 -50 -61 -32 c-76 -41 -180 -144 -227 -225 l-35 -60
        -32 15 -31 15 33 49 c56 81 78 151 79 253 1 112 -19 182 -77 267 -90 132 -229
        203 -392 202 -180 -2 -335 -97 -416 -256 -72 -142 -58 -330 36 -466 l33 -49
        -31 -15 -32 -15 -35 60 c-46 79 -153 185 -228 225 l-60 32 33 50 c94 140 108
        308 38 458 -39 84 -132 175 -221 220 -72 35 -81 37 -186 40 -90 2 -121 -1
        -164 -17z m261 -147 c64 -22 140 -92 174 -162 57 -116 28 -270 -67 -356 -73
        -66 -114 -81 -220 -81 -84 0 -101 3 -146 27 -60 32 -118 94 -147 157 -31 66
        -30 177 2 249 28 63 109 140 172 164 57 22 172 23 232 2z m2181 0 c64 -22 147
        -100 177 -166 35 -79 33 -184 -4 -260 -30 -62 -83 -115 -149 -150 -36 -19 -58
        -23 -138 -23 -106 0 -147 15 -220 81 -96 87 -124 239 -67 356 33 67 105 136
        168 160 57 22 172 23 233 2z m-1069 -298 c64 -29 117 -81 152 -149 23 -44 27
        -65 27 -132 1 -95 -24 -155 -89 -220 -66 -67 -125 -90 -225 -90 -101 0 -159
        23 -228 93 -64 63 -86 120 -86 217 0 67 4 88 27 132 82 159 262 222 422 149z
        m-1367 -436 c87 -27 201 -27 286 1 61 20 66 21 114 5 104 -35 206 -123 261
        -226 l32 -59 -47 -58 c-26 -31 -62 -88 -80 -126 -29 -61 -39 -95 -60 -204 l-4
        -23 -470 0 -470 0 0 148 c0 160 12 232 55 314 52 103 136 180 243 224 69 28
        63 28 140 4z m2182 0 c76 -27 197 -26 285 1 63 19 69 19 116 4 107 -36 218
        -134 268 -238 39 -81 51 -154 51 -309 l0 -148 -470 0 -470 0 -4 23 c-21 109
        -31 143 -60 204 -18 38 -54 95 -80 126 l-47 58 31 58 c52 98 138 176 237 217
        70 28 73 28 143 4z m-1060 -295 c71 -20 152 -19 225 2 33 9 78 17 100 17 98 0
        255 -123 313 -245 42 -90 52 -149 52 -309 l0 -140 -580 0 -580 0 0 140 c0 76
        5 163 11 192 25 120 103 236 201 300 108 71 140 76 258 43z"/>
        <path d="M400 3250 c-165 -16 -263 -37 -283 -61 -16 -20 -17 -82 -17 -980 0
        -946 0 -959 20 -979 19 -19 33 -20 376 -20 215 0 363 4 374 10 10 5 26 31 36
        57 9 27 20 51 24 55 8 11 136 -52 212 -103 127 -85 224 -177 691 -657 370
        -379 488 -495 524 -512 39 -18 61 -21 124 -18 67 3 83 7 120 33 40 28 102 98
        111 125 3 9 20 7 63 -5 158 -46 327 42 372 195 l17 55 65 -3 c85 -5 164 23
        221 79 46 45 90 128 90 172 0 27 1 27 63 27 83 0 145 22 201 71 102 90 131
        235 70 356 l-29 61 51 16 c28 9 102 41 163 71 62 30 116 55 120 55 5 0 18 -27
        30 -60 12 -33 30 -64 41 -70 11 -6 154 -10 374 -10 343 0 357 1 376 20 20 20
        20 33 20 979 0 898 -1 960 -18 980 -20 26 -112 44 -308 61 -304 27 -666 -33
        -980 -162 -97 -39 -105 -56 -75 -151 13 -40 26 -78 29 -86 4 -11 -9 -13 -79
        -7 -114 10 -234 37 -424 96 -221 68 -276 80 -381 80 -165 0 -313 -53 -450
        -161 -72 -58 -80 -61 -177 -81 -79 -16 -142 -21 -277 -22 -177 -1 -250 8 -387
        49 l-51 15 31 95 c39 119 34 132 -67 173 -316 130 -695 191 -1006 162z m330
        -150 c147 -13 333 -51 468 -95 59 -19 112 -38 119 -43 9 -5 -65 -230 -264
        -805 l-276 -797 -263 0 -264 0 0 859 0 859 43 8 c126 22 288 27 437 14z m3957
        0 c61 -5 128 -12 147 -16 l36 -6 0 -859 0 -859 -264 0 -263 0 -276 797 c-199
        575 -273 800 -264 805 29 19 236 80 352 103 202 41 347 51 532 35z m-1769
        -245 c35 -8 138 -38 228 -66 209 -64 320 -87 464 -95 l115 -7 203 -586 c111
        -322 202 -591 202 -597 0 -11 -211 -115 -288 -141 -53 -19 -132 -13 -187 12
        -26 13 -195 173 -498 474 -360 359 -464 457 -486 459 -61 7 -260 -193 -296
        -298 -9 -25 -20 -85 -26 -134 -12 -111 -41 -179 -107 -245 -27 -27 -81 -65
        -119 -84 -62 -32 -94 -43 -175 -61 l-28 -6 0 370 c0 351 1 372 20 413 15 31
        83 102 237 248 230 217 290 267 366 305 102 52 253 68 375 39z m-1401 -211
        c142 -36 252 -47 407 -41 l139 5 -111 -104 c-90 -85 -118 -118 -144 -172 l-33
        -67 -3 -447 -3 -447 22 -22 c20 -20 29 -21 113 -16 335 21 596 260 596 546 0
        64 36 133 105 201 l55 54 432 -431 c310 -308 446 -437 480 -454 60 -30 163
        -145 179 -199 11 -35 10 -47 -7 -88 -26 -62 -70 -92 -138 -92 -32 0 -60 7 -76
        18 -14 9 -137 131 -274 270 -227 229 -253 252 -283 252 -42 0 -73 -30 -73 -70
        0 -27 32 -64 231 -268 127 -130 237 -250 246 -266 21 -40 19 -113 -5 -145 -45
        -62 -120 -86 -185 -62 -14 5 -148 134 -299 287 -262 264 -276 277 -304 270
        -43 -11 -64 -35 -64 -73 0 -31 22 -57 232 -273 127 -131 237 -249 244 -262 7
        -13 13 -44 14 -69 0 -109 -117 -182 -212 -133 -16 9 -158 146 -315 305 -313
        316 -315 318 -370 263 -46 -46 -35 -63 214 -320 126 -128 234 -248 241 -265
        28 -68 -26 -139 -106 -139 -53 0 1 -50 -598 564 -214 219 -427 433 -474 473
        -103 91 -207 161 -320 215 -47 23 -86 42 -88 43 -1 1 90 269 203 595 113 325
        205 594 205 597 0 3 8 2 18 -2 9 -4 58 -18 109 -31z"/>
        </g>
    </svg>`,
    'insight': (fillColor) => `<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="23" height="23" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">  
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="${fillColor}" stroke="none">
        <path d="M4685 4473 c-131 -47 -210 -167 -203 -307 l3 -59 -243 -133 -242
        -134 -46 35 c-58 45 -141 69 -208 61 -58 -6 -142 -45 -175 -80 l-23 -25 -202
        104 -201 104 -2 71 c-3 120 -57 211 -155 265 -48 27 -62 30 -143 30 -81 0 -95
        -3 -143 -30 -64 -35 -97 -71 -130 -143 -21 -46 -23 -64 -19 -130 2 -42 8 -86
        12 -98 6 -18 -27 -47 -240 -218 l-246 -197 -53 26 c-151 74 -330 15 -402 -132
        -38 -77 -41 -195 -6 -260 33 -64 79 -111 136 -141 44 -23 63 -27 136 -27 73 0
        92 4 136 27 126 66 192 218 151 349 l-14 45 229 185 c126 101 237 190 248 197
        17 12 27 10 77 -14 105 -52 235 -34 324 44 l34 30 202 -104 201 -105 0 -70 c0
        -164 115 -286 277 -297 88 -5 166 22 224 78 63 61 84 111 91 214 l5 89 235
        128 c129 71 238 129 241 129 4 0 30 -16 59 -35 65 -44 119 -58 195 -52 149 11
        262 126 272 277 12 166 -111 305 -279 316 -44 3 -78 -1 -113 -13z m191 -158
        c46 -35 68 -86 61 -143 -6 -51 -29 -87 -78 -120 -28 -20 -43 -23 -87 -20 -85
        7 -142 62 -149 145 -11 134 146 220 253 138z m-1949 -65 c88 -53 102 -172 30
        -248 -75 -78 -202 -58 -252 39 -22 41 -19 117 5 157 43 70 147 95 217 52z
        m906 -464 c18 -7 45 -28 61 -45 24 -25 30 -42 34 -91 4 -52 1 -66 -21 -99 -77
        -116 -256 -83 -287 53 -27 122 99 231 213 182z m-1877 -295 c61 -28 89 -73 89
        -143 0 -63 -19 -97 -75 -135 -66 -46 -159 -24 -214 50 -31 42 -29 130 3 176
        26 36 90 71 131 71 14 0 43 -9 66 -19z"/>
        <path d="M4346 3534 c-9 -8 -18 -25 -21 -37 -3 -12 -4 -354 -3 -760 3 -718 4
        -739 22 -753 16 -11 84 -14 378 -14 345 0 359 1 378 20 20 20 20 33 20 770 0
        737 0 750 -20 770 -19 19 -33 20 -379 20 -313 0 -362 -2 -375 -16z m634 -774
        l0 -650 -260 0 -260 0 0 650 0 650 260 0 260 0 0 -650z"/>
        <path d="M2485 3468 c-11 -6 -23 -16 -27 -22 -4 -6 -8 -333 -8 -727 0 -681 1
        -717 18 -732 17 -15 58 -17 379 -17 336 0 361 1 376 18 16 17 17 82 17 733 0
        393 -4 719 -8 725 -20 31 -54 34 -389 34 -233 -1 -344 -4 -358 -12z m615 -743
        l0 -615 -255 0 -255 0 0 615 0 615 255 0 255 0 0 -615z"/>
        <path d="M3409 2991 l-24 -19 0 -480 c0 -458 1 -481 19 -501 19 -21 26 -21
        382 -21 l363 0 15 22 c14 20 16 84 16 499 l0 478 -26 20 c-26 20 -38 21 -374
        21 -328 0 -349 -1 -371 -19z m631 -501 l0 -380 -260 0 -260 0 0 380 0 380 260
        0 260 0 0 -380z"/>
        <path d="M1281 2985 c-331 -75 -578 -293 -680 -600 -37 -113 -55 -246 -47
        -347 9 -103 47 -251 85 -326 17 -34 31 -67 31 -74 0 -7 -146 -159 -324 -338
        -341 -341 -346 -348 -346 -435 0 -119 116 -235 235 -235 84 0 95 8 441 353
        l331 329 51 -26 c146 -74 336 -107 499 -87 200 24 370 103 511 235 133 127
        207 243 259 413 24 80 27 103 27 248 0 124 -4 175 -18 225 -89 323 -329 565
        -646 652 -99 27 -317 34 -409 13z m331 -140 c151 -32 270 -97 383 -210 115
        -116 179 -233 212 -388 18 -88 13 -264 -11 -352 -40 -143 -137 -292 -249 -385
        -354 -290 -876 -213 -1127 167 -295 446 -49 1053 474 1168 82 18 235 18 318 0z
        m-1037 -1763 c-346 -347 -337 -342 -401 -278 -35 35 -42 62 -23 99 6 12 144
        156 307 319 l296 297 66 -62 66 -62 -311 -313z"/>
        <path d="M1305 2621 c-202 -59 -349 -224 -386 -435 -45 -260 112 -518 366
        -606 292 -101 620 77 700 380 19 71 19 200 0 273 -49 189 -203 343 -392 392
        -76 20 -213 18 -288 -4z m327 -159 c80 -39 159 -120 196 -199 36 -80 42 -212
        13 -295 -40 -115 -126 -205 -238 -249 -76 -29 -205 -32 -281 -6 -103 37 -204
        134 -243 236 -85 226 33 463 271 542 14 5 68 7 121 6 85 -2 103 -6 161 -35z"/>
        </g>
    </svg>`,
    'form': (fillColor) => `<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="23" height="23" viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="${fillColor}" stroke="none">
        <path d="M415 4786 c-67 -29 -105 -106 -91 -181 9 -47 59 -102 104 -115 25 -8
        329 -10 954 -8 908 3 917 3 944 24 53 39 69 71 69 134 0 63 -16 95 -69 134
        -27 21 -36 21 -954 23 -755 2 -933 0 -957 -11z"/>
        <path d="M2815 4786 c-41 -18 -83 -69 -90 -109 -3 -18 -4 -442 -3 -944 3 -903
        3 -912 24 -939 11 -15 33 -37 48 -48 27 -21 34 -21 966 -21 932 0 939 0 966
        21 15 11 37 33 48 48 21 27 21 34 21 966 0 932 0 939 -21 966 -11 15 -33 37
        -48 48 -27 21 -36 21 -954 23 -755 2 -933 0 -957 -11z m1665 -1026 l0 -720
        -720 0 -720 0 0 720 0 720 720 0 720 0 0 -720z"/>
        <path d="M415 3666 c-67 -29 -105 -106 -91 -181 9 -47 59 -102 104 -115 25 -8
        329 -10 954 -8 908 3 917 3 944 24 53 39 69 71 69 134 0 63 -16 95 -69 134
        -27 21 -36 21 -954 23 -755 2 -933 0 -957 -11z"/>
        <path d="M415 2386 c-41 -18 -83 -69 -90 -109 -3 -18 -4 -442 -3 -944 3 -903
        3 -912 24 -939 11 -15 33 -37 48 -48 27 -21 34 -21 966 -21 932 0 939 0 966
        21 15 11 37 33 48 48 21 27 21 34 21 966 0 932 0 939 -21 966 -11 15 -33 37
        -48 48 -27 21 -36 21 -954 23 -755 2 -933 0 -957 -11z m1665 -1026 l0 -720
        -720 0 -720 0 0 720 0 720 720 0 720 0 0 -720z"/>
        <path d="M2815 1906 c-67 -29 -105 -106 -91 -181 9 -47 59 -102 104 -115 25
        -8 329 -10 954 -8 908 3 917 3 944 24 53 39 69 71 69 134 0 63 -16 95 -69 134
        -27 21 -36 21 -954 23 -755 2 -933 0 -957 -11z"/>
        <path d="M2815 786 c-67 -29 -105 -106 -91 -181 9 -47 59 -102 104 -115 25 -8
        329 -10 954 -8 908 3 917 3 944 24 53 39 69 71 69 134 0 63 -16 95 -69 134
        -27 21 -36 21 -954 23 -755 2 -933 0 -957 -11z"/>
        </g>
    </svg>`,
    'report': (fillColor) => `<svg width="23" height="23" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <defs>
        <style>.cls-1{fill:none;stroke:${fillColor};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style>
        </defs>
        <title/>
        <g id="dashboard">
        <line class="cls-1" x1="3" x2="29" y1="29" y2="29"/>
        <line class="cls-1" x1="3" x2="3" y1="3" y2="29"/>
        <line class="cls-1" x1="16" x2="16" y1="7" y2="25"/>
        <line class="cls-1" x1="22" x2="22" y1="11" y2="25"/>
        <line class="cls-1" x1="10" x2="10" y1="16" y2="25"/>
        </g>
    </svg>`,
    'setting': (fillColor) => `<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="23" height="23" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="${fillColor}" stroke="none">
        <path d="M3495 5006 c-251 -48 -466 -224 -560 -458 l-27 -68 -1377 0 -1378 0
        -44 -23 c-68 -36 -103 -93 -107 -176 -6 -103 34 -171 123 -212 38 -18 103 -19
        1413 -19 1298 0 1373 -1 1378 -17 25 -76 101 -207 155 -266 150 -166 332 -247
        555 -247 136 0 213 18 330 76 172 84 303 229 369 407 l18 47 306 0 c275 0 310
        2 346 19 89 41 129 109 123 212 -4 83 -39 140 -107 176 -43 23 -49 23 -355 23
        l-311 0 -28 69 c-88 219 -276 384 -506 446 -81 22 -232 27 -316 11z m242 -448
        c166 -62 250 -265 174 -421 -73 -151 -259 -223 -406 -158 -185 82 -246 303
        -129 472 77 112 229 157 361 107z"/>
        <path d="M1338 3295 c-234 -47 -458 -240 -542 -467 l-18 -48 -289 0 c-176 0
        -307 -4 -333 -11 -93 -23 -156 -107 -156 -209 0 -102 63 -186 156 -209 26 -7
        157 -11 333 -11 l289 0 18 -47 c85 -232 309 -422 550 -468 274 -53 548 46 726
        261 41 50 112 177 132 237 5 16 79 17 1361 17 944 0 1369 3 1399 11 93 23 156
        107 156 209 0 102 -63 186 -156 209 -30 8 -455 11 -1399 11 -1282 0 -1356 1
        -1361 18 -4 9 -24 53 -45 97 -72 149 -189 265 -344 340 -146 71 -314 92 -477
        60z m277 -449 c100 -46 154 -112 181 -218 37 -143 -38 -289 -183 -355 -46 -21
        -66 -25 -131 -21 -94 4 -154 32 -216 98 -154 165 -89 421 129 507 54 21 164
        16 220 -11z"/>
        <path d="M3440 1581 c-212 -59 -383 -198 -474 -386 -24 -49 -46 -98 -50 -107
        -5 -17 -80 -18 -1378 -18 -1310 0 -1375 -1 -1413 -19 -89 -41 -129 -109 -123
        -212 4 -83 39 -140 107 -176 l44 -23 1378 0 1377 0 27 -68 c155 -388 609 -575
        987 -408 180 80 322 225 395 407 l28 69 311 0 c306 0 312 0 355 23 68 36 103
        93 107 176 6 103 -34 171 -123 212 -36 17 -71 19 -346 19 l-306 0 -18 48 c-66
        177 -197 322 -369 406 -50 25 -120 52 -156 61 -91 21 -277 19 -360 -4z m317
        -446 c198 -90 244 -346 89 -501 -198 -197 -532 -58 -530 221 2 229 232 375
        441 280z"/>
        </g>
    </svg>`,
}
