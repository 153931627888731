<template>
    <div class="">
        <div class="h-[100vh] w-[100px] absolute hover:w-[200px]">
            <SidenavteamMenu />
        </div>

        <HeaderNavbar title="Create POI" />

        <div class="ml-sidebar">
            <div class="m-2">
                <div class="row">
                    <div class="col-md-9">
                        <div id="mapContainer">
                            <div class="map-full-screen">
                                <button class="btn" @click="handleFullScreenMap">
                                    <i class="fas fa-expand"></i>
                                </button>
                            </div>
                        </div>
                        <div class="position-fixed" style="top: 70px; left: 150px; z-index: 2;">
                            <div class="poi-form-sm">
                                <div class="form-layout shadow-lg">
                                    <div class="form-group p-2">
                                        <div class="d-flex align-items-center">
                                            <input type="text" class="form-control mb-0" placeholder="Search Lat, Lng"
                                                :value="latlng" @input="flytolocation" />
                                            <div class="mx-2">
                                                <i class="fa fa-search"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="text-xs">
                            <div class="poi-form border-rounded">
                                <form @submit.prevent="submit" class="form-layout my-3 text-start">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="text-start">Latitude</label>
                                                <input type="text" class="form-control" placeholder="Type Latitude"
                                                    v-model="form.poi.lat" required />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="text-start">Longitude</label>
                                                <input type="text" class="form-control" placeholder="Type Longitude"
                                                    v-model="form.poi.long" required />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="text-start">Division</label>
                                                <input type="text" class="form-control" placeholder="Type Division"
                                                    v-model="form.poi.division" required />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="text-start">District</label>
                                                <input type="text" class="form-control" placeholder="Type District"
                                                    v-model="form.poi.district" required />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="text-start">Thana</label>
                                                <input type="text" class="form-control" placeholder="Type Thana"
                                                    v-model="form.poi.thana" required />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="text-start">Union</label>
                                                <input type="text" class="form-control" placeholder="Type Union"
                                                    v-model="form.poi.union" required />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="text-start">Category</label>
                                                <vue-multiselect v-model="form.poi.poi_category"
                                                    placeholder="Select POI Categroy" label="Category" :options="companyPoiList.map(item => ({
                                                        value: item.id || '',
                                                        name: item.name || '',
                                                        icon: item.icon || '',
                                                    }))">
                                                    <template v-slot:singlelabel="{ value }">
                                                        <div class="multiselect-single-label">
                                                            <img class="character-label-icon" :src="value.icon"> {{
                                                                value.name }}
                                                        </div>
                                                    </template>

                                                    <template v-slot:option="{ option }">
                                                        <img class="character-option-icon" :src="option.icon"> {{
                                                            option.name }}
                                                    </template>
                                                </vue-multiselect>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="text-start">Name</label>
                                                <input type="text" class="form-control"
                                                    placeholder="Ex : House 20, Road 5, Block C" v-model="form.poi.name"
                                                    required />
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="text-start">Landmark</label>
                                                <input type="text" class="form-control" placeholder="Ex : ABC Building"
                                                    v-model="form.poi.landmark" required />
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="text-start">Address</label>
                                                <input type="text" class="form-control" placeholder="Ex :  Bashundhara R/A"
                                                    v-model="form.poi.address" required />
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="text-start">Marker Color</label>
                                                <div class="d-flex">
                                                    <div v-for="item in systemColors" :key="item.id">
                                                        <div class="m-1 text-center cursor-pointer"
                                                            :class="{ 'border-primary-bold': (form.poi['color'] || false) == item.id }"
                                                            @click="() => selectColorItem(item.id)">
                                                            <div
                                                                :style="`width: 20px; height: 20px; background: #${item.color_code};`">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="text-end">
                                        <button type="submit" class="btn btn-outline-primary btn-sm" :disabled="isSaving"
                                            @click="submit">

                                            {{ isEdit ? (isSaving ? "Updating..." : "Update POI")
                                                : (isSaving ? "Saving..." : "Save POI") }}
                                        </button>
                                    </div>

                                    <div class="mt-0" style="font-size: 10px;">
                                        NOTES <br>
                                        * To add a POI, left click the mouse at the desired location on the map <br>
                                        * To modify/delete any POI, right click the mouse over its marker and select
                                        modify/delete respectively <br>
                                        * To perform Search with tabular Name/Description, enter relevant matching text in
                                        "Search" text filed and click on "Search" button <br>
                                        * To clear entered Searched text from "Search" text field click on "Show All" button
                                        <br>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
  
<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";

import SidenavteamMenu from "@/components/menu/SideNavbarTeam.vue";
import HeaderNavbar from "@/components/menu/headerdashboard.vue";
import apis from "@/server/api/company/index";
import { mapState, mapActions } from "vuex";

export default {
    components: { SidenavteamMenu, HeaderNavbar },

    data: () => ({
        latlng: '',
        marker: null,
        isSaving: false,
        isEdit: false,
        form: {
            poi: {
                division: "",
                poi_category: "",
                district: "",
                thana: "",
                union: "",
                name: "",
                lat: "",
                long: "",
                landmark: "",
                address: "",
            },
        },
    }),

    computed: {
        ...mapState("core", ["systemColors", "companyPoiList"]),
    },

    mounted() {
        this.initializedMap();
        this.init();
        this.getSystemColors();
        this.getCompanyPoiCategory();
    },

    methods: {
        ...mapActions("core", [
            "getSystemColors",
            "getCompanyPoiCategory",
        ]),

        async init() {
            if (this.$route.name == 'company-poi-edit' && (this.$route.params.id || false)) {
                this.isEdit = true;
                this.form.poi = await apis.getPoi(this.$route.params.id);
                this.map.flyTo([this.form.poi.lat, this.form.poi.long], 17);
                this.handleMapMarker(this.form.poi.lat, this.form.poi.long);
            } else {
                this.isEdit = false;
            }
        },

        initializedMap() {
            this.map = L.map("mapContainer", {
                zoomControl: true,
                zoomAnimation: false,
                fadeAnimation: true,
                markerZoomAnimation: true,
            }).setView([23.8187676, 90.4314902], 12);
            L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
                attribution: '',
                minZoom: 0,
                maxZoom: 22,
                maxNativeZoom: 18,
            }).addTo(this.map);


            this.map.on('click', (e) => {
                this.handleMapMarker(e.latlng.lat, e.latlng.lng);
            });
        },

        handleMapMarker(lat, lng) {
            try {
                this.form.poi.lat = lat;
                this.form.poi.long = lng;

                if (this.marker || false) {
                    this.map.removeLayer(this.marker);
                }
                const defaultIcon = L.divIcon({
                    html: getMapMarker('#f73636'),
                    className: "",
                    iconSize: [24, 40],
                    iconAnchor: [12, 40],
                });

                this.marker = L.marker([lat, lng], { icon: defaultIcon, draggable: true }).addTo(this.map);
                this.marker.on('dragend', (event) => {
                    let markerItem = event.target;
                    let position = event.target.getLatLng();
                    markerItem.setLatLng(new L.LatLng(position.lat, position.lng), { draggable: 'true' });
                    this.map.panTo(new L.LatLng(position.lat, position.lng))
                    this.form.poi.lat = position.lat;
                    this.form.poi.long = position.lng;
                });
            } catch (_) {
                this.$toast.error(`Something went wrong!`, {
                    position: "top",
                });
            }
        },

        flytolocation(e) {
            this.latlng = e.target.value;
            const latlngarr = this.latlng.split(',');
            if (latlngarr.length == 2) {
                this.map.flyTo([latlngarr[0].trim(), latlngarr[1].trim()], 20);
                this.handleMapMarker(latlngarr[0].trim(), latlngarr[1].trim());
            }
        },

        async submit() {
            this.isSaving = true;
            if (this.validation()) {
                if (this.isEdit) {
                    this.handleUpdate();
                } else {
                    this.handleStore();
                }
            }
        },

        async handleUpdate() {
            let success = false;
            if (this.form.poi.id || false) {
                const response = await apis.updatePoi(this.form.poi);
                if (response.status || false) {
                    this.$toast.success(`POI update successfully`, {
                        position: "top",
                    });
                    success = true;
                } else {
                    if (response.message.length || false) {
                        this.$toast.error(`${response.message}`, {
                            position: "top",
                        });
                    } else {
                        this.$toast.error(`Something went wrong! please refresh.`, {
                            position: "top",
                        });
                    }
                }
            } else {
                this.$toast.error(`Something went wrong! please refresh.`, {
                    position: "top",
                });
            }

            if (success) {
                this.resetForm();
                this.$router.push({ name: 'company-poi' });
            }
        },

        async handleStore() {
            let success = false;
            const response = await apis.storePoi(this.form.poi);
            if (response.status || false) {
                this.$toast.success(`POI add successfully`, {
                    position: "top",
                });
                success = true;
            } else {
                if (response.message.length || false) {
                    this.$toast.error(`${response.message}`, {
                        position: "top",
                    });
                } else {
                    this.$toast.error(`Something went wrong! please refresh.`, {
                        position: "top",
                    });
                }
            }

            if (success) {
                this.resetForm();
                this.$router.push({ name: 'company-poi' });
            }
        },

        validation() {
            const requriedField = [
                "division",
                "poi_category",
                "district",
                "thana",
                "union",
                "name",
                "lat",
                "long",
                "landmark",
                "address",
            ];
            for (let key in this.form.poi) {
                if (
                    requriedField.includes(key) &&
                    !(this.form.poi[key] != '' && this.form.poi[key] != null && this.form.poi[key] != undefined)
                ) {
                    this.$toast.error(`POI ${key} field is required`, {
                        position: "top",
                    });
                    this.isSaving = false;
                    return false;
                }
            }
            return true;
        },

        resetForm() {
            this.isEdit = false;
            this.form = {
                poi: {
                    division: "",
                    category: "",
                    district: "",
                    thana: "",
                    union: "",
                    name: "",
                    lat: "",
                    long: "",
                    landmark: "",
                    address: "",
                },
            };
        },

        selectColorItem(colorId) {
            this.form.poi['color'] = colorId;
        },
    },

};
</script>

<style scoped>
#mapContainer {
    width: 100%;
    height: 89vh;
    z-index: 1;
}

.poi-form input {
    font-size: 12px;
}

.poi-form input::placeholder {
    font-size: 12px;
    color: #9ca3af;
}

.poi-form-sm {
    width: 300px;
}
</style>
  