<template>
  <div>
    <div class="h-[100vh] w-[100px] absolute hover:w-[200px]">
      <SideNavbar />
    </div>

    <HeaderDashboard title="Track History Report" />

    <div class="ml-sidebar">
      <div class="">
        <div class="mx-4 my-2">
          <div class="row">
            <div class="col-md-9">
              <div class="row">
                <div class="col-md-4">
                  <VueDatePicker @closed="handleDateTimeRange" v-model="dates" range :partial-range="false"
                    :enableTimePicker="true" :placeholder="datePlaceholder" :min-date="new Date(Date.now() - 3888000000)"
                    ref="datepicker">
                    <template #left-sidebar>
                      <div style="min-width: 100px;">
                        <button type="button" class="btn btn-sm btn-outline-primary w-100" @click="pickToday">
                          Today
                        </button>
                        <button type="button" class="btn btn-sm btn-outline-primary-rev w-100 mt-1"
                          @click="pickYesterday">
                          Yesterday
                        </button>
                        <button type="button" class="btn btn-sm btn-outline-primary-rev w-100 mt-1"
                          @click="pickLast7Days">
                          Last 7 Days
                        </button>
                        <button type="button" class="btn btn-sm btn-outline-primary-rev w-100 mt-1"
                          @click="pickLast30Days">
                          Last 30 Days
                        </button>
                      </div>
                    </template>
                  </VueDatePicker>
                </div>
                <div class="col-md-8 text-start">
                  <filter-button @reset="resetFilter" @callback="getUserLocationByCompany" />
                </div>
              </div>
            </div>
            <div class="col-md-3 text-end">
              <div>
                <button type="button" class="btn btn-outline-primary-rev px-4 py-1" @click="exportToExcel"
                  :disabled="isExporting">
                  Export
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="mx-4 mt-2 mb-4">
          <hr>
          <div class="row text-start my-2">
            <div class="col-md-2">
              <h2 class="text-xl">
                Traveling <span class="text-info fw-semi-bold ml-2" style="font-size: 28px">
                  {{ totalTravelling }}%
                </span>
              </h2>
            </div>
            <div class="col-md-2">
              <h2 class="text-xl">
                Idle <span class="text-info fw-semi-bold ml-2" style="font-size: 28px">
                  {{ totalIdle }}%
                </span>
              </h2>
            </div>
            <div class="col-md-2">
              <h2 class="text-xl">
                Switch Off <span class="text-info fw-semi-bold ml-2" style="font-size: 28px">
                  -
                </span>
              </h2>
            </div>
          </div>
          <hr>
        </div>
        <div class="mx-4 report-table mt-2">
          <div class="appscroll" style="height: 62vh;">
            <table class="table table-poi table-bordered " id="location-report-table">
              <thead class="position-sticky top-0 text-xs">
                <tr class="text-start">
                  <th>SL</th>
                  <th>Emp ID</th>
                  <th style="min-width:150px;">Emp Name</th>
                  <th>Emp Phone</th>
                  <th>Department</th>
                  <th>Designation</th>
                  <th style="max-width: 500px; min-width: 230px;">Address</th>
                  <th style="max-width: 300px; min-width: 150px;">Lat/Lng</th>
                  <th style="max-width: 80px;">Start Time</th>
                  <th style="max-width: 80px;">End Time</th>
                  <th>Duration</th>
                  <th>Status</th>
                  <th>Source</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <template v-if="locationList.length > 0">
                  <template v-for="(employee, index) in locationList" :key="index" :employee="employee">
                    <tr>
                      <td>{{ index + 1 }}</td>
                      <td>{{ employee.employee_id }}</td>
                      <td>
                        <div class="text-start">
                          {{ employee?.employee_name }}
                        </div>
                      </td>
                      <td>
                        <div>{{ employee?.mobile_number }}</div>
                      </td>
                      <td>{{ employee.employee_department }}</td>
                      <td>{{ employee.employee_designation }}</td>
                      <td>{{ employee?.address }}</td>
                      <td>
                        {{ employee?.lat_long }}
                      </td>
                      <td style="max-width: 80px;">
                        {{
                          formateDate(employee?.start_time)
                        }}
                      </td>
                      <td style="max-width: 80px;">
                        {{
                          formateDate(employee?.end_date_time || employee?.end_time)
                        }}
                      </td>
                      <td>
                        <div>{{ employee?.duration }}</div>
                      </td>
                      <td>
                        <div v-if="employee?.status == 'Idle'" class="badge badge-orange">{{ employee?.status }}</div>
                        <div v-else class="badge badge-purple">{{ employee?.status }}</div>
                      </td>
                      <td>
                        <div>{{ employee?.location_source }}</div>
                      </td>
                      <td>
                        <div>
                          <a class="btn border border-info brx-50"
                            :href="`https://www.google.com/maps/place/${employee?.lat_long}`" target="_blank">
                            <i class="fa fa-map-marker text-info"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                  </template>
                </template>
                <template v-else>
                  <tr>
                    <td colspan="14">
                      <div class="my-3 text-start">No data available in table</div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="mx-4 my-3">
          <div class="d-flex justify-content-between">
            <div>
              <div class="form-group">
                <div class="input-group">
                  Showing
                  <select @change="handlePageLimit" class="border mx-2" :value="pageLimit">
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="2000">2000</option>
                    <option value="5000">5000</option>
                  </select> of {{ totalCount }} entries
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <div class="mr-2">
                <button @click="fetchPageData('previous')" :disabled="pagination.prev == ''"
                  class="btn btn-outline-primary">
                  Previous
                </button>
              </div>
              <div>
                <button @click="fetchPageData('next')" :disabled="pagination.next == ''" class="btn btn-outline-primary">
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderDashboard from "@/components/menu/headerdashboard.vue";
import SideNavbar from "@/components/menu/SideNavbarTeam.vue";
import Dropdown from "@/components/menu/Dropdown.vue";
import PageTransactBtn from "@/components/ui/PageTransactBtn.vue";
import Searchbar from "@/components/ui/Searchbar.vue";
import CompanyApi from "@/server/api/company";
import JsonExcel from "vue-json-excel3";
import DropDownSearch from "@/components/ui/DropDownSearch.vue";
import DropdownCheck from "@/views/company/report/checkComponent.vue";
import FilterButton from "@/components/report/FilterButton.vue";

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import XLSX from "xlsx-js-style";

import { mapActions, mapState, mapGetters } from "vuex";

export default {
  components: {
    HeaderDashboard,
    SideNavbar,
    Dropdown,
    PageTransactBtn,
    Searchbar,
    JsonExcel,
    DropDownSearch,
    DropdownCheck,
    VueDatePicker,
    FilterButton,
  },
  data() {
    return {
      totalCount: null,
      pageUrl: null,
      pageLimit: 100,
      exportLocationList: [],
      locationList: [],
      employeeList: [],
      selectedEmployee: null,
      startingDate: null,
      endingDate: null,
      dates: null,
      datePlaceholder: 'Select Date',
      pagination: {
        next: '',
        prev: '',
      },
      companyApi: new CompanyApi(),
      isExporting: false,
    };
  },
  computed: {
    ...mapState("report", ["users", "departments", "designations", "selectedUsers"]),
    ...mapGetters("report", ["isFilterByDepartment"]),

    totalTravelling() {
      const temp = this.locationList.reduce((acc, cur) => {
        if (cur.status == 'Travelling') {
          return acc + 1;
        }
        return acc;
      }, 0);
      return Math.floor((temp / this.locationList.length) * 100) || 0;
    },

    totalIdle() {
      const temp = this.locationList.reduce((acc, cur) => {
        if (cur.status == 'Idle') {
          return acc + 1;
        }
        return acc;
      }, 0);
      return Math.floor((temp / this.locationList.length) * 100) || 0;
    },
  },
  mounted() {
    this.getEmployeeList();
  },
  methods: {
    ...mapActions("report", [
      "initReportState",
      "handleUserSelect",
      "getUserLocationReportData",
      "handleDepartmentSelect",
      "handleDesignationSelect",
      "resetReportFilter"
    ]),

    /*  API CALLS */
    async getUserLocationByCompany() {
      // this.companyApi.getDataForExcel(this.selectedEmployee?.id || null, this.startingDate, this.endingDate, this.pageUrl, this.pageLimit)
      this.getUserLocationReportData({
        startingDate: this.startingDate,
        endingDate: this.endingDate,
        pageUrl: this.pageUrl,
        pageLimit: this.pageLimit,
      }).then((res) => {
        this.locationList = res.results.result || [];
        this.pagination.next = res.next || res.links?.next || '';
        this.pagination.prev = res.previous || res.links?.previous || '';
        this.totalCount = res.count;
      });
    },
    async getEmployeeList() {
      this.companyApi.getUserListByCompany().then((res) => {
        this.employeeList = res;
      });
    },

    /* UTILS */

    resetFilter() {
      this.locationList = [];
      this.pagination.next = '';
      this.pagination.prev = '';
      this.totalCount = 0;
      this.resetReportFilter();
      const dom = document.getElementById('dropdownSearch');
      const domElem = dom.getElementsByClassName('form-check-input');
      for (let i = 0; i < domElem.length; i++) {
        domElem[i].checked = false;
      }
      this.toggleDropDown();
    },

    onUserSelect(e) {
      this.handleUserSelect(e);
      this.getUserLocationByCompany();
    },

    onDepartmentSelect(e) {
      this.handleDepartmentSelect(e).then((_) => this.getUserLocationByCompany());
    },

    onDesignationSelect(e) {
      this.handleDesignationSelect(e).then((_) => this.getUserLocationByCompany());
    },

    handlePageLimit(e) {
      this.pageLimit = e.target.value;
      this.getUserLocationByCompany();
    },

    formateDate(date) {
      return formateDateTime(date);
    },

    transactPage() {
      this.$router.push("/monitor");
    },
    toggleDropDown() {
      let toggleDropdown = document.getElementById("dropdownSearch");
      toggleDropdown.classList.toggle("hidden");
    },
    onSelectEmployee(employee) {
      this.selectedEmployee = employee;
      this.toggleDropDown();
      this.getUserLocationByCompany();
    },
    onFilterByDate() {
      this.getUserLocationByCompany();
      // this.companyApi
      //   .getDataForExcel(
      //     this.selectedEmployee.id,
      //     this.startingDate,
      //     this.endingDate
      //   )
      //   .then((res) => {
      //     this.locationList = res;
      //     console.log(res);
      //   });
    },

    fetchPageData(status) {
      if (status == 'previous' && this.pagination.prev != '') {
        this.pageUrl = this.pagination.prev;
        this.getUserLocationByCompany();
      } else if (status == 'next' && this.pagination.next != '') {
        this.pageUrl = this.pagination.next;
        this.getUserLocationByCompany();
      }
    },

    async handleDateTimeRange() {
      if (!this.dates) {
        return;
      }
      try {
        this.startingDate = this.dates[0].toISOString();
        this.endingDate = this.dates[1].toISOString();
        this.getUserLocationByCompany();
      } catch (_) {
        return;
      }
    },

    pickToday() {
      this.datePlaceholder = 'Today';
      this.startingDate = new Date().toISOString();
      this.endingDate = new Date().toISOString();
      this.getUserLocationByCompany();
      this.$refs.datepicker.closeMenu();
    },

    pickYesterday() {
      this.datePlaceholder = 'Yesterday';
      const now = new Date();
      this.startingDate = new Date(now.setDate(now.getDate() - 1)).toISOString();
      this.endingDate = new Date().toISOString();
      this.getUserLocationByCompany();
      this.$refs.datepicker.closeMenu();
    },

    pickLast7Days() {
      this.datePlaceholder = 'Last 7 Days';
      const now = new Date();
      this.startingDate = new Date(now.setDate(now.getDate() - 7)).toISOString();
      this.endingDate = new Date().toISOString();
      this.getUserLocationByCompany();
      this.$refs.datepicker.closeMenu();
    },

    pickLast30Days() {
      this.datePlaceholder = 'Last 30 Days';
      const now = new Date();
      this.startingDate = new Date(now.setDate(now.getDate() - 30)).toISOString();
      this.endingDate = new Date().toISOString();
      this.getUserLocationByCompany();
      this.$refs.datepicker.closeMenu();
    },

    async getReportDataByChunk(url, fromNext = false) {
      try {
        if (fromNext && (url == null || url == '')) {
          return [];
        }

        const userIds = this.selectedUsers.join(',');

        const response = await this.companyApi.getDataForExcel(
          userIds,
          this.startingDate,
          this.endingDate,
          url,
          10000,
        )
        const temp = await this.getReportDataByChunk(response.next, true);
        return [
          ...(response.results.result || []),
          ...(temp || []),
        ];

      } catch (_) {
        this.$toast.clear();
        this.$toast.error('Something went wrong', {
          position: "top",
          duration: false,
        });
      }
    },

    async exportToExcel() {
      this.isExporting = true;
      this.$toast.info('Loading ...', {
        position: "top",
        duration: false,
      });

      this.exportLocationList = await this.getReportDataByChunk(this.pageUrl);

      this.$toast.warning('Processing ...', {
        position: "top",
        duration: false,
      });

      if (this.exportLocationList.length == 0) {
        this.isExporting = false;
        this.$toast.error('No Data Found', {
          position: "top",
        });
        return
      }

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet([]);

      const cellStyles = {
        font: { sz: 9 },
        alignment: { vertical: "center", horizontal: "center", wrapText: true },
        border: {
          top: { style: 'thin', color: { rgb: '00000000' } },
          bottom: { style: 'thin', color: { rgb: '00000000' } },
          left: { style: 'thin', color: { rgb: '00000000' } },
          right: { style: 'thin', color: { rgb: '00000000' } }
        },
      }
      const headerCellStyles = {
        ...cellStyles,
        font: { bold: true, sz: 9 },
        fill: { fgColor: { rgb: "9bc2e6" } }
      };

      const userName = localStorage.getItem("fullname");
      const companyName = localStorage.getItem("company_name");

      XLSX.utils.sheet_add_json(ws, [
        [
          {
            v: 'FieldForce Track History Report',
            s: {
              font: { bold: true, sz: 18 },
              alignment: { horizontal: "center" },
              fill: { fgColor: { rgb: "9bc2e6" } },
              border: { style: "thin", color: "000000" }
            }
          },
          '', '', '', '', '', '', '', '', '',
          {
            v: `${formateDateTime(new Date())}`,
            s: headerCellStyles,
          }
        ],
        [
          { v: 'Organization Name:', s: headerCellStyles },
          { v: '', s: headerCellStyles },
          { v: `${companyName || ''}`, s: headerCellStyles },
          { v: '', s: headerCellStyles },
          { v: 'Owner Name:', s: headerCellStyles },
          { v: '', s: headerCellStyles },
          { v: `${userName || ''}`, s: headerCellStyles },
          { v: '', s: headerCellStyles },
          { v: '', s: headerCellStyles },
          {
            v: `Report From ${formateDateTime(this.startingDate)} to ${formateDateTime(this.endingDate)}`,
            s: headerCellStyles
          },
          { v: '', s: headerCellStyles },
          { v: '', s: headerCellStyles },
          { v: '', s: headerCellStyles },
          { v: '', s: headerCellStyles },
        ]
      ], { skipHeader: true, origin: 0 });

      ws['!merges'] = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 9 } },
        { s: { r: 0, c: 10 }, e: { r: 0, c: 13 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 1 } },
        { s: { r: 1, c: 2 }, e: { r: 1, c: 3 } },
        { s: { r: 1, c: 4 }, e: { r: 1, c: 5 } },
        { s: { r: 1, c: 5 }, e: { r: 1, c: 6 } },
        { s: { r: 1, c: 9 }, e: { r: 1, c: 13 } },
      ];

      XLSX.utils.sheet_add_aoa(ws, [[
        { v: 'Sr. No.', s: headerCellStyles },
        { v: 'Employee ID', s: headerCellStyles },
        { v: 'Employee Name', s: headerCellStyles },
        { v: 'Emp Mobile No.', s: headerCellStyles },
        { v: 'Employee Department', s: headerCellStyles },
        { v: 'Employee Designation', s: headerCellStyles },
        { v: 'Address', s: headerCellStyles },
        { v: 'Lat/Lng', s: headerCellStyles },
        { v: 'Start Time', s: headerCellStyles },
        { v: 'End Time', s: headerCellStyles },
        { v: 'Duration', s: headerCellStyles },
        { v: 'Status', s: headerCellStyles },
        { v: 'Source', s: headerCellStyles },
        { v: 'Location', s: headerCellStyles },
      ]], { origin: -1 });

      this.exportLocationList.forEach((item, index) => {
        const row = [
          { v: (index + 1), s: cellStyles },
          { v: item.employee_id, s: cellStyles },
          { v: item.employee_name, s: cellStyles },
          { v: item.mobile_number, s: cellStyles },
          { v: item.employee_department, s: cellStyles },
          { v: item.employee_designation, s: cellStyles },
          { v: item?.address, s: cellStyles },
          { v: item.lat_long, s: cellStyles },
          { v: `${formateDateTime(item?.start_time)}`, s: cellStyles },
          { v: `${formateDateTime(item?.end_date_time || item?.end_time)}`, s: cellStyles  },
          { v: item.duration, s: cellStyles },
          {
            v: item.status, s: {
              ...cellStyles,
              font: { sz: 10, color: { rgb: item.status == 'Idle' ? "ffa500" : "9bc2e6" } },
            }
          },
          { v: item.location_source, s: cellStyles },
          {
            v: 'view on map',
            s: {
              ...cellStyles,
              font: { sz: 10, color: { rgb: "0000ff" } },
            },
            l: { Target: `https://www.google.com/maps/place/${item?.lat_long}` }
          },
        ];

        XLSX.utils.sheet_add_aoa(ws, [row], { origin: -1 });
      });

      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'track-history-report.xlsx', { bookType: 'xlsx', type: 'binary' });
      this.isExporting = false;
      this.$toast.clear();
      this.$toast.success('Successfully Exported', {
        position: "top",
      });
    }
  },
};
</script>

<style>
.task-custombg {
  background-color: #e4f0f8;
}

.collapse {
  visibility: visible !important;
}
</style>
