<template>
  <router-view />
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");

#app {
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

html {
  overflow: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px !important;
  background: #1fa8e1;
}

.scrollbar-hidden ::-webkit-scrollbar {
  display: none !important;
}

/* leaflet map global styling for MONITOR */
.poi-map .leaflet-popup {
  margin: 50px 10px !important;
}

.leaflet-popup-content-wrapper {
  background-color: rgba(255, 255, 255, 0.7) !important;
}

.leaflet-popup-content {
  margin: 5px !important;
}

.leafLetCard {
  width: 170px !important;
}

.leafLetCard hr {
  padding-top: 10px !important;

  color: black;
}

.leafLetCard button {
  font-size: 10px;
  background-color: rgb(107, 185, 240);
  color: white;
  padding: 5px;
  border-radius: 5px;
}

.monitor-view .leafLetCard {
  width: 250px !important;
}

.justName {
  color: rgb(0, 0, 0) !important;
  font-weight: bolder !important;
}

.leafletMonitortDistance {
  font-weight: bolder;
  font-size: 12px;
}

.mapLocationName {
  padding-top: 10px;
  font-weight: 700;
  display: flex !important;
  align-items: center !important;
  font-size: 10px !important;
}

.svgIconClass {
  padding-right: 5px !important;
}

.distanceFromPOILeafletCard {
  padding-top: 5px;
  display: flex !important;
  align-items: center !important;
  font-size: 10px;
}

.mapLocationMonitorPageTime {
  padding-top: 5px;
  display: flex !important;
  align-items: center !important;

  font-size: 10px;
}

/* leadflet popup style */

.zIndexForMonitor {
  z-index: 500 !important;
}

/* .markernameMonitor{
  background-color: rgb(71, 174, 211) !important;
  padding: 5px !important;
  width: fit-content !important;
  opacity: 0.9;
  border-radius: 20px !important;
} */
</style>
