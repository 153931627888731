<template>
  <div>
    <!-- sidenav bar -->
    <!-- <SideNavbarTeam /> -->
    <!-- end of the sidenav -->

    <!-- Top right Profile information -->
    <!-- <div class="topNavTeam flex justify-end items-center bg-transparent absolute px-5 py-5">
      <div class="mx-3 font-sans font-[500] text-gray-500 hover:text-black cursor-pointer">
        Hello, {{ user?.name }}
      </div>
      <img class="w-7 h-7 rounded-xl" :src="`${user?.avatar}`" alt="" />
    </div> -->
    <!-- End of Top right Profile information -->
    <div class="h-[100vh] w-[100px] absolute hover:w-[200px]">
      <SideNavbarTeam />
    </div>

    <HeaderNavbar title="Team" />



    <!-- Right sidebar -->
    <div id="mySidebar" class="sidebar scrollbar-hidden">

      <!-- selected employees Information details -->
      <div class="h-auto w-[300px] bg-white" v-if="selectedEmployee">
        <div class="shadow-2xl">
          <div class="">
            <div>
              <div class="">
                <!-- header infor -->
                <div class="flex bg-white justify-between px-2 pt-2">
                  <div class="w-full text-start text-sm font-bold">
                    Last Location
                  </div>
                  <div class="w-full flex justify-end">
                    <div class="cursor-pointer" @click="removeMarker()">
                      <img :src="require(`@/assets/icons/backArrow.svg`)" alt="searchLogo" class="h-[16px] w-[16px]" />
                    </div>
                  </div>
                </div>
                <!-- end of header info -->

                <!-- specific employee information -->
                <div class="bg-black flex items-center justify-between px-2 py-2 text-white">
                  <div class="w-full text-start">
                    <div class="text-sm font-semibold">
                      {{ selectedEmployee.first_name }}
                      {{ selectedEmployee.last_name }}
                    </div>
                    <div class="text-xs flex">
                      <div>Emp.ID:</div>
                      <div>
                        {{ selectedEmployee.mobile }}
                      </div>
                    </div>
                  </div>
                  <div class="w-full text-end text-xs">
                    <div>0 Tasks Assigned</div>
                  </div>
                </div>
                <!-- end of specific employee information -->

                <!-- date picker  -->
                <div class="p-2">
                  <VueDatePicker v-model="vdate" @update:model-value="handleDate" range />
                </div>
                <!-- date picker -->
                <div class="">
                  <!-- Employee Status Timeline -->
                  <div class="timelineContainer">
                    <ul class="sessions h-auto overflow-scroll px-2 py-12" id="map-marker-card">
                      <template v-for="(lh, index) in locationHistory" :key="lh.id">
                        <p class="sessionTIME">
                          {{
                            new Date(lh?.created_at).toLocaleString("en-US", {
                              hour: "numeric",
                              minute: "numeric",
                            })
                          }}
                        </p>

                        <div :class="{
                              sessionDistance: true,
                              'red-text': index === locationHistory.length - 1,
                            }">
                          {{ lh?.distance }}m
                        </div>

                        <li class="sessionsLi" @mouseover="flyToLocationInMap(lh)">
                          <div class="flex ml-[12px]">
                            <div class="pointyShape"></div>
                            <div class="sessionsCard" :class="{ 'idle_color': lh?.travelling_status == 'Idle' }"
                              :style="{ 'background': lh?.id == selectedMarkerId ? '#ffe4c4' : '#fff' }"
                              :id="`map-marker-card-${lh?.id}`">
                              <!-- Index -->

                              <div class="numbericValues" :style="{
                                backgroundColor:
                                  index === 0
                                    ? '#4caf50'
                                    : lh?.travelling_status == 'Idle'
                                      ? '#f44336' :
                                      index === locationHistory.length - 1
                                        ? '#f44336'
                                        : '#3f51b5',
                              }">
                                {{
                                  index === 0
                                  ? "E"
                                  : index === locationHistory.length - 1
                                    ? "S"
                                    : locationHistory.length - index - 1
                                }}
                              </div>

                              <!-- Created Time -->
                              <!-- <div
                                class="flex justify-between items-center text-center"
                              >
                                <div class="font-extrabold">Date :</div>

                                <div>
                                  {{
                                    new Date(lh?.updated_at).toLocaleString(
                                      "en-US",
                                      {
                                        day: "numeric",
                                        month: "long",
                                        year: "numeric",
                                      }
                                    )
                                  }}
                                </div>
                              </div> -->

                              <div class="flex justify-between items-center text-center">
                                <div class="text-[15px]">
                                  <i class="fa fa-user" v-if="lh?.travelling_status == 'Idle'"></i>
                                  <i class="fa fa-car" v-else></i>
                                  {{ lh?.travelling_status }}
                                </div>
                                <div class="text-[15px] font-bold">
                                  {{ convertToHMS(lh?.duration) }}
                                </div>
                              </div>
                              <div>{{ formateDateString(new Date(lh?.created_at)) }}</div>
                              <div class="flex justify-between items-center text-[13px]">
                                <div>
                                  <svg class="inline" width="14" height="14" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C21.9939 17.5203 17.5203 21.9939 12 22ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C19.995 7.58378 16.4162 4.00496 12 4ZM17 13H11V7H13V11H17V13Z"
                                      fill="#2E3A59" />
                                  </svg>

                                  {{
                                    new Date(lh?.created_at).toLocaleString(
                                      "en-US",
                                      {
                                        hour: "numeric",
                                        minute: "numeric",
                                      }
                                    )
                                  }}
                                </div>
                                to
                                <div>
                                  <svg class="inline" width="12" height="12" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C21.9939 17.5203 17.5203 21.9939 12 22ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C19.995 7.58378 16.4162 4.00496 12 4ZM17 13H11V7H13V11H17V13Z"
                                      fill="#2E3A59" />
                                  </svg>

                                  {{
                                    new Date(lh?.updated_at).toLocaleString(
                                      "en-US",
                                      {
                                        hour: "numeric",
                                        minute: "numeric",
                                      }
                                    )
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </template>
                    </ul>
                  </div>
                  <!-- End of Employee Status Timeline -->
                </div>

                <!-- <div
                    class="cardDetailsContainerLocation h-[80vh] overflow-scroll"
                  >
                    <div
                      v-for="lh in locationHistory"
                      :key="lh.id"
                      @mouseover="flyToLocation(lh.lat, lh.long)"
                    >
                      <div
                        class="bg-gray-100 shadow-5xl rounded-xl p-3 my-2 cursor-pointer"
                      >
                        <div
                          class="flex justify-between items-center text-center"
                        >
                          <div>Created At</div>
                          <div class="text-[10px] font-bold">
                            {{
                              new Date(lh?.updated_at).toLocaleString("en-US", {
                                hour: "numeric",
                                minute: "numeric",
                                second: "numeric",
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                              })
                            }}
                          </div>
                        </div>
                        <div
                          class="flex justify-between items-center text-center"
                        ></div>
                        <div class="pb-3">
                          <div class="font-extrabold">Location</div>
                          <div class="text-[10px]">
                            {{ lh?.poi?.landmark }},{{ lh?.poi?.name }},
                            {{ lh?.poi?.address }},
                            {{
                              lh?.poi?.union === "N/A"
                                ? lh?.poi?.union + ", "
                                : ""
                            }}
                            {{ lh?.poi?.thana }} , {{ lh?.poi?.district }}
                          </div>
                        </div>
                        <div class="flex w-full justify-between">
                          <div class="">
                            <button
                              class="text-xs bg-black rounded-md p-0.5 w-16 text-white"
                            >
                              {{ lh?.distance }} M
                            </button>
                          </div>
                          <div class="flex mx-4">
                            <button
                              class="font-xxs bg-blue-400 rounded-md pl-0.5 pr-0.5 w-24 text-white mr-3"
                            >
                              {{ lh?.lat }}
                            </button>
                            <button
                              class="font-xxs bg-green-500 rounded-md pl-0.5 pr-0.5 w-24 text-white"
                            >
                              {{ lh?.long }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="h-full w-[300px] bg-white">
        <!-- header infor -->
        <div class="flex bg-white justify-between px-2 pt-2">
          <div class="w-full text-start text-sm font-bold">
            Favorites
          </div>
          <div class="w-full flex justify-end">
            <div class="cursor-pointer" @click="removeMarker()">
              <img :src="require(`@/assets/icons/backArrow.svg`)" alt="searchLogo" class="h-[16px] w-[16px]" />
            </div>
          </div>
        </div>
        <!-- end of header info -->
        <div class="">
          <!-- Employee Status Timeline -->
          <div class="timelineContainer">
            <ul class="sessions h-full overflow-scroll px-2">
              <div v-for="emp in employees.filter((emp) => emp.is_favorite || false)" :key="emp.id"
                @click="showEmployeeDetails(emp)">
                <div class="card text-xs my-2 cursor-pointer shadow-md">
                  <div class="card-body">
                    <div class="d-flex mb-2">
                      <img :src="`${emp.avatar}`" class="h-12 w-12 rounded-md" alt="" />
                      <div class="text-start ml-2">
                        <p class="fw-bold">{{ emp.first_name }} {{ emp.last_name }}</p>
                        <p>{{ emp.mobile }}</p>
                        <p>{{ (emp.department?.length || 0) > 0 ? `Dept: ${emp.department || ''}` : '' }}</p>
                        <p>{{ (emp.designation?.length || 0) > 0 ? `Deg: ${emp.designation || ''}` : '' }}</p>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between" style="font-size: 10px;">
                      <div class="font-bold">Last Location: </div>
                      <div>
                        {{
                          new Date(
                            emp?.last_location?.created_at
                          ).toLocaleString("en-US", {
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })
                        }}
                      </div>
                    </div>
                    <p style="font-size: 10px;">
                      {{ emp?.last_location?.poi?.landmark }},
                      {{ emp?.last_location?.poi?.name }},
                      {{ emp?.last_location?.poi?.address }},
                      {{
                        emp?.last_location?.poi?.union === "N/A"
                        ? emp?.last_location?.poi?.union + ", "
                        : ""
                      }}
                      {{ emp.last_location.poi.thana }},
                      {{ emp.last_location.poi.district }}
                    </p>
                    <div class="d-flex justify-content-between mt-2">
                      <button class="font-xxs bg-black rounded-md px-2 text-white">
                        {{ emp?.last_location?.distance }} M
                      </button>
                      <button class="font-xxs bg-blue-400 rounded-md px-2 text-white">
                        {{ emp?.last_location?.lat }}
                      </button>
                      <button class="font-xxs bg-green-500 rounded-md px-2 text-white">
                        {{ emp?.last_location?.long }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </ul>
          </div>
          <!-- End of Employee Status Timeline -->
        </div>
      </div>
      <!-- End of selected employees Information details -->
    </div>
    <!-- End of the right Sidebar -->

    <!-- Moving button for sidebar to open and close -->
    <div id="main" class="openbtn" @click="openNav()">
      <svg width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_0_1825)">
          <path
            d="M23 12L15.929 19.071L14.515 17.657L20.172 12L14.515 6.34296L15.929 4.92896L23 12ZM3.828 12L9.485 17.657L8.071 19.071L1 12L8.071 4.92896L9.485 6.34296L3.828 12Z"
            fill="black" />
        </g>
        <defs>
          <clipPath id="clip0_0_1825">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
    <!-- End of the moving butotn for sidebar to open and close -->

    <div class="ml-sidebar">
      <div class="">
        <!-- Employee list in table -->
        <div>
          <div id="mapContainer">
            <div class="map-full-screen">
              <button class="btn" @click="handleFullScreenMap">
                <i class="fas fa-expand"></i>
              </button>
            </div>
          </div>

          <!-- Task Status -->
          <div id="topBar" class="topBarEmployeeStatus pt-2 px-2 text-xs rounded-md font-bold d-none">
            <div class="h-28 overflow-scroll dark:text-gray-500">
              <div class="flex justify-between pt-1">
                <div class="flex gap-2 items-center">
                  <div>
                    <img :src="require(`@/assets/icons/info.svg`)" alt="searchLogo" class="h-[16px] w-[16px]" />
                  </div>
                  <div>Assigned</div>
                </div>
                <div>0</div>
              </div>
            </div>
          </div>
          <!-- End of task Status -->

          <div>
            <div class="mx-2">
              <div class="d-flex align-items-center mb-1">
                <div class="d-flex align-items-center mr-3">
                  <div class="mr-2">Search:</div>
                  <div class="border">
                    <input class="inputCard ml-2" type="text" v-model="searchQuery"
                      placeholder="Enter employee contact number, name, id" />
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <div class="d-flex align-items-center mr-3">
                    <div class="mr-2">Department:</div>
                    <div class="border">
                      <select class="inputCard" v-model="selectedDepartment">
                        <option value="">All</option>
                        <option :value="allDep.id" v-for="allDep in allDeartments" :key="allDep.id">
                          {{ allDep.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <div class="d-flex align-items-center mr-3">
                    <div class="mr-2">Designation:</div>
                    <div class="border">
                      <select class="inputCard" v-model="selectedDesignation">
                        <option value="">All</option>
                        <option :value="allDes.id" v-for="allDes in allDesignation" :key="allDes.id">
                          {{ allDes.title }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <p class="text-xs text-start">Showing {{ filteredEmployees.length || '0' }} entires</p>
            </div>
            <div class="appscroll" style="height: 80vh;">
              <div v-if="loading" class="text-center">
                Loading ...
              </div>
              <table v-else
                class="w-full border-collapse text-sm table table-striped table-bordered app-table-center mb-5">
                <thead class="position-sticky top-0 bg-light" style="vertical-align: middle;">
                  <tr class="text-xs">
                    <th style="min-width: 80px;" @click="sort">
                      Emp ID <i class="fas" :class="{
                        'fas fa-angle-down': !isSort,
                        'fas fa-angle-up': isSort
                      }"></i>
                    </th>
                    <th @click="() => propertySort('first_name')">Name</th>
                    <th>Cell No</th>
                    <th @click="() => propertySort('department')" class="text-start">Department</th>
                    <th @click="() => propertySort('designation')">Designation</th>
                    <th>Previous Location</th>
                    <th>Previous Locate Time</th>
                    <th>Current Location</th>
                    <th>Current Lat Long</th>
                    <th>Locate Time</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="emp in filteredEmployees" :key="emp.id">
                    <td>{{ emp?.id }}</td>
                    <td>{{ emp.first_name }} {{ emp.last_name }}</td>
                    <td>{{ emp?.mobile || emp.username || '' }}</td>
                    <td>{{ emp.department || '' }}</td>
                    <td>{{ emp.designation || '' }}</td>
                    <td>
                      {{ getPoiString(emp?.previous_location?.poi, emp?.previous_location?.lat,
                        emp?.previous_location?.long) }}
                    </td>
                    <td>
                      {{ formateDate(new Date(emp?.previous_location?.created_at)) }}
                    </td>
                    <td>
                      {{ getPoiString(emp?.last_location?.poi, emp?.last_location?.lat, emp?.last_location?.long) }}
                    </td>
                    <td>
                      {{ emp?.last_location?.lat }}, {{ emp?.last_location?.long }}
                    </td>
                    <td>
                      {{ formateDate(new Date(emp?.last_location?.updated_at)) }}
                    </td>
                    <td>
                      <div class="d-flex algin-items-center">
                        <div class="">
                          <button class="btn border border-info brx-50" @click="() => showEmployeeDetails(emp)"
                            title="View on map">
                            <i class="fa fa-map-marker text-info"></i>
                          </button>
                        </div>
                      </div>
                    </td>
                    <!-- Display additional user information in table rows -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- end of employee list table -->
      </div>
    </div>

  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import SideNav from "@/components/menu/SideNavbar.vue";

import SideNavbarTeam from "@/components/menu/SideNavbarTeam.vue";
import HeaderNavbar from "@/components/menu/headerdashboard.vue";
import headerMyTeam from "@/components/menu/headerMyTeam.vue";

import CompanyApi from "@/server/api/company";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import "leaflet-arrowheads";
import api from "@/boot/axios.js";

export default {
  data() {
    return {
      isFavSort: false,
      isSort: false,
      leftCollapsed: true,
      employees: [],
      searchQuery: "",
      selectedEmployee: null,
      locationHistory: [],
      markers: [],
      highlightedCard: null,
      toggle: true,
      user: null,
      vdate: null,
      allDeartments: [],
      allDesignation: [],
      selectedDepartment: null,
      selectedDesignation: null,
      selectedMarkerId: null,
      loading: false,
    };
  },
  components: {
    SideNav,
    headerMyTeam,
    VueDatePicker,
    SideNavbarTeam,
    HeaderNavbar,
  },
  created() {
    this.getUserLocationByCompany();
  },
  mounted() {
    this.user = {
      name: localStorage.getItem("fullname"),
      role: localStorage.getItem("role"),
      avatar: localStorage.getItem("avatar"),
    };
    console.log(this.user);
    this.initializedMap();
    this.getDepartments();
    this.getDesignation();
  },
  computed: {
    filteredEmployees() {
      return this.employees.filter((emp) => {
        // const mobile = `${emp.mobile}`;

        // return mobile.includes(this.searchQuery);
        const name = `${emp.first_name} ${emp.last_name}`.toLowerCase();
        const phone = emp.phone?.toLowerCase() || emp.username?.toLowerCase() || '';
        const email = emp.email?.toLowerCase() || '';
        const searchQuery = this.searchQuery.toLowerCase() || '';
        const departmentFilter = this.selectedDepartment
          ? emp?.department_id === this.selectedDepartment
          : true;
        const designationFilter = this.selectedDesignation
          ? emp?.designation_id === this.selectedDesignation
          : true;

        const id = emp.id?.toString() || '';

        return (
          (name.includes(searchQuery) ||
            phone.includes(searchQuery) ||
            email.includes(searchQuery) ||
            id.includes(searchQuery)) &&
          departmentFilter &&
          designationFilter
        );
      });
    },
  },
  methods: {
    initializedMap() {
      this.map = L.map("mapContainer", {
        zoomControl: true,
        zoomAnimation: false,
        fadeAnimation: true,
        markerZoomAnimation: true,
      }).setView([23.8187676, 90.4314902], 12);
      L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
        minZoom: 0,
        maxZoom: 22,
        maxNativeZoom: 18,
      }).addTo(this.map);
    },

    async getDepartments() {
      await api.get("api/company/v1/department/").then((res) => {
        this.allDeartments = res.data;
        console.log(res.data, "checking the department list");
      });
    },
    async getDesignation() {
      await api.get("api/company/v1/designation/").then((res) => {
        this.allDesignation = res.data;
        console.log(res.data, "checking the designation list");
      });
    },

    toggleSidebar(id) {
      if (id === "left") {
        this.leftCollapsed = !this.leftCollapsed;
      }
    },
    flyToLocation(lat, lng) {
      this.map.flyTo([lat, lng], 17); // Adjust the zoom level as desired
    },

    flyToLocationInMap(location) {
      this.map.flyTo([location.lat, location.long], 17);

      const marker = location.marker;
      marker.openPopup();
      marker.setZIndexOffset(99999);
    },

    removeMarker() {
      this.selectedEmployee = null;
      this.locationHistory = [];

      if (this.polylineLayer) {
        this.map.removeLayer(this.polylineLayer); // Remove the polyline layer from the map
        this.polylineLayer = null; // Set the polyline layer variable to null
      }

      if (this.markerLayer) {
        this.map.removeLayer(this.markerLayer); // Remove the marker layer from the map
        this.markerLayer = null; // Set the marker layer variable to null
      }

      this.markers = [];
      this.closeNav();
    },

    async getUserLocationByCompany() {
      try {
        this.loading = true;
        const companyApi = new CompanyApi();
        const response = await companyApi.getLocationByCompany();
        console.log(response, "checking employee location");
        this.employees = response;
        this.loading = false;
      } catch (_) {
        this.loading = false;
      }
    },

    async getUserLocationHistory(emp) {
      const companyApi = new CompanyApi();
      let startDate, endDate;
      if ((this.vdate?.value || false) && this.vdate.value.length == 2) {
        startDate = formatDateToYYYYMMD(this.vdate.value[0]);
        endDate = formatDateToYYYYMMD(this.vdate.value[1]);
      }
      const response = await companyApi.getLocationByUser(
        emp.id,
        startDate,
        endDate
      );
      this.locationHistory = response; // Assign the response to the data property
      console.log(response, "checking employee location history");
      this.createMarkers();
    },

    showEmployeeDetails(emp) {
      this.selectedEmployee = emp;
      this.createMarkers();
      this.getUserLocationHistory(emp);
      this.openNav();
    },

    handleDate(modelData) {
      if (modelData == null) {
        this.vdate = modelData;
      } else {
        this.vdate.value = modelData;
      }
      this.getUserLocationHistory(this.selectedEmployee);
    },

    createMarkers() {
      // Clear any existing markers and polylines on the map and from the corresponding layers
      this.markerLayer?.clearLayers();
      this.polylineLayer?.clearLayers();
      this.markers = [];

      // Create a layer group to hold the markers and polylines
      if (!this.markerLayer) {
        this.markerLayer = L.layerGroup().addTo(this.map);
      }
      if (!this.polylineLayer) {
        this.polylineLayer = L.layerGroup().addTo(this.map);
      }

      const reversedLocationHistory = [...this.locationHistory].reverse(); // Reverse the location history array

      reversedLocationHistory.forEach((location, index) => {
        const lat = location.lat;
        const lng = location.long;

        const status = location?.travelling_status;

        const number = index + 1;

        let markerText;

        if (index === 0) {
          markerText = "Start"; // Set marker text as "Start" for the first response
        } else if (index === reversedLocationHistory.length - 1) {
          markerText = "End"; // Set marker text as "End" for the last response
        } else {
          markerText = index; // Use the index as the marker text for other responses
        }

        let markerColor = "#FFA500"; // Default orange color

        if (index === 0) {
          markerColor = "#FF0000"; // Red color for Start marker
        } else if (index === reversedLocationHistory.length - 1) {
          markerColor = "#00FF00"; // Green color for End marker
        } else if (status === "Idle") {
          markerColor = "#FFA500"; // Orange color for idel locations
        } else {
          markerColor = "#2EA5EA"; // Blue color for other markers
        }

        const defaultIcon = L.divIcon({
          html: `
            <div>
            <svg width="25" height="40" viewBox="0 0 35 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5 0C7.825 0 0 7.825 0 17.5C0 30.625 17.5 50 17.5 50C17.5 50 35 
                30.625 35 17.5C35 7.825 27.175 0 17.5 0Z" fill="${markerColor}"/>
                <text x="50%" y="40%" dominant-baseline="middle" text-anchor="middle" fill="white" font-size="14">${markerText}</text>
            </svg>
            </div>
        `,
          className: "",
          iconSize: [24, 40],
          iconAnchor: [12, 40],
        });

        const marker = L.marker([lat, lng], {
          icon: defaultIcon,
          id: location.id
        }).addTo(
          this.markerLayer
        ).on('click', (e) => {
          this.selectedMarkerId = e.target.options.id;
          const selectedMarkerDom = document.getElementById(`map-marker-card-${this.selectedMarkerId}`)
          selectedMarkerDom.scrollIntoView();
        });
        const popupContent = `
        <div class="leafLetCard">
          <div class="mapLocationName">
            <div class="svgIconClass">
              <svg width="14" height="14" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C21.9939 17.5203 17.5203 21.9939 12 22ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C19.995 7.58378 16.4162 4.00496 12 4ZM17 13H11V7H13V11H17V13Z"
                  fill="#117abf" />
              </svg>
            </div>
            <div>
              ${formateDateTime(new Date(location.created_at))}
            </div>
          </div> 
          <div class="mapLocationName pt-1">
            <div class="svgIconClass">
              <svg width="15" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 23.728L5.636 17.364C4.37734 16.1053 3.52018 14.5017 3.17292 12.7558C2.82567 11.01 3.0039 9.20041 3.68509 7.55588C4.36629 5.91136 5.51984 4.50575 6.99988 3.51683C8.47992 2.5279 10.22 2.00006 12 2.00006C13.78 2.00006 15.5201 2.5279 17.0001 3.51683C18.4802 4.50575 19.6337 5.91136 20.3149 7.55588C20.9961 9.20041 21.1743 11.01 20.8271 12.7558C20.4798 14.5017 19.6227 16.1053 18.364 17.364L12 23.728ZM16.95 15.95C17.9289 14.971 18.5955 13.7237 18.8655 12.3659C19.1356 11.008 18.9969 9.60058 18.4671 8.32154C17.9373 7.0425 17.04 5.94929 15.8889 5.18015C14.7378 4.41101 13.3844 4.00049 12 4.00049C10.6156 4.00049 9.26221 4.41101 8.11108 5.18015C6.95995 5.94929 6.06275 7.0425 5.53291 8.32154C5.00308 9.60058 4.86441 11.008 5.13445 12.3659C5.40449 13.7237 6.0711 14.971 7.05 15.95L12 20.9L16.95 15.95ZM12 13C11.4696 13 10.9609 12.7893 10.5858 12.4142C10.2107 12.0391 10 11.5304 10 11C10 10.4696 10.2107 9.96084 10.5858 9.58577C10.9609 9.2107 11.4696 8.99998 12 8.99998C12.5304 8.99998 13.0391 9.2107 13.4142 9.58577C13.7893 9.96084 14 10.4696 14 11C14 11.5304 13.7893 12.0391 13.4142 12.4142C13.0391 12.7893 12.5304 13 12 13Z" fill="#117abf"/>
              </svg>
            </div>
            <div>
              ${this.getPoiString(location.poi, lat, lng)}
              <div>${lat || ''}, ${lng || ''}</div>
            </div>
          </div> 
        </div>`;
        marker.bindPopup(popupContent);
        location.marker = marker;
        location.defaultIcon = defaultIcon;
        this.markers.push(marker); // Store the marker in the markers array
      });

      // Create a polyline based on the marker locations
      if (this.markers.length > 1) {
        const latLngs = this.markers.map((marker) => marker.getLatLng());
        const polyline = L.polyline(latLngs, { color: "blue" })
          .arrowheads({
            frequency: "250px",
            size: "12px",
            fill: true,
          })
          .addTo(this.polylineLayer);
      }
    },
    // Right sidebar Fucntion
    openNav() {
      const sidebar = document.getElementById("mySidebar");
      const main = document.getElementById("main");
      const topBar = document.getElementById("topBar");

      if (sidebar.style.width === "300px") {
        // If the sidebar is already open, close it
        sidebar.style.width = "0";
        main.style.marginRight = "0";
        topBar.style.marginTop = "-300px";
      } else {
        // If the sidebar is closed, open it
        sidebar.style.width = "300px";
        main.style.marginRight = "300px";
        topBar.style.marginTop = "40px";
      }
    },
    closeNav() {
      const sidebar = document.getElementById("mySidebar");
      const main = document.getElementById("main");
      const topBar = document.getElementById("topBar");
      sidebar.style.width = "0";
      main.style.marginRight = "0";
      topBar.style.marginTop = "-300px";
    },
    // End of the Right sidebar Fucntion

    // miniute hour secound converted
    convertToHMS(duration) {
      const hours = Math.floor(duration / 3600);
      const minutes = Math.floor((duration % 3600) / 60);
      const seconds = duration % 60;
      return `${hours}h ${minutes}m ${seconds}s`;
    },
    // end of miniute hour secound converted

    handleSelectFav(userId, value) {
      api
        .patch(`api/core/v1/company-user-update/${userId}/`, { is_favorite: value })
        .then((_) => {
          this.$toast.success(`Successfully Done`, {
            position: "top",
          });
          this.getUserLocationByCompany();
        });

    },

    formateDate(date) {
      return formateDateTime(date);
    },

    sort() {
      if (this.isSort) {
        this.filteredEmployees = this.filteredEmployees.sort((a, b) => {
          if (a.id === undefined || b.id === undefined) {
            return a.id === undefined ? 1 : -1;
          }
          return b.id - a.id
        })
      } else {
        this.filteredEmployees = this.filteredEmployees.sort((a, b) => {
          if (a.id === undefined || b.id === undefined) {
            return a.id === undefined ? 1 : -1;
          }
          return a.id - b.id
        })
      }
      this.isSort = !this.isSort;
    },

    favSort() {
      if (this.isFavSort) {
        this.filteredEmployees = this.filteredEmployees.sort((a, b) => {
          const aValue = a['is_favorite'] !== undefined ? a['is_favorite'] : false;
          const bValue = b['is_favorite'] !== undefined ? b['is_favorite'] : false;
          return aValue - bValue;
        })
      } else {
        this.filteredEmployees = this.filteredEmployees.sort((a, b) => {
          const aValue = a['is_favorite'] !== undefined ? a['is_favorite'] : false;
          const bValue = b['is_favorite'] !== undefined ? b['is_favorite'] : false;
          return bValue - aValue;
        })
      }
      this.isFavSort = !this.isFavSort;
    },

    propertySort(propertyName) {
      this.filteredEmployees = this.filteredEmployees.sort((a, b) => {
        if (a[propertyName] === undefined || b[propertyName] === undefined) {
          return a[propertyName] === undefined ? 1 : -1;
        }
        return (a[propertyName] || '').toLowerCase().localeCompare((b[propertyName] || '').toLowerCase());
      })
    },

  },
};
</script>

<style scoped>
/* Right sidebar css */

.sidebar {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 5;
  top: 0;
  right: 0;
  background-color: #ffffff36;
  transition: 0.5s;
}

.sidebar .contentOfSidebar {
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  color: #f1f1f1;
}

.sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #0d6efd47;
  color: white;
  padding: 15px 5px;
  border: none;
  border-radius: 10px 0 10px 0;
  box-shadow: -4px 0px 10px 6px;
}

#main {
  transition: margin-right 0.5s;

  position: absolute;
  top: 50%;
  right: 0%;
  z-index: 5;
}

@media screen and (max-height: 350px) {
  .sidebar {
    padding-top: 15px;
  }

  .sidebar a {
    font-size: 18px;
  }
}

/* end of Right sidebar css */

/* Inside Rightbar Timeline Style */

.sessions,
.sessionsLi {
  list-style: none;
}

.sessionTIME {
  position: absolute;
  font-size: 12px;
  font-weight: bolder;
}

.sessionDistance {
  position: absolute;
  font-size: 10px;
  font-weight: bolder;
  height: 100%;
  margin-top: 43px;
  width: 60px;
  display: flex;
  margin-left: -2px;
  text-align: end;
  justify-content: end;
}

.red-text {
  display: none;
}

/* .timelineContainer {
  padding: 1rem;
  border-radius: 15px;
  background-color: rgb(250, 250, 250);
} */

.sessions {
  border-radius: 12px;
  position: relative;
}

.sessionsLi {
  padding-bottom: 50px;
  border-left: 4px solid #3f51b5;
  position: relative;
  padding-left: 20px;
  margin-left: 72px;

  &:last-child {
    border: 0px;
    padding-bottom: 0;
  }

  &:before {
    content: "";
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid #000000;

    border-radius: 50%;
    position: absolute;
    left: -13px;
    top: 0px;
  }
}

.sessionsCard {
  min-height: 50px;
  min-width: 180px;
  text-align: start;
  margin-left: -5px;
  color: #3f51b5;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  font-family: sans-serif;
  line-height: 1.5;
  font-size: 12px;
  z-index: 2;
  border-radius: 5px;
  margin-top: -31px;
  width: 100%;
  font-weight: bolder;
  box-shadow: 5px 5px 10px 1px #e6e6e6;
}

.idle_color {
  color: #ff5722 !important;
}

.pointyShape {
  height: 2px;
  width: 56px;
  background: #9e9e9e57;
  color: red;
  /* z-index: 20; */
  position: absolute;
  /* top: 190px; */
  margin-top: 9px;
  /* left: 511px; */
  right: 154px;
}

.numbericValues {
  position: absolute;
  height: 22px;
  width: 22px;
  margin-left: -52px;
  top: -1px;
  border-radius: 20px;
  background: #4caf50;
  font-weight: bolder;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

/* End of Inside Rightbar Timeline Style */

/* Top right Profile information */
.topNavTeam {
  right: 0%;
  z-index: 3;
}

/* End of Top right Profile information */

#mapContainer {
  width: 97%;
  max-width: 100%;
  height: 450px;
  z-index: 1;
  margin: 20px;
}

.sideNavTeam {
  position: absolute;
  left: 0%;
  z-index: 3;
}

.sideNavTeamFirst {
  height: 100vh;
  width: 408px;
  background-color: rgba(255, 255, 255);
  box-shadow: 1px 2px #eeeeee;
}

.topNavTeam {
  right: 0%;
  z-index: 3;
}

.sideNavTeamElement {
  display: flex;
  align-items: center;
  height: 100vh;
  margin-left: -10px;
  z-index: -1;
}

.topNavTeam {
  right: 0%;
}

.topBarEmployeeStatus {
  position: fixed;
  width: 200px;
  top: 2%;
  left: 66%;
  z-index: 1;
  margin-top: -300px;
  transition: 0.3s;
  background-color: white;
}

.secondcontainerCard {
  position: absolute;
  /* Position the container div absolutely */
  top: 20%;
  /* Shift it down by 50% of the wrapper height */
  left: 20%;
  z-index: 2;
  opacity: 0.95;

  /* Vertically center the container div */
}

.employeeContainerCard {
  height: 92vh;
}

.nameCardContainer {
  font-size: 13px;
  text-align: start;
  font-weight: bolder;

  background: #cac9c9;
}

.cardDetailsContainer {
  font-size: 13px;
  text-align: start;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.cardDetailsContainerLocation {
  font-size: 13px;
  text-align: start;
}

.latlongCardMyTeam {
  font-size: 12px;
}

.inputCard {
  background: transparent !important;
  border: none;
  outline: none;
}

th {
  background: #1fa8e1 !important;
}

th,
td {
  text-align: left;
  padding: 8px;
}

.btn-blue {
  background: #1fa8e1 !important;
}
</style>
