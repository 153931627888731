import api from "@/boot/axios";

export default {
    getSystemColors: async () => {
        const response = await api
            .get(`/api/super-admin/v1/color/`)
            .then((res) => {
                return res.data;
            });

        return response;
    }
}