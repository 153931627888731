<template>
  <div>
    <svg
      width="21"
      height="15"
      viewBox="0 0 21 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.1665 1.25H18.8332M2.1665 7.5H16.9998M2.1665 13.75H13.9998"
        stroke="#202331"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>    