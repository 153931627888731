export default {
    data: () => ({
        baseUrl: 'https://lbsadmin.zedtrack.com',
    }),

    methods: {
        formateDateTimeString(value, defaultValue) {
            if (!value) {
                return defaultValue || '*';
            }

            const date = new Date(value)

            const months = [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];

            const day = date.getDate();
            const month = months[date.getMonth()];
            const year = date.getFullYear().toString().slice(-2);
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const period = hours >= 12 ? "PM" : "AM";
            const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
            const formattedMinutes = minutes.toString().padStart(2, "0");

            const formattedDate = `${day} ${month} ${year}, ${formattedHours}:${formattedMinutes} ${period}`;
            return formattedDate;
        },

        formateDateString(value) {
            if (!value) {
                return '';
            }

            const date = new Date(value)

            const months = [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];

            const day = date.getDate();
            const month = months[date.getMonth()];
            const year = date.getFullYear().toString().slice(-2);

            const formattedDate = `${day} ${month} ${year}`;
            return formattedDate;
        },

        formateTimeString(value) {
            if (!value) {
                return '';
            }
            try {
                const times = value.split(':');
                if (times.length > 0) {
                    const hours = times[0];
                    const minutes = times[1];
                    const period = hours >= 12 ? "PM" : "AM";
                    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
                    const formattedMinutes = minutes.toString().padStart(2, "0");

                    const formattedDate = `${formattedHours}:${formattedMinutes} ${period}`;
                    return formattedDate;
                }

                return '';
            } catch (_) {
                return '';
            }
        },

        formateDurationFromSec(value) {
            let date = new Date(0);
            date.setSeconds(value);
            let timeString = date.toISOString().substring(11, 19);
            return timeString;
        },

        getPoiString(poi, lat, lng) {
            if ((!poi) || !(poi?.lat || false)) {
                return `${lat || ''} ${(lat && lat.length > 0) ? ',' : ''} ${lng || ''}`;
            }
            return `${poi?.landmark?.length > 0 ? (poi?.landmark + ', ') : ''}
                ${poi?.name?.length > 0 ? (poi?.name + ', ') : ''}
                ${poi?.address?.length > 0 ? (poi?.address + ', ') : ''}
                ${(poi?.union?.length > 0 && poi?.union != 'NA') ? (poi?.union + ', ') : ''}
                ${poi?.thana?.length > 0 ? (poi?.thana + ', ') : ''}
                ${poi?.district?.length > 0 ? (poi?.district + ', ') : ''}
                ${poi?.division?.length > 0 ? poi?.division : ''}`;
        },

        handleFullScreenMap() {
            const dom = document.getElementById('mapContainer');
            if (!document.fullscreenElement) {
                dom.requestFullscreen();
            } else {
                document.exitFullscreen();
            }
        }
    }
}