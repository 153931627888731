<template>
    <div class="">
        <div class="h-[100vh] w-[100px] absolute hover:w-[200px]">
            <SidenavteamMenu />
        </div>

        <HeaderNavbar title="Coming Soon" />

        <div class="ml-sidebar">
            <div class="d-flex justify-content-center mt-2">
                <section class="info-section">
                    <div class="text-section">
                        <h1><span>Under Construction</span> coming soon</h1>
                        <p> Hello! We're currently building features.
                            Stay up-to-date with announcements and our launch deals.
                        </p>
                        <img src="../../../assets/under-construction.png" alt="" />
                    </div>
                </section>
            </div>
        </div>

    </div>
</template>
  
<script>
import SidenavteamMenu from "@/components/menu/SideNavbarTeam.vue";
import HeaderNavbar from "@/components/menu/headerdashboard.vue";

export default {
    components: { SidenavteamMenu, HeaderNavbar },
};
</script>

<style scoped>
.main-container {
    display: grid;
    grid-template-columns: 57.5% 1fr;
}

.logo {
    height: 2.3rem;
    max-width: 100%;
}

/* Info Section */
.info-section {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    padding: 2rem 10.2rem;
    gap: 1rem;
    background: linear-gradient(135deg, hsl(0, 0%, 100%), hsl(0, 100%, 98%));
    background-size: cover;
    justify-content: space-evenly;
}

h1 {
    font-size: 4rem;
    text-transform: uppercase;
    font-weight: 600;
    display: inline;
    color: var(--dark-grayish-red);
    letter-spacing: 1.1rem;
    line-height: 4.2rem;
}

h1 span {
    color: var(--desaturated-red);
    font-weight: 300;
    font-size: 1rem;
    line-height: 1rem !important;
}

.info-section p {
    position: relative;
    color: var(--desaturated-red);
    line-height: 1.8rem;
    font-weight: 400;
    width: 85%;
    padding-top: 1.2rem;
}

.text-section {
    max-width: 550px;
}

@media screen and (max-width: 1025px) {
    .main-container {
        grid-template-columns: 50% 1fr;
    }
}

@media screen and (max-width: 900px) {
    .info-section {
        display: initial;
    }

    .main-container {
        grid-template-columns: 1fr;
        grid-template-rows: 15% 50% 1fr;
        grid-template-areas:
            "icon"
            "hero"
            "info";
    }

    .img-section {
        grid-area: hero;
    }

    .logo {
        position: absolute;
        grid-area: icon;
        top: 5%;
        left: 5%;
    }

    .info-section {
        grid-area: info;
        position: initial;
        padding: 5rem 2rem;
    }

    h1 {
        font-size: 2.5rem;
    }
}
</style>
  