import ReportsView from "@/views/company/report/Index.vue";
import TaskReportView from "@/views/company/report/TaskReport.vue";
import AttendanceReportView from "@/views/company/report/AttendanceReport.vue";
import LocationReportView from "@/views/company/report/EmployeeLocationreport.vue";
import ErrorLogReportView from "@/views/company/report/ErrorLogReport.vue";
import DeviceLogReportView from "@/views/company/report/DeviceLogReport.vue";
import PunchInOutReportView from "@/views/company/report/PunchInOutReport.vue";
import GeofenchViolationReportView from "@/views/company/report/GeofenchViolationReport.vue";

export default [
    {
        path: '/reports',
        name: 'company-reports',
        meta: {
            key: 'report',
            title: 'Reports',
            auth: true,
        },
        component: ReportsView
    },
    {
        path: '/reports/task-report',
        name: 'company-task-report',
        meta: {
            key: 'report',
            title: 'Task Report',
            auth: true,
        },
        component: TaskReportView
    },
    {
        path: '/reports/attendance-report',
        name: 'company-attendance-report',
        meta: {
            key: 'report',
            title: 'Attendance Report',
            auth: true,
        },
        component: AttendanceReportView
    },
    {
        path: "/user-location-report",
        name: "user-location-report",
        meta: {
            key: 'report',
            title: 'Location Report',
            auth: true,
        },
        component: LocationReportView
    },
    {
        path: "/error-log-report",
        name: "company-error-log-report",
        meta: {
            key: 'report',
            title: 'Error Log Report',
            auth: true,
        },
        component: ErrorLogReportView
    },
    {
        path: "/device-log-report",
        name: "company-device-log-report",
        meta: {
            key: 'report',
            title: 'Device Log Report',
            auth: true,
        },
        component: DeviceLogReportView
    },
    {
        path: "/punch-in-out-report",
        name: "company-punch-in-out-report",
        meta: {
            key: 'report',
            title: 'Punch In Out Report',
            auth: true,
        },
        component: PunchInOutReportView
    },
    {
        path: "/geofench-violation-report",
        name: "company-geofench-violation-report",
        meta: {
            key: 'report',
            title: 'Geofench Violation',
            auth: true,
        },
        component: GeofenchViolationReportView
    }
];