import api from "../../boot/axios";

export default class CompanyApi {
  //login
  getLocationByCompany = async () => {
    const company = localStorage.getItem("company");
    const response = await api
      .get(`api/location/v1/location-filter/?company=${company}`)
      .then((res) => {
        return res.data.results;
      });

    return response;
  };
  //user-validate /token/verify/
  // https://lbsadmin.zedtrack.com/api/location/v1/user-location-filter/?company=1&user=2
  getLocationByUser = async (userId, startingDate, endingDate) => {
    const company = localStorage.getItem("company");
    if (!startingDate || !endingDate) {
      startingDate = "";
      endingDate = "";
    } else if (startingDate === endingDate) {
      endingDate = "";
    }
    const response = await api
      .get(
        `api/location/v1/user-location-filter/?company=${company}&user=${userId}&start_date=${startingDate}&end_date=${endingDate}`
      )
      .then((res) => {
        return res.data.results;
      });

    return response;
  };
  //https://lbsadmin.zedtrack.com/api/report/v1/location-report/?start_date_time=2023-03-14T12:56:20&end_date_time=2023-03-16T12:56:20&user=3&company=1
  // `api/location/v1/user-location-filter/?company=${company}&user=${userId}&start_date=${startingDate}&end_date=${endingDate}`
  getDataForExcel = async (userId, startingDate, endingDate, url, limit) => {
    if (!startingDate && !endingDate) {
      const today = new Date();
      startingDate = today.toISOString();
      endingDate = startingDate;
    }
    const company = localStorage.getItem("company");
    let datestr = '';
    if (startingDate && endingDate) {
      datestr = `&start_date_time=${startingDate}&end_date_time=${endingDate}`;
    }
    let userstr = '';
    if (userId || false) {
      userstr = `&user__in_data=${userId}`;
    }
    const response = await api
      .get(url || `api/report/v1/location-report/?company=${company}&${datestr}${userstr}&page_size=${limit || 100}`)
      .then((res) => {
        return res.data;
      });
    return response;
  };

  getUserListByCompany = async () => {
    //https://lbsadmin.zedtrack.com/api/company/v1/user-list-by-company/?company=1
    const company = localStorage.getItem("company");
    const response = await api
      .get(`api/company/v1/user-list-by-company/?company=${company}`)
      .then((res) => {
        console.log(res.data);
        return res.data.results;
      });
    return response;
  };

  getUserByProps = async ({ department, designation }) => {
    const company = localStorage.getItem("company");
    const queryString = `?company=${company}&user_department__in=${department || ''}&user_designation__in=${designation || ''}`;
    const response = await api
      .get(`api/company/v1/user-list-by-com-depart-design/${queryString}`)
      .then((res) => {
        return res.data.results;
      });
    return response;
  }

  getCompanyTotalUserStatus = async () => {
    // https://lbsadmin.zedtrack.com/api/location/v1/active-inactive-location-filter-count/?company=2
    const company = localStorage.getItem("company");
    const response = await api
      .get(
        `api/location/v1/active-inactive-location-filter-count/?company=${company}`
      )
      .then((res) => {
        console.log(res.data);
        return res.data.results;
      });
    return response;
  };
}
