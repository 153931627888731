<template>
    <div>
        <div v-if="pois.length > 0" class="appscroll" style="max-height: 67vh;">
            <table class="table table-poi table-striped">
                <thead class="position-sticky top-0">
                    <tr class="text-start">
                        <th>Name</th>
                        <th>Address</th>
                        <th>Landmark</th>
                        <th>Lat</th>
                        <th>Lng</th>
                        <th>Category</th>
                        <th>Union</th>
                        <th>Thana</th>
                        <th>District</th>
                        <th>Division</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="text-start" v-for="poi in pois" :key="poi.id">
                        <td>{{ poi.name || '' }}</td>
                        <td>{{ poi.address || '' }}</td>
                        <td>{{ poi.landmark || '' }}</td>
                        <td>
                            <div class="badge badge-success">{{ poi.lat || '' }}</div>
                        </td>
                        <td>
                            <div class="badge badge-success">{{ poi.long || '' }}</div>
                        </td>
                        <td>
                            <div class="badge badge-orange">{{ poi.poi_category_details?.name || '' }}</div>
                        </td>
                        <td>{{ poi.union || '' }}</td>
                        <td>{{ poi.thana || '' }}</td>
                        <td>{{ poi.district || '' }}</td>
                        <td>{{ poi.division || '' }}</td>
                        <td>
                            <router-link :to="{ name: 'company-poi-edit', params: { id: poi.id } }" class="btn">
                                <i class="fa fa-edit text-info"></i>
                            </router-link>
                            <button type="button" class="btn" @click="deletePoi(poi)">
                                <i class="fa fa-trash text-danger"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import apis from "@/server/api/company/index";

export default {
    props: ["pois"],

    emits: ["init"],

    methods: {
        async deletePoi(poi) {
            if (poi.id || false) {
                if (confirm(`are you sure, you want to delete ${poi.name || ""}?`)) {
                    const response = await apis.deletePoi(poi.id);
                    if (response.status || false) {
                        this.$toast.success(`Poi delete successfully`, {
                            position: "top",
                        });
                    } else {
                        if (response.message.length || false) {
                            this.$toast.error(`${response.message}`, {
                                position: "top",
                            });
                        } else {
                            this.$toast.error(`Something went wrong! please refresh.`, {
                                position: "top",
                            });
                        }
                    }
                }
                this.$emit('init');
            }

        },

    }
}
</script>