<template>
    <div class="mt-2 row">
        <div class="col-md-3">
            <div class="card">
                <div class="card-body">
                    <div class="mt-2 form-layout">
                        <div class="mx-4 app-table mt-2">
                            <div v-if="loading">Loading...</div>
                            <table v-else class="table text-start">
                                <thead>
                                    <tr>
                                        <th>Workdays</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="form-check">
                                                <input class="form-check-input app-checkbox" type="checkbox" id="Saturday"
                                                    v-model="form.sat_working">
                                                <label class="form-check-label mt-1 ml-3" for="Saturday">Saturday</label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="form-check">
                                                <input class="form-check-input app-checkbox" type="checkbox" id="Sunday"
                                                    v-model="form.sun_working">
                                                <label class="form-check-label mt-1 ml-3" for="Sunday">Sunday</label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="form-check">
                                                <input class="form-check-input app-checkbox" type="checkbox" id="Monday"
                                                    v-model="form.mon_working">
                                                <label class="form-check-label mt-1 ml-3" for="Monday">Monday</label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="form-check">
                                                <input class="form-check-input app-checkbox" type="checkbox" id="Tuesday"
                                                    v-model="form.tue_working">
                                                <label class="form-check-label mt-1 ml-3" for="Tuesday">Tuesday</label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="form-check">
                                                <input class="form-check-input app-checkbox" type="checkbox" id="Wednesday"
                                                    v-model="form.wed_working">
                                                <label class="form-check-label mt-1 ml-3" for="Wednesday">Wednesday</label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="form-check">
                                                <input class="form-check-input app-checkbox" type="checkbox" id="Thursday"
                                                    v-model="form.thu_working">
                                                <label class="form-check-label mt-1 ml-3" for="Thursday">Thursday</label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="form-check">
                                                <input class="form-check-input app-checkbox" type="checkbox" id="Friday"
                                                    v-model="form.fri_working">
                                                <label class="form-check-label mt-1 ml-3" for="Friday">Friday</label>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <button type="button" class="btn btn-outline-primary" @click="submit">
                                Update Changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import apis from "@/server/api/company/index";

export default {

    data() {
        return {
            loading: false,
            form: {
                sat_working: false,
                sun_working: false,
                mon_working: false,
                tue_working: false,
                wed_working: false,
                thu_working: false,
                fri_working: false,
            }
        }
    },

    mounted() {
        this.init();
    },

    methods: {
        async init() {
            this.loading = true;
            await this.getWeekDays();
            this.loading = false;
        },

        async getWeekDays() {
            const temp = await apis.getWorkdays();
            if (temp.length > 0) {
                this.form = temp[0]
            }
        },

        async submit() {
            if (confirm('Are you sure, you want to update workdays?')) {
                const response = await apis.updateWorkdays(this.form);
                if (response.status || false) {
                    this.$toast.success(`Workdays update successfully`, {
                        position: "top",
                    });
                    this.getWeekDays();
                } else {
                    if (response.message.length || false) {
                        this.$toast.error(`${response.message}`, {
                            position: "top",
                        });
                    } else {
                        this.$toast.error(`Something went wrong! please refresh.`, {
                            position: "top",
                        });
                    }
                }
            }
        }
    },
}
</script>