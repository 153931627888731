<template>
    <div>
        <div class="h-[100vh] w-[100px] absolute hover:w-[200px]">
            <SidenavteamMenu />
        </div>

        <HeaderNavbar title="Attendance Report" />

        <div class="ml-sidebar">
            <div class="">
                <div class="mx-4 my-2">
                    <div class="row">
                        <div class="col-md-9">
                            <div class="row">
                                <div class="col-md-4">
                                    <VueDatePicker @closed="handleDateTimeRange" v-model="dates" range
                                        :partial-range="false" :enableTimePicker="true" :placeholder="datePlaceholder"
                                        :min-date="new Date(Date.now() - 3888000000)" ref="datepicker">
                                        <template #left-sidebar>
                                            <div style="min-width: 100px;">
                                                <button type="button" class="btn btn-sm btn-outline-primary w-100"
                                                    @click="pickToday">
                                                    Today
                                                </button>
                                                <button type="button" class="btn btn-sm btn-outline-primary-rev w-100 mt-1"
                                                    @click="pickYesterday">
                                                    Yesterday
                                                </button>
                                                <button type="button" class="btn btn-sm btn-outline-primary-rev w-100 mt-1"
                                                    @click="pickLast7Days">
                                                    Last 7 Days
                                                </button>
                                                <button type="button" class="btn btn-sm btn-outline-primary-rev w-100 mt-1"
                                                    @click="pickLast30Days">
                                                    Last 30 Days
                                                </button>
                                            </div>
                                        </template>
                                    </VueDatePicker>
                                </div>
                                <div class="col-md-8 text-start">
                                    <filter-button @reset="resetFilter" @callback="getAttendanceReport" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 text-end">
                            <div>
                                <button type="button" class="btn btn-outline-primary-rev px-4 py-1" @click="exportToExcel"
                                    :disabled="isExporting">
                                    Export
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mx-4 mt-2 mb-4">
                    <hr>
                    <div class="row text-start my-2">
                        <div class="col-md-2">
                            <h2 class="text-xl">
                                Present <span class="text-info fw-semi-bold ml-2" style="font-size: 28px">
                                    {{ totalPresent }} %
                                </span>
                            </h2>
                        </div>
                        <div class="col-md-2">
                            <h2 class="text-xl">
                                Late <span class="text-info fw-semi-bold ml-2" style="font-size: 28px">
                                    {{ totalLate }} %
                                </span>
                            </h2>
                        </div>
                        <div class="col-md-2">
                            <h2 class="text-xl">
                                Absent <span class="text-info fw-semi-bold ml-2" style="font-size: 28px">
                                    -
                                </span>
                            </h2>
                        </div>
                        <div class="col-md-2">
                            <h2 class="text-xl">
                                Working Hrs <span class="text-info fw-semi-bold ml-2" style="font-size: 28px">
                                    -
                                </span>
                            </h2>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="mx-4 report-table mt-2">
                    <div class="appscroll" style="height: 62vh;">
                        <table class="table table-poi table-bordered " id="task-report-table">
                            <thead class="position-sticky top-0 text-xs">
                                <tr class="text-start">
                                    <th>SL</th>
                                    <th style="min-width: 70px;">Emp ID</th>
                                    <th style="min-width: 130px;">Emp Name</th>
                                    <th>Emp Phone</th>
                                    <th>Department</th>
                                    <th>Designation</th>
                                    <th style="min-width: 100px;">Date</th>
                                    <th>Status</th>
                                    <th style="min-width: 80px;">Punch-In Time</th>
                                    <th style="max-width: 500px; min-width: 230px;">Punch-In Address</th>
                                    <th style="min-width: 80px;">Punch-Out Time</th>
                                    <th style="max-width: 500px; min-width: 230px;">Punch-Out Address</th>
                                    <th title="Distance From Punch In Location to Base Location">Distance</th>
                                    <th>Working Hours</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-if="attendanceReport.length > 0">
                                    <tr v-for="(item, index) in attendanceReport" :key="item.id">
                                        <td>{{ index + 1 }}</td>
                                        <td>{{ item.employee_id }}</td>
                                        <td>{{ item.employee_name }}</td>
                                        <td>{{ item.employee_mobile_number }}</td>
                                        <td>{{ item.employee_department }}</td>
                                        <td>{{ item.employee_designation }}</td>
                                        <td>{{ formateDateString(item.date) }}</td>
                                        <td>
                                            <div :class="{
                                                'text-success': item.status == 'Present',
                                                'text-warning': item.status == 'Late'
                                            }">{{ item.status }}</div>
                                        </td>
                                        <td>{{ formateTimeString(item.punch_in_time) }}</td>
                                        <td>{{ item.punch_in_address }}</td>
                                        <td>{{ formateTimeString(item.punch_out_time) }}</td>
                                        <td>{{ item.punch_out_address }}</td>
                                        <td>{{ item.distance_from_base }}</td>
                                        <td>{{ item.working_hours }}</td>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td colspan="14">
                                            <div class="my-3 text-start">No data available in table</div>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>

                </div>
                <div class="mx-4 my-3">
                    <div class="d-flex justify-content-between">
                        <div>
                            <div class="form-group">
                                <div class="input-group">
                                    Showing
                                    <select @change="handlePageLimit" class="border mx-2" :value="pageLimit">
                                        <option value="10">10</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="500">500</option>
                                    </select> of {{ totalCount }} entries
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end">
                            <div class="mr-2">
                                <button @click="fetchPageData('previous')" :disabled="pagination.prev == ''"
                                    class="btn btn-outline-primary">
                                    Previous
                                </button>
                            </div>
                            <div>
                                <button @click="fetchPageData('next')" :disabled="pagination.next == ''"
                                    class="btn btn-outline-primary">
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SidenavteamMenu from "@/components/menu/SideNavbarTeam.vue";
import HeaderNavbar from "@/components/menu/headerdashboard.vue";
import apis from "@/server/api/company/index";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import XLSX from "xlsx-js-style";
import FilterButton from "@/components/report/FilterButton.vue";

import {mapActions} from "vuex";

export default {
    components: { SidenavteamMenu, HeaderNavbar, VueDatePicker, FilterButton },

    data() {
        return {
            totalCount: null,
            pageUrl: null,
            pageLimit: 100,
            startingDate: null,
            endingDate: null,
            exportedAttendanceReport: [],
            attendanceReport: [],
            dates: null,
            datePlaceholder: 'Select Date',
            pagination: {
                next: '',
                prev: '',
            },
            isExporting: false,
        }
    },

    computed: {
        totalPresent() {
            const temp = this.attendanceReport.reduce((acc, cur) => {
                if (cur.status == 'Present') {
                    return acc + 1
                }
                return acc;
            }, 0);

            return Math.floor((temp / this.attendanceReport.length) * 100) || 0;
        },

        totalLate() {
            const temp = this.attendanceReport.reduce((acc, cur) => {
                if (cur.status == 'Late') {
                    return acc + 1
                }
                return acc;
            }, 0);
            return Math.floor((temp / this.attendanceReport.length) * 100) || 0;
        },
    },

    methods: {
        ...mapActions("report", ["getUserAttendanceReportData"]),

        async getAttendanceReport() {
            const response = await this.getUserAttendanceReportData({
                startingDate: this.startingDate,
                endingDate: this.endingDate,
                limit: this.pageLimit,
                url: this.pageUrl,
            });
            console.log(response);
            this.attendanceReport = response.results.content;
            this.pagination.next = response.next || response.links?.next || '';
            this.pagination.prev = response.previous || response.links?.previous || '';
            this.totalCount = response.count;
        },

        handlePageLimit(e) {
            this.pageLimit = e.target.value;
            this.getAttendanceReport();
        },

        formateDate(date) {
            return formateDateTime(new Date(date))
        },

        async handleDateTimeRange() {
            if (!this.dates) {
                return;
            }
            try {
                this.startingDate = this.dates[0].toISOString();
                this.endingDate = this.dates[1].toISOString();
                this.getAttendanceReport();
            } catch (_) {
                return;
            }
        },

        fetchPageData(status) {
            if (status == 'previous' && this.pagination.prev != '') {
                this.pageUrl = this.pagination.prev;
                this.getAttendanceReport();
            } else if (status == 'next' && this.pagination.next != '') {
                this.pageUrl = this.pagination.next;
                this.getAttendanceReport();
            }
        },

        pickToday() {
            this.datePlaceholder = 'Today';
            this.startingDate = new Date().toISOString();
            this.endingDate = new Date().toISOString();
            this.getAttendanceReport();
            this.$refs.datepicker.closeMenu();
        },

        pickYesterday() {
            this.datePlaceholder = 'Yesterday';
            const now = new Date();
            this.startingDate = new Date(now.setDate(now.getDate() - 1)).toISOString();
            this.endingDate = new Date().toISOString();
            this.getAttendanceReport();
            this.$refs.datepicker.closeMenu();
        },

        pickLast7Days() {
            this.datePlaceholder = 'Last 7 Days';
            const now = new Date();
            this.startingDate = new Date(now.setDate(now.getDate() - 7)).toISOString();
            this.endingDate = new Date().toISOString();
            this.getAttendanceReport();
            this.$refs.datepicker.closeMenu();
        },

        pickLast30Days() {
            this.datePlaceholder = 'Last 30 Days';
            const now = new Date();
            this.startingDate = new Date(now.setDate(now.getDate() - 30)).toISOString();
            this.endingDate = new Date().toISOString();
            this.getAttendanceReport();
            this.$refs.datepicker.closeMenu();
        },

        async exportToExcel() {
            this.isExporting = true;
            this.$toast.info('Loading ...', {
                position: "top",
                duration: false,
            });

            const response = await this.getUserAttendanceReportData({
                startingDate: this.startingDate,
                endingDate: this.endingDate,
                limit: this.pageLimit,
                url: this.pageUrl,
            });
            this.exportedAttendanceReport = response.results.content || [];

            if (this.exportedAttendanceReport.length == 0) {
                this.isExporting = false;
                this.$toast.error('No Data Found', {
                    position: "top",
                });
                return
            }
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet([]);
            XLSX.utils.sheet_add_json(ws, [{
                header: {
                    v: 'FieldForce Attendance Report',
                    s: {
                        font: { bold: true, sz: 18 },
                        alignment: { horizontal: "center" },
                    }
                }
            }], { skipHeader: true, origin: 0 });

            ws['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 14 } }];

            const cellStyles = {
                font: { sz: 10 },
                alignment: { vertical: "center", horizontal: "center", wrapText: true },
                border: {
                    top: { style: 'thin', color: { rgb: '00000000' } },
                    bottom: { style: 'thin', color: { rgb: '00000000' } },
                    left: { style: 'thin', color: { rgb: '00000000' } },
                    right: { style: 'thin', color: { rgb: '00000000' } }
                },
            }
            const headerCellStyles = {
                ...cellStyles,
                fill: { fgColor: { rgb: "9bc2e6" } }
            };

            XLSX.utils.sheet_add_aoa(ws, [[
                { v: 'Sr. No.', s: headerCellStyles },
                { v: 'Employee ID', s: headerCellStyles },
                { v: 'Employee Name', s: headerCellStyles },
                { v: 'Emp Mobile No.', s: headerCellStyles },
                { v: 'Employee Department', s: headerCellStyles },
                { v: 'Employee Designation', s: headerCellStyles },
                { v: 'Date', s: headerCellStyles },
                { v: 'Status', s: headerCellStyles },
                { v: 'Punch-In Time', s: headerCellStyles },
                { v: 'Punch-In Address', s: headerCellStyles },
                { v: 'Punch-Out Time', s: headerCellStyles },
                { v: 'Punch-Out Address', s: headerCellStyles },
                { v: 'Distance From Punch In Location to Base Location', s: headerCellStyles },
                { v: 'Working Hours', s: headerCellStyles },
                { v: 'Remarks', s: headerCellStyles },
            ]], { origin: -1 });

            this.exportedAttendanceReport.forEach((item, index) => {
                const row = [
                    { v: (index + 1), s: cellStyles },
                    { v: item.employee_id, s: cellStyles },
                    { v: item.employee_name, s: cellStyles },
                    { v: item.employee_mobile_number, s: cellStyles },
                    { v: item.employee_department, s: cellStyles },
                    { v: item.employee_designation, s: cellStyles },
                    { v: item.date, s: cellStyles },
                    { v: item.status, s: cellStyles },
                    { v: item.punch_in_time, s: cellStyles },
                    { v: item.punch_in_address, s: cellStyles },
                    { v: item.punch_out_time, s: cellStyles },
                    { v: item.punch_out_address, s: cellStyles },
                    { v: item.distance_from_base, s: cellStyles },
                    { v: item.working_hours, s: cellStyles },
                    { v: '', s: cellStyles },
                ];

                XLSX.utils.sheet_add_aoa(ws, [row], { origin: -1 });
            });

            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, 'attendance-report.xlsx', { bookType: 'xlsx', type: 'binary' });
            this.isExporting = false;
            this.$toast.clear();
            this.$toast.success('Successfully Exported', {
                position: "top",
            });
        },

        
        toggleDropDown() {
            let toggleDropdown = document.getElementById("dropdownSearch");
            toggleDropdown.classList.toggle("hidden");
        },

        resetFilter() {
            this.attendanceReport = [];
            this.pagination.next = '';
            this.pagination.prev = '';
            this.totalCount = 0;
            this.resetReportFilter();
            const dom = document.getElementById('dropdownSearch');
            const domElem = dom.getElementsByClassName('form-check-input');
            for (let i = 0; i < domElem.length; i++) {
                domElem[i].checked = false;
            }
            this.toggleDropDown();
        },

    },

};
</script>
  