
<template>
  <div class="">
    <div class="h-[100vh] w-[100px] absolute hover:w-[200px]">
      <SidenavteamMenu />
    </div>

    <HeaderNavbar title="Geofences Assignment" />

    <div class="ml-sidebar">
      <div v-if="loading">
        Loading...
      </div>
      <div v-else>
          <div class="mx-4 my-3">
            <form @submit.prevent="submit" class="form-layout my-3 text-start">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                      <label class="text-start">Start Time</label>
                      <input type="datetime-local" class="form-control"
                          v-model="form.start_time" required />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                      <label class="text-start">End Time</label>
                      <input type="datetime-local" class="form-control"
                          v-model="form.end_time" required />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row text-xs">
                    <div class="col-4">
                      <div class="input-group">
                        <label class="text-start"> Search</label>
                        <div class="input-group">
                          <input v-model="searchQuery" type="text" class="form-control" placeholder="Type Employee Name"
                            aria-label="Username" aria-describedby="basic-addon1" />
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <label class="text-start"> Department</label>
                      <select class="form-control" v-model="selectedDepartment">
                        <option value="">All</option>
                        <option :value="allDep.id" v-for="allDep in allDeartments" :key="allDep.id">
                          {{ allDep.name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-4">
                      <label class="text-start"> Designation</label>
                      <select class="form-control" v-model="selectedDesignation">
                        <option value="">All</option>
                        <option :value="allDes.id" v-for="allDes in allDesignation" :key="allDes.id">
                          {{ allDes.title }}
                        </option>
                      </select>
                    </div>

                    <div class="col-12">
                      <div class="h-[300px] overflow-scroll">
                        <table class="table text-xs">
                          <thead>
                            <tr class="text-gray-500">
                              <th scope="col">
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" v-model="allEmpTaskAssign"
                                    @change="checkedAllTaskAssignTo" />
                                </div>
                              </th>
                              <th scope="col">Employee name</th>
                              <th scope="col">Contact No.</th>
                              <th scope="col">Email Address</th>
                              <th scope="col">Department</th>
                              <th scope="col">Designation</th>
                            </tr>
                          </thead>
                          <tbody>
                            <template v-for="emp in filteredEmployees" :key="emp.id">
                              <tr @click="toggleEmployeeCheck(emp)" class="cursor-pointer">
                                <td>
                                  <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" :id="emp.id" v-model="emp.checked"
                                      @change="updateAssignedUser" />
                                  </div>
                                </td>
                                <td>{{ emp.first_name }} {{ emp.last_name }}</td>
                                <td>{{ emp.phone }}</td>
                                <td>{{ emp.email }}</td>
                                <td>{{ emp.user_department?.name }}</td>
                                <td>{{ emp.user_designation?.title }}</td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                      <div class="col-12">
                        <div class="h-[300px] overflow-scroll">
                          <table class="table text-xs">
                            <thead>
                              <tr class="text-gray-500">
                                <th scope="col">
                                  #
                                </th>
                                <th scope="col">Name</th>
                                <th scope="col">Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              <template v-for="item in geofences" :key="item.id">
                                <tr @click="toggleGeofenchCheck(item)" class="cursor-pointer">
                                  <td>
                                    <div class="custom-control custom-checkbox">
                                      <input type="checkbox" class="custom-control-input" :id="item.id" v-model="item.checked"
                                        @change="updateAssignedGeofench" />
                                    </div>
                                  </td>
                                  <td>{{ item.name || '' }}</td>
                                  <td>{{ item.description || '' }}</td>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
              <div class="text-end">
                <button type="submit" class="btn btn-outline-primary" :disabled="isSaving" @click="submit">
                  {{ isSaving ? "Saving..." : "Save changes" }}
                </button>
              </div>
            </form>
          </div>
      </div>
    </div>
  </div>
</template>


<script>
import SidenavteamMenu from "@/components/menu/SideNavbarTeam.vue";
import HeaderNavbar from "@/components/menu/headerdashboard.vue";
import CompanyApi from "@/server/api/company";
import api from "@/boot/axios.js";
import apis from "@/server/api/company/index";
import Multiselect from "@vueform/multiselect";

export default {
  data() {
    return {
      companyApi: new CompanyApi(),
      geofences: [],
      geofences2: [],
      employeeList: [],
      employeeList02: [],
      idAndNameList: [],
      allDeartments: [],
      allDesignation: [],
      selectedDepartment: null,
      selectedDesignation: null,
      query: '',
      searchQuery: "",
      form: {
        employees: [],
        geofences: [],
        start_time: null,
        end_time: null,
      },
      assignToInput: "",
      isSaving: false,
      allEmpTaskAssign: false,
    };
  },

  components: { SidenavteamMenu, HeaderNavbar, Multiselect},
  
  created() {
    this.getGeofences();
    this.userList();
    this.getDepartments();
    this.getDesignation();
  },

  watch: {
    assignToInput(newValue) {
      this.form.employees = newValue
        .split(",")
        .map((item) => Number(item.trim()));
    },
  },
  computed: {
    filteredEmployees() {
      return this.employeeList02.filter((emp) => {
        const name = `${emp.first_name} ${emp.last_name}`.toLowerCase();
        const phone = emp.phone.toLowerCase();
        const email = emp.email.toLowerCase();
        const searchQuery = this.searchQuery.toLowerCase();
        const departmentFilter = this.selectedDepartment
          ? emp?.user_department?.id === this.selectedDepartment
          : true;
        const designationFilter = this.selectedDesignation
          ? emp?.user_designation?.id === this.selectedDesignation
          : true;

        return (
          (name.includes(searchQuery) ||
            phone.includes(searchQuery) ||
            email.includes(searchQuery)) &&
          departmentFilter &&
          designationFilter
        );
      });
    },
  },
  methods: {
    async userList() {
      this.companyApi.getUserListByCompany().then((res) => {
        this.employeeList02 = res;
        this.employeeList = res;
        this.idAndNameList = this.employeeList.map(
          ({ id, first_name, last_name }) => ({
            id,
            first_name,
            last_name,
          })
        );
        console.log(this.idAndNameList, "chekcing name and name");
      });
    },

    async getGeofences() {
      const response = await apis.getGeofences();
      this.geofences = response;
      this.geofences2 = response;
    },

    updateAssignedUser() {
      this.form.employees = this.employeeList02
        .filter((emp) => emp.checked)
        .map((emp) => emp.id);
    },

    updateAssignedGeofench() {
      this.form.geofences = this.geofences2
        .filter((item) => item.checked)
        .map((item) => item.id);
    },

    checkedAllTaskAssignTo() {
      console.log(this.allEmpTaskAssign)
      if (this.allEmpTaskAssign) {
        this.form.employees = this.employeeList02
          .map((emp) => {
            this.toggleEmployeeCheck(emp)
            return emp.id
          });
      } else {
        this.employeeList02
          .map((emp) => {
            this.toggleEmployeeCheck(emp)
            return emp.id
          });
        this.form.employees = [];
      }
    },

    toggleEmployeeCheck(emp) {
      emp.checked = !emp.checked;
      this.updateAssignedUser();
    },

    toggleGeofenchCheck(item) {
      item.checked = !item.checked;
      this.updateAssignedGeofench();
    },

    async getDepartments() {
      await api.get("api/company/v1/department/").then((res) => {
        this.allDeartments = res.data;
        console.log(res.data, "checking the department list");
      });
    },

    async getDesignation() {
      await api.get("api/company/v1/designation/").then((res) => {
        this.allDesignation = res.data;
        console.log(res.data, "checking the designation list");
      });
    },

    async getAssignEmp() {
      await this.getTask();
      this.assignedTaskList = this.assignedTaskList.filter((item) => {
        return item.employee_id == this.query ||
          item.first_name.toLowerCase().includes(this.query) ||
          item.last_name.toLowerCase().includes(this.query) ||
          item.phone.includes(this.query);
      });
    },

    async submit() {
      this.isSaving = true;
      const response = await apis.postGeofenceAssignment(this.form);
      if (response.status || false) {
          this.$toast.success(`Assigned successfully`, {
              position: "top",
          });
          this.$router.push({name: 'company-geo-fence-assignments'});
      } else {
          if (response.message.length || false) {
              this.$toast.error(`${response.message}`, {
                  position: "top",
              });
          } else {
              this.$toast.error(`Something went wrong! please refresh.`, {
                  position: "top",
              });
          }
      }
      this.isSaving = false;
      this.form = {
        employees: [],
        geofences: [],
        start_time: null,
        end_time: null,
      }
    },
  },
};
</script>

<style scoped>
.personDetailsButton {
  border-radius: 5px;
  color: rgba(106, 106, 106, 0.346);
  border: solid rgba(106, 106, 106, 0.346) 1px;
  margin-top: 20px;
  font-size: 12px;
  padding: 10px 20px;
}

.popupContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.242);
}
</style>
