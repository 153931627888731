import POIsView from "@/views/company/poi/Index.vue";
import POIFormView from "@/views/company/poi/Form.vue";

export default [
    {
        path: '/poi',
        name: 'company-poi',
        meta: {
            key: 'poi',
            title: 'POI',
            auth: true,
        },
        component: POIsView
    },
    {
        path: '/poi/create',
        name: 'company-poi-create',
        meta: {
            key: 'poi',
            title: 'Create POI',
            auth: true,
        },
        component: POIFormView
    },
    {
        path: '/poi/edit/:id',
        name: 'company-poi-edit',
        meta: {
            key: 'poi',
            title: 'Edit POI',
            auth: true,
        },
        component: POIFormView
    },
];