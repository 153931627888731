import api from "@/boot/axios";

export default {
    getHolidays: async () => {
        const response = await api
            .get(`api/super-admin/v1/holiday/`)
            .then((res) => {
                return res.data;
            });

        return response;
    },

    storeHoliday: async (data) => {
        try {
            await api.post(`api/super-admin/v1/holiday/`, data)
            return { status: true };
        } catch (err) {
            let msg = '';
            for (let e in err.response.data) {
                msg += err.response.data[e].join(' ');
            }
            return { status: false, message: msg }
        }
    },
}