<template>
  <div>
    <div class="flex">
      <input type="text" placeholder="search..." class=" w-56 rounded-lg mr-2 bg-gray-100 placeholder:pl-3"  />
      <label for="">
        <button
          class="w-10 h-10 bg-primary flex items-center justify-center rounded-lg"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18.4062 16.6237L25.1312 23.3487C25.3676 23.5852 25.5003 23.906 25.5002 24.2404C25.5001 24.5748 25.3671 24.8954 25.1306 25.1318C24.894 25.3682 24.5733 25.5009 24.2389 25.5008C23.9045 25.5007 23.5838 25.3677 23.3475 25.1312L16.6225 18.4062C14.6121 19.9633 12.0841 20.696 9.55261 20.4554C7.02117 20.2147 4.67649 19.0187 2.99556 17.1107C1.31462 15.2027 0.423692 12.7259 0.504019 10.1844C0.584346 7.64277 1.62989 5.22725 3.42796 3.42918C5.22603 1.63111 7.64155 0.585566 10.1831 0.50524C12.7247 0.424913 15.2014 1.31584 17.1095 2.99678C19.0175 4.67771 20.2135 7.02239 20.4541 9.55383C20.6948 12.0853 19.9621 14.6133 18.405 16.6237H18.4062ZM10.5 17.9999C12.4891 17.9999 14.3967 17.2097 15.8033 15.8032C17.2098 14.3967 18 12.489 18 10.4999C18 8.5108 17.2098 6.60315 15.8033 5.19662C14.3967 3.7901 12.4891 2.99992 10.5 2.99992C8.51083 2.99992 6.60318 3.7901 5.19665 5.19662C3.79013 6.60315 2.99995 8.5108 2.99995 10.4999C2.99995 12.489 3.79013 14.3967 5.19665 15.8032C6.60318 17.2097 8.51083 17.9999 10.5 17.9999Z"
              fill="white"
            />
          </svg>
        </button>
      </label>
    </div>
  </div>
</template>

<script>
</script>