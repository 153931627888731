import apis from "@/server/api/company/index";
import CompanyApi from "@/server/api/company";

export default {
    namespaced: true,

    state: () => ({
        selectedUsers: [],
        usersByDepartment: {},
        usersByDesignation: [],
        users: [],
        departments: [],
        designations: [],
    }),

    actions: {
        initReportState: async ({ commit }) => {
            const companyApi = new CompanyApi();
            const users = await companyApi.getUserListByCompany();
            commit("SET_USERS", users);

            const departments = await apis.getDepartments();
            commit("SET_DEPARTMENTS", departments);

            const designations = await apis.getDesignations();
            commit("SET_DESIGNATIONS", designations);
        },

        handleUserSelect: async ({ commit }, event) => {
            if (event.target.value == '') {
                commit("RESET_FILTER");
                return;
            }
            if (event.target.checked) {
                commit("PUSH_TO_SELECTED_USER", [event.target.value]);
            } else {
                commit("REMOVE_FROM_SELECTED_USER", event.target.value);
            }
        },

        getUserLocationReportData: async ({ state }, data) => {
            const userIds = state.selectedUsers.join(',');
            // if (userIds == '') return new Promise(() => []);
            const companyApi = new CompanyApi();
            return companyApi.getDataForExcel(userIds, data?.startingDate, data?.endingDate, data?.pageUrl, data?.pageLimit)
        },

        getUserAttendanceReportData: async ({ state }, data) => {
            const userIds = state.selectedUsers.join(',');
            // if (userIds == '') return new Promise(() => []);
            return apis.getAttendanceReport({
                userid: userIds,
                startingDate: data?.startingDate,
                endingDate: data?.endingDate,
                limit: data?.limit,
                url: data?.url,
            });
        },

        getGeofenchViolationReportData: async (_, data) => {
            return apis.getGeofenchViolationReport({
                userid: data?.userid,
                startingDate: data?.startingDate,
                endingDate: data?.endingDate,
                limit: data?.limit,
                url: data?.url,
                search: data?.search,
            });
        },

        getErrorLogReportData: async (_, data) => {
            return apis.getErrorLogReport({
                userid: data?.userid,
                startingDate: data?.startingDate,
                endingDate: data?.endingDate,
                limit: data?.limit,
                url: data?.url,
                search: data?.search,
            });
        },

        getDeviceLogReportData: async (_, data) => {
            return apis.getDeviceLogReport({
                userid: data?.userid,
                startingDate: data?.startingDate,
                endingDate: data?.endingDate,
                limit: data?.limit,
                url: data?.url,
                search: data?.search,
            });
        },

        getPunchInOutLogReportData: async (_, data) => {
            return apis.getPunchInOutLogReport({
                userid: data?.userid,
                startingDate: data?.startingDate,
                endingDate: data?.endingDate,
                limit: data?.limit,
                url: data?.url,
                search: data?.search,
            });
        },

        handleDepartmentSelect: async ({ commit }, event) => {
            const companyApi = new CompanyApi();
            const users = await companyApi.getUserByProps({ department: event.target.value });
            const userIds = users.map(item => item.id);
            if (event.target.checked) {
                commit("PUSH_TO_SELECTED_USER", userIds);
                commit("SET_USERS_BY_DEPARTMENT", { key: event.target.value, value: userIds, })
            } else {
                userIds.map(item => commit("REMOVE_FROM_SELECTED_USER", item));
                commit("SET_USERS_BY_DEPARTMENT", { key: event.target.value, value: [], })
            }
        },

        handleDesignationSelect: async ({ commit }, event) => {
            const companyApi = new CompanyApi();
            const users = await companyApi.getUserByProps({ designation: event.target.value });
            const userIds = users.map(item => item.id);
            if (event.target.checked) {
                commit("SET_USERS_BY_DESIGNATION", { key: event.target.value, value: userIds, })
                commit("MAP_USER_FOR_DESIGNATION");
            } else {
                userIds.map(item => commit("REMOVE_FROM_SELECTED_USER", item));
            }
        },

        resetReportFilter: async ({ commit }) => {
            commit("RESET_FILTER");
        },

        handleDepartmentFilter: async ({ commit }, event) => {
            const departments = await apis.getDepartments();
            const temp = departments.filter((item) => item.name.toLowerCase().includes(event.target.value));
            commit("SET_DEPARTMENTS", temp);
        },

        handleDesignationFilter: async ({ commit }, event) => {
            const designations = await apis.getDesignations();
            const temp = designations.filter((item) => item.title.toLowerCase().includes(event.target.value));
            commit("SET_DESIGNATIONS", temp);
        },

        handleUserFilter: async ({ commit }, event) => {
            const companyApi = new CompanyApi();
            const users = await companyApi.getUserListByCompany();
            const temp = users.filter((item) => item.first_name.toLowerCase().includes(event.target.value));
            commit("SET_USERS", temp);
        },
    },

    getters: {
        isFilterByDepartment: (state) => {
            let totalDepartmentUsers = [];
            (Object.values(state.usersByDepartment) || []).forEach((item) => {
                totalDepartmentUsers = [
                    ...totalDepartmentUsers,
                    ...item
                ]
            });
            let totalDesignationUsers = [];
            (Object.values(state.usersByDesignation) || []).forEach((item) => {
                totalDesignationUsers = [
                    ...totalDesignationUsers,
                    ...item
                ]
            });
            return totalDepartmentUsers.length > 0 || totalDesignationUsers.length > 0;
        },
    },

    mutations: {
        SET_USERS: (state, value) => {
            state.users = value;
        },

        SET_DEPARTMENTS: (state, value) => {
            state.departments = value
        },

        SET_DESIGNATIONS: (state, value) => {
            state.designations = value
        },

        PUSH_TO_SELECTED_USER: (state, value) => {
            state.selectedUsers = [
                ...state.selectedUsers,
                ...value,
            ];
        },

        REMOVE_FROM_SELECTED_USER: (state, value) => {
            state.selectedUsers = state.selectedUsers.filter(item => item !== value);
        },

        RESET_FILTER: (state) => {
            state.selectedUsers = [];
            state.usersByDepartment = [];
            state.usersByDesignation = [];
        },

        SET_USERS_BY_DEPARTMENT: (state, data) => {
            state.usersByDepartment[data.key] = data.value;
        },

        SET_USERS_BY_DESIGNATION: (state, data) => {
            state.usersByDesignation[data.key] = data.value;
        },

        MAP_USER_FOR_DESIGNATION: (state) => {
            // report will filter from department and designation nested way, 
            // selected department users and selected designation users combined
            let totalDepartmentUsers = [];
            (Object.values(state.usersByDepartment) || []).forEach((item) => {
                totalDepartmentUsers = [
                    ...totalDepartmentUsers,
                    ...item
                ]
            });

            let totalDesignationUsers = [];
            (Object.values(state.usersByDesignation) || []).forEach((item) => {
                totalDesignationUsers = [
                    ...totalDesignationUsers,
                    ...item
                ];
            });

            if (totalDepartmentUsers.length > 0) {
                state.selectedUsers = totalDesignationUsers.filter(item => totalDepartmentUsers.includes(item));
            } else {
                state.selectedUsers = totalDesignationUsers;
            }

        },
    },
}