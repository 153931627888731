<template>
    <div>
        <div class="h-[100vh] w-[100px] absolute hover:w-[200px]">
            <SidenavteamMenu />
        </div>

        <HeaderNavbar title="Shift & Workdays" />

        <div class="ml-sidebar">
            <div class="h-screen mx-3">
                <ul class="nav nav-tabs mt-3" id="attendanceTabInfo" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="shift-tab" data-bs-toggle="tab" data-bs-target="#shift"
                            type="button" role="tab" aria-controls="shift" aria-selected="true">Shifts</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="workday-tab" data-bs-toggle="tab" data-bs-target="#workday"
                            type="button" role="tab" aria-controls="workday" aria-selected="false">Workdays</button>
                    </li>
                </ul>
                <div class="tab-content" id="attendanceTabInfoContent">
                    <div class="tab-pane fade show active" id="shift" role="tabpanel" aria-labelledby="shift-tab">
                        <ShiftIndex />
                    </div>
                    <div class="tab-pane fade" id="workday" role="tabpanel" aria-labelledby="workday-tab">
                        <WorkdayIndex />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import SidenavteamMenu from "@/components/menu/SideNavbarTeam.vue";
import HeaderNavbar from "@/components/menu/headerdashboard.vue";
import ShiftIndex from "./shift/Index.vue";
import WorkdayIndex from "./workday/Index.vue";

export default {
    components: { SidenavteamMenu, HeaderNavbar, ShiftIndex, WorkdayIndex },
};
</script>
  