<template>
  <div class="">
    <div class="h-[100vh] w-[100px] absolute hover:w-[200px]">
      <SidenavteamMenu />
    </div>
    <HeaderNavbar title="Monitor" />

    <!-- Right sidebar -->
    <div id="mySidebar" class="sidebarRight">
      <div class="containerCard">
        <div class="w-[300px] bg-white shadow-2xl overflow-hidden">
          <!-- search box for employee search--list -->
          <div class="px-2 py-2 fixed bg-gray-100 w-[100%]" style="z-index: 999999;">
            <input class="inputCard w-full p-2" type="number" v-model="searchQuery"
              placeholder="Enter employee contact number..." />
          </div>
          <!-- end of the search box employee search -- list -->

          <!-- Under the Company all the listed employee list -->
          <div class="employeeContainerCard overflow-scroll px-2 mt-2">
            <div v-for="emp in filteredEmployees" :key="emp.id" @click="flyToLocation(emp)" class="mt-2">
              <div class="card text-xs my-2 cursor-pointer shadow-md">
                <div class="card-body">
                  <div class="d-flex mb-2">
                    <img :src="`${emp.avatar}`" class="h-12 w-12 rounded-md" alt="" />
                    <div class="text-start ml-2">
                      <p class="fw-bold">{{ emp.first_name }} {{ emp.last_name }}</p>
                      <p>{{ emp.mobile }}</p>
                      <p>{{ (emp.department?.length || 0) > 0 ? `Dept: ${emp.department || ''}` : '' }}</p>
                      <p>{{ (emp.designation?.length || 0) > 0 ? `Deg: ${emp.designation || ''}` : '' }}</p>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between" style="font-size: 10px;">
                    <div class="font-bold">Last Location: </div>
                    <div>
                      {{ formateDateTimeString(emp.last_location?.updated_at) }}
                    </div>
                  </div>
                  <p style="font-size: 10px;">
                    {{ getPoiString(emp?.last_location?.poi) }}
                  </p>
                  <div class="d-flex justify-content-between mt-2">
                    <button class="font-xxs bg-black rounded-md px-2 text-white">
                      {{ emp?.last_location?.distance }} M
                    </button>
                    <button class="font-xxs bg-blue-400 rounded-md px-2 text-white">
                      {{ emp?.last_location?.lat }}
                    </button>
                    <button class="font-xxs bg-green-500 rounded-md px-2 text-white">
                      {{ emp?.last_location?.long }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End of Under the Company all the listed employee list -->
        </div>
      </div>
    </div>
    <!-- End of the right Sidebar -->

    <!-- Moving button for sidebar to open and close -->
    <div id="main" class="openbtn" @click="openNav()">
      <svg width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_0_1825)">
          <path
            d="M23 12L15.929 19.071L14.515 17.657L20.172 12L14.515 6.34296L15.929 4.92896L23 12ZM3.828 12L9.485 17.657L8.071 19.071L1 12L8.071 4.92896L9.485 6.34296L3.828 12Z"
            fill="black" />
        </g>
        <defs>
          <clipPath id="clip0_0_1825">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
    <!-- End of the moving butotn for sidebar to open and close -->

    <div class="ml-sidebar">
      <div class="monitor-view">
        <div id="mapContainer">
          <div class="map-full-screen">
            <button class="btn" @click="handleFullScreenMap">
              <i class="fas fa-expand"></i>
            </button>
          </div>
        </div>

        <!-- Dashboard of the total user , Active user, Inactive user -->

        <div id="topBar" class="topBarEmployeeStatus">
          <div class="topBarGrid">
            <div class="grid-item text-start bg-white">
              <div class="text-[13px] font-extrabold">Total User</div>
              <div>{{ totalUser[0]?.total_user }}</div>
              <div class="tagHightlighter">
                <hr />
              </div>
            </div>
            <div class="grid-item text-start bg-white">
              <div class="text-[13px] font-extrabold">Active User</div>
              <div>{{ totalUser[0]?.total_active_user }}</div>
              <div class="tagHightlighter">
                <hr />
              </div>
            </div>
            <div class="grid-item text-start bg-white">
              <div class="text-[13px] font-extrabold">
                Inactive User
                <span class="text-xs dark:text-gray-500">(>10min)</span>
              </div>
              <div>{{ totalUser[0]?.total_inactive_user }}</div>
              <div class="tagHightlighter">
                <hr />
              </div>
            </div>
          </div>
        </div>

        <!-- End of the Dashboard of the total user, Active user, Inactice user -->
      </div>
    </div>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import AuthApi from "@/server/auth/auth";

/* import custom components */
import SidenavteamMenu from "@/components/menu/SideNavbarTeam.vue";
import headermonitor from "@/components/menu/headermonitor.vue";
import SideNavBar from "@/components/menu/SideNavbar.vue";
import StatusBar from "@/components/ui/StatusBar.vue";
import PageTransactBtn from "@/components/ui/PageTransactBtn.vue";
import Searchbar from "@/components/ui/Searchbar.vue";
import EmployeeShortInfo from "@/components/ui/EmployeeShortInfo.vue";
import Dropdown from "@/components/menu/Dropdown.vue";

import HeaderNavbar from "@/components/menu/headerdashboard.vue";
// import "../public/Tween.js";
// import "../public/leaflet.curve.js";
import CompanyApi from "@/server/api/company";

export default {
  name: "LeafletMap",
  data() {
    return {
      employees: [],
      searchQuery: "",
      totalUser: [],
      user: null,
      intervalId: null,
    };
  },
  components: {
    headermonitor,
    SidenavteamMenu,
    SideNavBar,
    StatusBar,
    Searchbar,
    EmployeeShortInfo,
    PageTransactBtn,
    Dropdown,
    HeaderNavbar,
  },

  mounted() {
    this.user = {
      name: localStorage.getItem("fullname"),
      role: localStorage.getItem("role"),
      avatar: localStorage.getItem("avatar"),
    };
    this.initializedMap();
    this.getUserLocationByCompany();
    this.getCompanyUser();
    this.refreshData();
  },
  computed: {
    filteredEmployees() {
      // Filter employees based on the search query
      return this.employees.filter((emp) => {
        // const fullName = `${emp.first_name} ${emp.last_name}`.toLowerCase();
        const mobile = `${emp.mobile}`;
        return mobile.includes(this.searchQuery);
      });
    },
  },

  unmounted() {
    clearInterval(this.intervalId);
  },

  methods: {
    initializedMap() {
      if(this.map == null || this.map == undefined) {
        this.map = L.map("mapContainer").setView([23.8187676, 90.4314902], 12);
        L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
          attribution:
            '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          minZoom: 0,
          maxZoom: 22,
          maxNativeZoom: 18,
        }).addTo(this.map);
      }
     
      this.map.on("click", (_) => {
        this.resetMarkerStyles();
      });
    },

    refreshData() {
      this.intervalId = setInterval(() => {
        this.initializedMap();
        this.getUserLocationByCompany();
        this.getCompanyUser();
      }, 100000);
    },

    async getUserLocationByCompany() {
      const companyApi = new CompanyApi();
      const response = await companyApi.getLocationByCompany();
      console.log(response);
      this.employees = response;
      this.createMarkers();
    },

    async getCompanyUser() {
      const companyApi = new CompanyApi();
      const response = await companyApi.getCompanyTotalUserStatus();
      console.log(response, "total user active inactive");
      this.totalUser = response;
      console.log(this.totalUser, "total user active inactive");
    },

    createMarkers() {
      this.employees.forEach((emp) => {
        const { lat, long } = emp.last_location;

        const diffMins = getMinutesDifference(new Date(emp.last_location.updated_at), new Date());

        const defaultIcon = L.divIcon({
          html: `
          <div>

            <h1 class="w-[57px] top-[27px] right-[10px]  pl-2 pr-2 text-white rounded-lg relative opacity-75" style="background: ${diffMins > 10 ? '#ff0000' : '#2EA5EA'};">${emp.first_name}</h1>

            <img style="
              width: 31px;
              height: 31px;
              border-radius: 50%;
              position: relative;
              top: 33px;
              left: 2px;
              "src="${emp.avatar}" >
            <svg  width="35" height="50" viewBox="0 0 35 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.5 0C7.825 0 0 7.825 0 17.5C0 30.625 17.5 50 17.5 50C17.5 50 35 30.625 35 17.5C35 7.825 27.175 0 17.5 0Z" fill="${diffMins > 10 ? '#ff0000' : '#2EA5EA'}"/>
            </svg>
          </div>
        `,
          className: "",
          iconSize: [24, 40],
          iconAnchor: [12, 40],
        });

        const marker = L.marker([lat, long], { icon: defaultIcon }).addTo(
          this.map
        );

        const customPopup = `<div class="leafLetCard">
            <div class="justName mb-1">
              <i class="fas fa-user mr-2 text-app-primary"></i>${emp.first_name} ${emp.last_name} - Emp Id: ${emp.id}
            </div>
            <div class="justName">
              <i class="fas fa-phone-alt mr-2 text-app-primary"></i>${emp.mobile}
            </div>
            <div class="mapLocationName">
              <div class="svgIconClass">
              <svg width="15" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 23.728L5.636 17.364C4.37734 16.1053 3.52018 14.5017 3.17292 12.7558C2.82567 11.01 3.0039 9.20041 3.68509 7.55588C4.36629 5.91136 5.51984 4.50575 6.99988 3.51683C8.47992 2.5279 10.22 2.00006 12 2.00006C13.78 2.00006 15.5201 2.5279 17.0001 3.51683C18.4802 4.50575 19.6337 5.91136 20.3149 7.55588C20.9961 9.20041 21.1743 11.01 20.8271 12.7558C20.4798 14.5017 19.6227 16.1053 18.364 17.364L12 23.728ZM16.95 15.95C17.9289 14.971 18.5955 13.7237 18.8655 12.3659C19.1356 11.008 18.9969 9.60058 18.4671 8.32154C17.9373 7.0425 17.04 5.94929 15.8889 5.18015C14.7378 4.41101 13.3844 4.00049 12 4.00049C10.6156 4.00049 9.26221 4.41101 8.11108 5.18015C6.95995 5.94929 6.06275 7.0425 5.53291 8.32154C5.00308 9.60058 4.86441 11.008 5.13445 12.3659C5.40449 13.7237 6.0711 14.971 7.05 15.95L12 20.9L16.95 15.95ZM12 13C11.4696 13 10.9609 12.7893 10.5858 12.4142C10.2107 12.0391 10 11.5304 10 11C10 10.4696 10.2107 9.96084 10.5858 9.58577C10.9609 9.2107 11.4696 8.99998 12 8.99998C12.5304 8.99998 13.0391 9.2107 13.4142 9.58577C13.7893 9.96084 14 10.4696 14 11C14 11.5304 13.7893 12.0391 13.4142 12.4142C13.0391 12.7893 12.5304 13 12 13Z" fill="#117abf"/>
              </svg>
              </div>
            <div>
              ${this.getPoiString(emp?.last_location?.poi)}
              <div>${emp?.last_location?.lat || ''}, ${emp?.last_location?.long || ''}</div>
            </div>
          </div>

          <div class="mapLocationMonitorPageTime">
            <div class="svgIconClass">
            <svg width="15" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_0_7218)">
            <path d="M17.618 5.968L19.071 4.515L20.485 5.929L19.032 7.382C20.4678 9.17917 21.1609 11.4579 20.9691 13.7501C20.7772 16.0424 19.715 18.1742 18.0005 19.7077C16.286 21.2412 14.0494 22.0601 11.75 21.9961C9.4506 21.9321 7.26301 20.9901 5.63647 19.3635C4.00993 17.737 3.06793 15.5494 3.00392 13.25C2.93991 10.9506 3.75875 8.71402 5.29228 6.99951C6.82581 5.285 8.95761 4.22275 11.2499 4.03092C13.5421 3.83909 15.8208 4.53223 17.618 5.968ZM12 20C12.9193 20 13.8295 19.8189 14.6788 19.4672C15.5281 19.1154 16.2997 18.5998 16.9497 17.9497C17.5998 17.2997 18.1154 16.5281 18.4672 15.6788C18.8189 14.8295 19 13.9193 19 13C19 12.0807 18.8189 11.1705 18.4672 10.3212C18.1154 9.47194 17.5998 8.70026 16.9497 8.05025C16.2997 7.40024 15.5281 6.88463 14.6788 6.53284C13.8295 6.18106 12.9193 6 12 6C10.1435 6 8.36301 6.7375 7.05025 8.05025C5.7375 9.36301 5 11.1435 5 13C5 14.8565 5.7375 16.637 7.05025 17.9497C8.36301 19.2625 10.1435 20 12 20ZM11 8H13V14H11V8ZM8 1H16V3H8V1Z" fill="#117abf"/>
            </g>
            <defs>
            <clipPath id="clip0_0_7218">
            <rect width="24" height="24" fill="white"/>
            </clipPath>
            </defs>
            </svg>
            </div>
            <div>
              ${this.formateDateTimeString(emp.last_location?.updated_at)}
            </div>
          </div>
          <div class="distanceFromPOILeafletCard">
              <div class="svgIconClass">
                <svg width="15" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 18H9.5V19.25C9.5 19.9793 9.21027 20.6788 8.69454 21.1945C8.17882 21.7102 7.47935 22 6.75 22C6.02065 22 5.32118 21.7102 4.80546 21.1945C4.28973 20.6788 4 19.9793 4 19.25V18ZM8 6.11996C10 6.11996 11 8.99996 11 11C11 12 10.5 13 10 14.5L9.5 16H4C4 15 3.5 13.5 3.5 11C3.5 8.49996 5.498 6.11996 8 6.11996ZM20.054 14.098L19.837 15.329C19.7104 16.0473 19.3035 16.6859 18.7061 17.1043C18.1086 17.5227 17.3693 17.6866 16.651 17.56C15.9327 17.4333 15.2941 17.0265 14.8757 16.429C14.4573 15.8315 14.2934 15.0923 14.42 14.374L14.638 13.144L20.054 14.098ZM18.178 1.70496C20.642 2.13896 22.196 4.82896 21.762 7.29096C21.328 9.75396 20.575 11.144 20.402 12.129L14.985 11.174L14.753 9.60996C14.521 8.04596 14.203 6.97396 14.376 5.98996C14.723 4.01996 16.208 1.35796 18.178 1.70496Z" fill="#117abf"/>
                </svg>
              </div>
            <div>
              Distance From POI: <span class="leafletMonitortDistance">${emp.last_location?.distance
          }M </span><br>
            </div>
            </div><hr>
            <button onclick="redirectToSingleUserMonitor(${emp.id
          });">Last 20 location</button>
        </div>`;

        marker.bindPopup(customPopup);

        emp.marker = marker;
        emp.defaultIcon = defaultIcon;

        marker.on("click", () => {
          this.highlightMarker(emp);
        });
      });
    },

    highlightMarker(emp) {
      const marker = emp.marker;
      const defaultIcon = emp.defaultIcon;
      // Reset the styles for all markers
      this.resetMarkerStyles();
      // Highlight the clicked marker
      marker.setIcon(
        L.divIcon({
          html: `<div class="map-marker">
            <div class='zIndexForMonitor'>
              <h1 class="w-[60px] top-[27px] right-[10px]  pl-2 pr-2 bg-red-900 text-white font-bold rounded-lg relative ">${emp.first_name}</h1>
              <img class="map-marker-img" src="${emp.avatar}" >
              <svg width="45" height="50"  viewBox="0 0 35 50" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.5 0C7.825 0 0 7.825 0 17.5C0 30.625 17.5 50 17.5 50C17.5 50 35 30.625 35 17.5C35 7.825 27.175 0 17.5 0Z" fill="#85F56B"/>
              </svg>
              <div class="map-marker-blink"><div>
            </div>
          </div>`,
          className: "",
          iconSize: [24, 40],
          iconAnchor: [12, 40],
        })
      );

      marker.setZIndexOffset(99999)
    },

    resetMarkerStyles() {
      this.employees.forEach((emp) => {
        const marker = emp.marker;
        const defaultIcon = emp.defaultIcon;

        marker.setIcon(defaultIcon);
        marker.getElement().style.zIndex = ""; // Reset the z-index
      });
    },

    async flyToLocation(emp) {
      const { lat, long } = emp.last_location;
      const marker = emp.marker;

      await this.map.flyTo([lat, long], 20);

      // Highlight the clicked marker
      this.highlightMarker(emp);

    },

    // Right sidebar Fucntion
    openNav() {
      const sidebar = document.getElementById("mySidebar");
      const main = document.getElementById("main");
      const topBar = document.getElementById("topBar");

      if (sidebar.style.width === "300px") {
        // If the sidebar is already open, close it
        sidebar.style.width = "0";
        main.style.marginRight = "0";
        topBar.style.marginTop = "-100px";
      } else {
        // If the sidebar is closed, open it
        sidebar.style.width = "300px";
        main.style.marginRight = "300px";
        topBar.style.marginTop = "20px";
      }
    },
    // End of the Right sidebar Fucntion
  },
};
</script>

<style scoped>
body {
  font-family: "Lato", sans-serif;
}

/* Right sidebar css */

.sidebarRight {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 5;
  top: 0;
  right: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
}

.sidebarRight .contentOfSidebar {
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidebarRight a:hover {
  color: #f1f1f1;
}

.sidebarRight .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #0d6efd47;
  color: white;
  padding: 15px 5px;
  border: none;
  border-radius: 10px 0 10px 0;
  box-shadow: -4px 0px 10px 6px;
}

#main {
  transition: margin-right 0.5s;

  position: absolute;
  top: 50%;
  right: 0%;
  z-index: 5;
}

@media screen and (max-height: 450px) {
  .sidebarRight {
    padding-top: 15px;
  }

  .sidebarRight a {
    font-size: 18px;
  }
}

/* end of Right sidebar css */

/* Top right Profile information */
.topNavTeam {
  right: 0%;
  z-index: 3;
}

/* End of Top right Profile information */

.tagHightlighter {
  padding-right: 20px;
}

.topBarGrid {
  display: grid;
  grid-template-columns: auto auto auto;
}

.grid-item {
  font-size: 18px;
  width: 250px;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 10px;
  border-radius: 8px;
  box-shadow: 5px 5px 8px 1px #88888853;
  background-color: rgba(255, 255, 255, 0.923);
}

.topBarEmployeeStatus {
  position: fixed;
  top: 2%;
  left: 10%;
  z-index: 1;
  margin-top: -150px;
  transition: 0.3s;
}

#mapContainer {
  width: 98%;
  height: 88vh;
  z-index: 1;
  margin: 15px;
}

.containerCard {
  z-index: 2;
  opacity: 0.95;
}

.employeeContainerCard {
  height: 100%;
  padding-top: 50px;
}

.markernameMonitor {
  background-color: rgb(167, 226, 247);
  padding: 5px;
  width: auto;
}

.nameCardContainer {
  font-size: 13px;
  text-align: start;
  font-weight: bolder;
  /* background-image: linear-gradient(15deg, #117abf 0%, #1fa8e1 100%); */
  background: #cac9c9;
  color: black;
}

.inputCard {
  background: white !important;
  border: none;
  outline: none;
}

.ml-64 {
  margin-left: 16.5rem;
}

.scrollable {
  overflow-y: scroll;
}

.h-70 {
  height: 60%;
}

.marker-icon {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.marker-icon h1.marker-name {
  font-size: 14px;
  margin: 0;
}

.marker-icon img {
  width: 31px;
  height: 31px;
  border-radius: 50%;
}

.marker-icon svg {
  width: 35px;
  height: 50px;
  fill: #2ea5ea;
}

.marker-icon.highlight {
  /* Highlighted marker icon styles */
  border: 2px solid #ff0000;
  box-shadow: 0 0 8px rgba(255, 0, 0, 0.6);
}

::-webkit-scrollbar {
  display: none !important;
}
</style>
