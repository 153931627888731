<template>
    <div class="row p-4">
        <div class="col-md-3 text-start">
            <label>Excel File:</label>
            <input type="file" @change="onChange" class="form-control" />
        </div>
        <div class="col-md-9">
            <div id="map"></div>
        </div>
    </div>
</template>

<script>
import { read, utils } from "xlsx";

import L from "leaflet";
import "leaflet/dist/leaflet.css";

export default {
    data() {
        return {
            mapData: [],
        }
    },

    mounted() {
        this.initializedMap();
    },

    methods: {
        initializedMap() {
            this.map = L.map("map", { trackResize: false }).setView([51.7473795, -0.3265224], 12);
            L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
                attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                minZoom: 0,
                maxZoom: 22,
                maxNativeZoom: 18,
            }).addTo(this.map);
            this.createMarker();
            // var markers = new L.MarkerClusterGroup();

            // markers.addLayer(L.marker([51.7473795, -0.3265224]));
            // // add more markers here...

            // this.map.addLayer(markers);
        },

        onChange(event) {
            const file = event.target.files ? event.target.files[0] : null;
            const reader = new FileReader();

            reader.onload = (e) => {
                const bstr = e.target.result;
                const wb = read(bstr, { type: 'binary' });
                const ws = wb.Sheets['Sheet1'];
                this.mapData = utils.sheet_to_json(ws, { header: 2 });
                console.log(this.mapData)

                this.createMarker();
            }

            reader.readAsBinaryString(file);
        },

        async createMarker() {
            // const temp = this.mapData
            // const latlngs = temp.map((element) => ([element.Latitude, element.Longitude]))
            // var polygon = L.polygon(latlngs, { color: 'red' });
            // polygon.addTo(this.map);

            // let clusters = {};
            // temp.forEach((element) => {
            //     if ((element.Latitude || false) && (element.Longitude || false)) {
            //         clusters[element.Cluster] = [
            //             ...(clusters[element.Cluster] || []),
            //             [element.Latitude, element.Longitude],
            //         ];
            //     }
            // });
            // console.log(clusters);
            const colors = ['red', 'blue', 'green', 'yellow']
            // Object.values(clusters).forEach((element, index) => {
            //     const polygon = L.polygon(element, { color: colors[index % colors.length] });
            //     polygon.addTo(this.map);
            // })
            const res = await fetch('https://map-cluster.free.beeceptor.com/map');
            console.log(res)
            const temp = await res.json();
            // const data = JSON.parse(temp);
            console.log(temp)
            Object.values(temp).forEach((element, index) => {
                const polygon = L.polygon(element, { color: colors[index % colors.length] });
                polygon.addTo(this.map);
            })

            // temp.forEach((element, index) => {
            //     const defaultIcon = L.divIcon({
            //         html: getMapMarker('#d63535'),
            //         className: "",
            //         iconSize: [24, 40],
            //         iconAnchor: [12, 40],
            //     });

            //     const marker = L.marker([element.Latitude, element.Longitude], { icon: defaultIcon }).addTo(this.map);
            //     const customPopup = `<div class="leafLetCard mt-1">
            //         <div class="justName">
            //            ${element.POSTCODE}
            //         </div>
            //         <div class="mapLocationName">
            //             <div class="svgIconClass">
            //                 <svg width="15" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            //                     <path d="M12 23.728L5.636 17.364C4.37734 16.1053 3.52018 14.5017 3.17292 12.7558C2.82567 11.01 3.0039 9.20041 3.68509 7.55588C4.36629 5.91136 5.51984 4.50575 6.99988 3.51683C8.47992 2.5279 10.22 2.00006 12 2.00006C13.78 2.00006 15.5201 2.5279 17.0001 3.51683C18.4802 4.50575 19.6337 5.91136 20.3149 7.55588C20.9961 9.20041 21.1743 11.01 20.8271 12.7558C20.4798 14.5017 19.6227 16.1053 18.364 17.364L12 23.728ZM16.95 15.95C17.9289 14.971 18.5955 13.7237 18.8655 12.3659C19.1356 11.008 18.9969 9.60058 18.4671 8.32154C17.9373 7.0425 17.04 5.94929 15.8889 5.18015C14.7378 4.41101 13.3844 4.00049 12 4.00049C10.6156 4.00049 9.26221 4.41101 8.11108 5.18015C6.95995 5.94929 6.06275 7.0425 5.53291 8.32154C5.00308 9.60058 4.86441 11.008 5.13445 12.3659C5.40449 13.7237 6.0711 14.971 7.05 15.95L12 20.9L16.95 15.95ZM12 13C11.4696 13 10.9609 12.7893 10.5858 12.4142C10.2107 12.0391 10 11.5304 10 11C10 10.4696 10.2107 9.96084 10.5858 9.58577C10.9609 9.2107 11.4696 8.99998 12 8.99998C12.5304 8.99998 13.0391 9.2107 13.4142 9.58577C13.7893 9.96084 14 10.4696 14 11C14 11.5304 13.7893 12.0391 13.4142 12.4142C13.0391 12.7893 12.5304 13 12 13Z" fill="black"/>
            //                 </svg>
            //             </div>
            //             <div>
            //                 ${element.Latitude}, ${element.Longitude}
            //             </div>
            //         <div>
            //     </div>`;

            //     marker.bindPopup(customPopup);
            // });
        },
    }
}

</script>

<style>
#map {
    width: 100%;
    height: 90vh;
    z-index: 1;
    margin: 15px;
}
</style>