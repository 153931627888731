<template>
    <div v-click-outside="toggleDropDown">
        <button id="dropdownSearchButton" data-dropdown-toggle="dropdownSearch" @click="toggleDropDown"
            data-dropdown-placement="bottom" class="btn btn-outline-primary px-4 py-1" type="button">
            Filter <span v-if="selectedUsers.length > 0"> - (User: {{ selectedUsers.length || '' }})</span>
        </button>

        <button v-if="selectedUsers.length > 0" type="button" class="btn btn-outline-primary-rev px-4 py-1 mx-3"
            @click="$emit('reset')">
            Reset
        </button>

        <!-- Dropdown menu -->
        <div id="dropdownSearch" :class="`z-10 bg-white rounded-lg shadow w-60 dark:bg-gray-700 hidden fixed`">
            <div class="">
                <div class="h-48 px-3 py-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200">
                    <div class="accordion" id="accordionReportFilter">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseDepartments" aria-expanded="false"
                                    aria-controls="collapseDepartments">
                                    Departments
                                </button>
                            </h2>
                            <div id="collapseDepartments" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                data-bs-parent="#accordionReportFilter">
                                <div class="accordion-body">
                                    <div class="border mb-2">
                                        <input type="text" class="inputCard" @change="handleDepartmentFilter"
                                            placeholder="Search ..." />
                                    </div>
                                    <ul>
                                        <li v-for="department in departments" :key="department.id">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" :value="department.id"
                                                    :id="`departments-${department.id}`" @change="onDepartmentSelect">
                                                <label class="form-check-label" :for="`departments-${department.id}`">
                                                    {{ department?.name ?? '' }}
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingThree">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseDesignations" aria-expanded="false"
                                    aria-controls="collapseDesignations">
                                    Designations
                                </button>
                            </h2>
                            <div id="collapseDesignations" class="accordion-collapse collapse"
                                aria-labelledby="headingThree" data-bs-parent="#accordionReportFilter">
                                <div class="accordion-body">
                                    <div class="border mb-2">
                                        <input type="text" class="inputCard" @change="handleDesignationFilter"
                                            placeholder="Search ..." />
                                    </div>
                                    <ul>
                                        <li v-for="designation in designations" :key="designation.id">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" :value="designation.id"
                                                    :id="`designations-${designation.id}`" @change="onDesignationSelect">
                                                <label class="form-check-label" :for="`designations-${designation.id}`">
                                                    {{ designation?.title ?? '' }}
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseUser" aria-expanded="false" aria-controls="collapseUser">
                                    Users
                                </button>
                            </h2>
                            <div id="collapseUser" class="accordion-collapse collapse" aria-labelledby="headingOne"
                                data-bs-parent="#accordionReportFilter">
                                <div class="accordion-body">
                                    <div class="border mb-2">
                                        <input type="text" class="inputCard" @change="handleUserFilter"
                                            placeholder="Search ..." />
                                    </div>
                                    <ul>
                                        <li>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" :id="`users-`"
                                                    @change="onUserSelect" :disabled="isFilterByDepartment">
                                                <label class="form-check-label" :for="`users-`">
                                                    All
                                                </label>
                                            </div>
                                        </li>
                                        <li v-for="employee in users" :key="employee.id">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" :value="employee.id"
                                                    :id="`users-${employee.id}`" @change="onUserSelect"
                                                    :disabled="isFilterByDepartment">
                                                <label class="form-check-label" :for="`users-${employee.id}`">
                                                    {{ employee?.first_name + " " + employee?.last_name }}
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.accordion-item {
    margin: 10px 0;
}

.accordion-header {
    background: #f5f5f5;
}

.accordion-button {
    padding: 5px 15px;
}

.inputCard {
    background: transparent !important;
    border: none;
    outline: none;
}
</style>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
    emits: ['reset', 'callback'],

    computed: {
        ...mapState("report", ["users", "departments", "designations", "selectedUsers"]),
        ...mapGetters("report", ["isFilterByDepartment"]),
    },

    mounted() {
        this.resetReportFilter();
        this.initReportState();
    },

    methods: {
        ...mapActions("report", [
            "initReportState",
            "handleUserSelect",
            "getUserLocationReportData",
            "handleDepartmentSelect",
            "handleDesignationSelect",
            "resetReportFilter",
            "handleDepartmentFilter",
            "handleDesignationFilter",
            "handleUserFilter",
        ]),

        toggleDropDown() {
            let toggleDropdown = document.getElementById("dropdownSearch");
            toggleDropdown.classList.toggle("hidden");
        },

        onUserSelect(e) {
            this.handleUserSelect(e);
            this.$emit('callback');
        },

        onDepartmentSelect(e) {
            this.handleDepartmentSelect(e).then((_) => this.$emit('callback'));
        },

        onDesignationSelect(e) {
            this.handleDesignationSelect(e).then((_) => this.$emit('callback'));
        },
    }
}
</script>