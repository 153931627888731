import api from "../../boot/axios";

export default class AuthApi {
  //login
  loginApi = async (loginData) => {
    try {
      const response = await api
        .post("/auth/login/", loginData, {
          withCredentials: true,
        });

      if (response.status === 200 && response.data.user.role === 'Company') {
        const resData = response.data;
        api.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${resData.access_token}`;
        localStorage.setItem("jwt", resData.access_token);
        localStorage.setItem("r_token", resData.refresh_token);
        localStorage.setItem("user", resData.user.id);
        localStorage.setItem("company", resData.user.company);
        localStorage.setItem("company_uuid", resData.user.uuid);
        localStorage.setItem("company_name", resData.user.company_name || '');
        localStorage.setItem("role", resData.user.role);
        localStorage.setItem(
          "fullname",
          resData.user.first_name + " " + resData.user.last_name
        );
        localStorage.setItem("avatar", resData.user.avatar);
        return { status: true }
      } else {
        return { status: false, message: '' }
      }
    } catch (err) {
      let msg = '';
      for (let e in err.response.data) {
        msg += err.response.data[e].join(' ');
      }
      return { status: false, message: msg }
    }
  };
  // logoutApi '/auth/logout/'
  logoutApi = async () => {
    console.log("logout");
    const response = await api.post("/auth/logout/", {});
    api.defaults.headers.common["Authorization"] = "";
    localStorage.clear();
    return true
  };
  //user-validate /token/verify/

  validateUser = async () => {
    try {
      if (!localStorage.getItem('jwt')) {
        return false
      }
      const token = {
        token: localStorage.getItem("jwt"),
      };
      const response = await api
        .post("/auth/token/verify/", token, {
          withCredentials: true,
        })
        .then((res) => {
          console.log(res.status);
          if (res.status === 200) return true;

          return false;
        });
      return response;
    } catch (e) {
      localStorage.clear();
      window.location.reload();
    }
  };

  changePassword = async (data) => {
    if (!localStorage.getItem('jwt')) {
      return { status: false }
    }
    api.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("jwt")}`;
    try {
      const response = await api
        .post("/auth/password/change/", data, {
          withCredentials: true,
        });
      console.log(response);
      if (response.status === 200) return { status: true };

      return { status: false };
    } catch (err) {
      let msg = '';
      for (let e in err.response.data) {
        msg += err.response.data[e].join(' ');
      }
      return { status: false, message: msg }
    }
  };
}
