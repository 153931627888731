<template>
  <button
    class="w-10 h-10 flex items-center justify-center rounded-lg custom-bg"
  >
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.70116 19.2963C9.88047 19.6031 10.2092 19.7917 10.5645 19.7917L12.0833 19.7917C12.8554 19.7917 13.3362 18.9538 12.9466 18.2872L9.85944 13.0046C9.67727 12.6929 9.67727 12.3072 9.85944 11.9955L12.9466 6.71298C13.3362 6.04633 12.8554 5.20841 12.0833 5.20841L10.5645 5.20841C10.2092 5.20841 9.88048 5.39702 9.70116 5.70385L6.02421 11.9955C5.84204 12.3072 5.84204 12.6929 6.02421 13.0046L9.70116 19.2963Z"
        fill="white"
      />
      <path
        d="M15.667 19.2963C15.8463 19.6031 16.175 19.7917 16.5304 19.7917L18.0491 19.7917C18.8212 19.7917 19.3021 18.9538 18.9125 18.2872L15.8253 13.0046C15.6431 12.6929 15.6431 12.3072 15.8253 11.9955L18.9125 6.71298C19.3021 6.04633 18.8212 5.20841 18.0491 5.20841L16.5304 5.20841C16.175 5.20841 15.8463 5.39702 15.667 5.70385L11.99 11.9955C11.8079 12.3072 11.8079 12.6929 11.99 13.0046L15.667 19.2963Z"
        fill="white"
      />
    </svg>
  </button>
</template>


<style>
.custom-bg{
    background-color: #FFBD5A;
}
</style>