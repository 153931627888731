<template>
    <div v-if="userAttendances?.length || false" class="mx-4 app-table mt-2">
        <div class="appscroll" style="height: 80vh;">
            <table class="table table-py table-striped">
                <thead class="position-sticky top-0">
                    <tr class="text-start">
                        <th>SL</th>
                        <th>User</th>
                        <th>Phone</th>
                        <th>Punch In</th>
                        <th>In Lat, Lng</th>
                        <th>Punch Out</th>
                        <th>Out Lat, Lng</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="text-start" v-for="(attendance, index) in userAttendances" :key="attendance.id">
                        <td>{{ index + 1 }}</td>
                        <td>
                            <p>
                                {{ `${attendance.user?.first_name || ''} ${attendance.user?.last_name || ''}` }}
                            </p>
                            <!-- <p>{{ attendance.user?.email || '' }}</p> -->
                        </td>
                        <td>
                            <p>{{ attendance.user?.username || '' }}</p>
                        </td>
                        <td>
                            {{ formateDate(attendance.in_created_at) }}
                        </td>
                        <td>
                            <div v-if="attendance.in_poi || false">
                                {{ getPoiString(attendance.in_poi, attendance.in_lat, attendance.in_long) }}
                            </div>
                            <div v-else>
                                <div class="badge badge-success mr-2">
                                    {{ attendance.in_lat }}
                                </div>
                                <div class="badge badge-blue">
                                    {{ attendance.in_long }}
                                </div>
                            </div>
                        </td>
                        <td>
                            {{ formateDate(attendance.out_created_at) }}
                        </td>
                        <td>
                            <div v-if="attendance.out_poi || false">
                                {{ getPoiString(attendance.out_poi, attendance.out_lat, attendance.out_long) }}
                            </div>
                            <div v-else>
                                <div class="badge badge-success mr-2">
                                    {{ attendance.out_lat }}
                                </div>
                                <div class="badge badge-blue">
                                    {{ attendance.out_long }}
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="d-flex justify-content-end mt-3">
            <div class="mr-2">
                <button @click="fetchPageData('previous')" :disabled="pagination.prev == ''"
                    class="btn btn-outline-primary">
                    Previous
                </button>
            </div>
            <div>
                <button @click="fetchPageData('next')" :disabled="pagination.next == ''" class="btn btn-outline-primary">
                    Next
                </button>
            </div>
        </div>
    </div>
</template>
  
<script>
import apis from "@/server/api/company/index";

export default {

    data: () => ({
        userAttendances: [],
        pageUrl: null,
        pagination: {
            next: '',
            prev: '',
        }
    }),

    mounted() {
        this.init();
    },

    methods: {
        async init() {
            const response = await apis.getUserAttendances({ url: this.pageUrl, });
            this.userAttendances = response.results || [];
            this.pagination.next = response.next || response.links?.next || '';
            this.pagination.prev = response.previous || response.links?.previous || '';
        },

        fetchPageData(status) {
            if (status == 'previous' && this.pagination.prev != '') {
                this.pageUrl = this.pagination.prev;
                this.init();
            } else if (status == 'next' && this.pagination.next != '') {
                this.pageUrl = this.pagination.next;
                this.init();
            }
        },

        formateDate(date) {
            return formateDateTime(date)
        }
    }
};
</script>