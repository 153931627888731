import { createStore } from 'vuex';
import core from './core/index';
import report from './report/index';

const store = createStore({
    modules: {
        core,
        report,
    }
})

export default store;