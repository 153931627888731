import api from "@/boot/axios";

export default {
    getDesignations: async () => {
        const response = await api
            .get(`api/company/v1/designation/`)
            .then((res) => {
                return res.data;
            });

        return response;
    },

    storeDesignation: async (data) => {
        try {
            await api.post(`api/company/v1/designation/`, data)
            return { status: true };
        } catch (err) {
            let msg = '';
            for (let e in err.response.data) {
                msg += err.response.data[e].join(' ');
            }
            return { status: false, message: msg }
        }
    },

    updateDesignation: async (data) => {
        try {
            if (data.id || false) {
                await api.put(`api/company/v1/designation/${data.id}/`, data)
                return { status: true };
            }
            return { status: false };
        } catch (err) {
            let msg = '';
            for (let e in err.response.data) {
                msg += err.response.data[e].join(' ');
            }
            return { status: false, message: msg }
        }
    },

    deleteDesignation: async (id) => {
        try {
            if (id || false) {
                await api.delete(`api/company/v1/designation/${id}/`)
                return { status: true };
            }
            return { status: false };
        } catch (err) {
            let msg = '';
            for (let e in err.response.data) {
                msg += err.response.data[e].join(' ');
            }
            return { status: false, message: msg }
        }
    }
}
