<template>
  <div class="">
    <div class="h-[100vh] w-[100px] absolute hover:w-[200px]">
      <SidenavteamMenu />
    </div>

    <HeaderNavbar title="Designations" />

    <div class="ml-sidebar">
      <div class="">
        <div class="mx-4 app-table mt-5">
          <div class="row justify-content-center">
            <div class="col-md-6">
              <div v-if="designations.length || false"  class="appscroll" style="max-height: 75vh;">
                <table class="table table-poi table-striped table-bordered m-0">
                  <thead>
                    <tr class="text-center">
                      <th>SL</th>
                      <th>Designation</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="text-center" v-for="(designation, index) in designations" :key="designation.id">
                      <td>{{ index + 1 }}</td>
                      <td>{{ designation.title || "" }}</td>
                      <td>
                        <button type="button" class="btn" data-bs-toggle="modal" data-bs-target="#designationModal"
                          @click="handleEdit(designation)">
                          <i class="fa fa-edit text-info"></i>
                        </button>
                        <button type="button" class="btn" @click="deleteDesignation(designation)">
                          <i class="fa fa-trash text-danger"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <button type="button" class="btn text-button text-sm" data-bs-toggle="modal"
                  data-bs-target="#designationModal" @click="resetForm">
                  <i class="fa fa-plus text-info"></i> Add Designation
                </button>
                <p class="text-xs text-start">Showing {{ designations.length || '0' }} entires</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="designationModal" tabindex="-1" aria-labelledby="designationModalLabel"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <h1 class="modal-title fs-5 text-start">
              {{ form.isEdit ? "Edit" : "Add" }} Designation
            </h1>
            <form @submit.prevent="submit" class="form-layout my-3 text-start">
              <div class="form-group">
                <label class="text-start">Title</label>
                <input type="text" class="form-control" placeholder="Type Name" v-model="form.designation.title" />
              </div>
              <div class="text-end">
                <button type="submit" class="btn btn-outline-primary" :disabled="isSaving" @click="submit">
                  {{ isSaving ? "Saving..." : "Save changes" }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SidenavteamMenu from "@/components/menu/SideNavbarTeam.vue";
import HeaderNavbar from "@/components/menu/headerdashboard.vue";
import apis from "@/server/api/company/index";

export default {
  components: { SidenavteamMenu, HeaderNavbar },

  data: () => ({
    designations: [],
    isSaving: false,
    form: {
      isEdit: false,
      designation: {
        title: "",
      },
    },
  }),

  mounted() {
    this.init();
  },

  methods: {
    async init() {
      this.designations = await apis.getDesignations();
    },

    handleEdit(data) {
      this.form.isEdit = true;
      this.form.designation = { ...data };
      if (data.parent || false) {
        this.form.isChild = true;
      } else {
        this.form.isChild = false;
      }
    },

    async submit() {
      this.isSaving = true;
      if (this.validation()) {
        if (this.form.isEdit) {
          this.handleUpdate();
        } else {
          this.handleStore();
        }
      }
    },

    async handleUpdate() {
      let success = false;
      if (this.form.designation.id || false) {
        const response = await apis.updateDesignation(this.form.designation);
        if (response.status || false) {
          this.$toast.success(`Designation update successfully`, {
            position: "top",
          });
          success = true;
        } else {
          if (response.message.length || false) {
            this.$toast.error(`${response.message}`, {
              position: "top",
            });
          } else {
            this.$toast.error(`Something went wrong! please refresh.`, {
              position: "top",
            });
          }
        }
      } else {
        this.$toast.error(`Something went wrong! please refresh.`, {
          position: "top",
        });
      }

      if (success) {
        this.resetForm();
        setTimeout(() => {
          this.$router.go();
        }, 800);
      }
    },

    async handleStore() {
      let success = false;
      const response = await apis.storeDesignation(this.form.designation);
      if (response.status || false) {
        this.$toast.success(`Designation add successfully`, {
          position: "top",
        });
        success = true;
      } else {
        if (response.message.length || false) {
          this.$toast.error(`${response.message}`, {
            position: "top",
          });
        } else {
          this.$toast.error(`Something went wrong! please refresh.`, {
            position: "top",
          });
        }
      }

      if (success) {
        this.resetForm();
        setTimeout(() => {
          this.$router.go();
        }, 800);
      }
    },

    async deleteDesignation(designation) {
      if (designation.id || false) {
        if (
          confirm(`are you sure, you want to delete ${designation.name || ""}?`)
        ) {
          const response = await apis.deleteDesignation(designation.id);
          if (response.status || false) {
            this.$toast.success(`Designation delete successfully`, {
              position: "top",
            });
          } else {
            if (response.message.length || false) {
              this.$toast.error(`${response.message}`, {
                position: "top",
              });
            } else {
              this.$toast.error(`Something went wrong! please refresh.`, {
                position: "top",
              });
            }
          }
          this.init();
        }
      }
    },

    validation() {
      if (!(this.form.designation.title.length || false)) {
        this.$toast.error(`Designation field is required`, {
          position: "top",
        });
        return false;
      }

      return true;
    },

    resetForm() {
      this.form = {
        isEdit: false,
        designation: {
          title: "",
        },
      };
    },
  },
};
</script>
