<template>
  <div>
    <div class="h-[100vh] w-[100px] absolute hover:w-[200px]">
      <SidenavteamMenu />
    </div>

    <HeaderNavbar title="Attendances" />


    <div class="ml-sidebar">
      <div class="mx-3">

        <UserAttendanceIndex />

      </div>
    </div>
  </div>
</template>

<script>
import SidenavteamMenu from "@/components/menu/SideNavbarTeam.vue";
import HeaderNavbar from "@/components/menu/headerdashboard.vue";
import UserAttendanceIndex from "./user-attendance/Index.vue";

export default {
  components: { SidenavteamMenu, HeaderNavbar, UserAttendanceIndex },
};
</script>
