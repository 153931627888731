<template>
    <div class="">
        <div class="h-[100vh] w-[100px] absolute hover:w-[200px]">
            <SidenavteamMenu />
        </div>

        <HeaderNavbar title="Create Geofence" />

        <div class="ml-sidebar">
            <div class="m-2">
                <div class="row">
                    <div class="col-md-9">
                        <div id="mapContainer">
                            <div class="map-full-screen">
                                <button class="btn" @click="handleFullScreenMap">
                                    <i class="fas fa-expand"></i>
                                </button>
                            </div>
                        </div>
                        <div class="position-fixed" style="top: 70px; left: 150px; z-index: 2;">
                            <div class="poi-form-sm">
                                <div class="form-layout shadow-lg">
                                    <div class="form-group p-2">
                                        <div class="d-flex align-items-center">
                                            <input type="text" class="form-control mb-0" placeholder="Search Lat, Lng"
                                                :value="latlng" @input="flytolocation" />
                                            <div class="mx-2">
                                                <i class="fa fa-search"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="text-xs">
                            <div class="poi-form border-rounded">
                                <form @submit.prevent="submit" class="form-layout my-3 text-start">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="text-start">Geofence name</label>
                                                <input type="text" class="form-control" placeholder="Type Geofence Name"
                                                    v-model="form.name" required />
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="text-start">Description</label>
                                                <textarea class="form-control" placeholder="Type Description"
                                                    v-model="form.description" row="2" required></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="text-end">
                                        <button type="submit" class="btn btn-outline-primary btn-sm"
                                            :disabled="isSaving" @click="submit">

                                            {{ isEdit ? (isSaving ? "Updating..." : "Update")
                                    : (isSaving ? "Saving..." : "Save") }}
                                        </button>
                                    </div>

                                    <!-- <div class="mt-0" style="font-size: 10px;">
                                        NOTES <br>
                                        * To add a POI, left click the mouse at the desired location on the map <br>
                                        * To modify/delete any POI, right click the mouse over its marker and select
                                        modify/delete respectively <br>
                                        * To perform Search with tabular Name/Description, enter relevant matching text in
                                        "Search" text filed and click on "Search" button <br>
                                        * To clear entered Searched text from "Search" text field click on "Show All" button
                                        <br>
                                    </div> -->
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<style>
.leaflet-draw-draw-polygon {
    background: blue;
}
</style>

<script>
import "leaflet/dist/leaflet.css";
import * as L from "leaflet";
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';

import SidenavteamMenu from "@/components/menu/SideNavbarTeam.vue";
import HeaderNavbar from "@/components/menu/headerdashboard.vue";
import apis from "@/server/api/company/index";

export default {
    components: { SidenavteamMenu, HeaderNavbar },

    data: () => ({
        latlng: '',
        marker: null,
        isSaving: false,
        isEdit: false,
        polygons: {},
        form: {
            name: '',
            description: '',
            geom: {
                type: "Polygon",
                coordinates: []
            }
        },
    }),

    mounted() {
        this.initializedMap();
        this.init();
    },

    methods: {

        async init() {
            if (this.$route.name == 'company-geo-fence-edit' && (this.$route.params.id || false)) {
                this.isEdit = true;
                this.form = await apis.getGeofence(this.$route.params.id);
                this.map.flyTo([23.8041, 90.4152], 25);
                this.handleMapMarker(23.8041, 90.4152);
            } else {
                this.isEdit = false;
            }
        },

        initializedMap() {
            this.map = L.map("mapContainer", {
                zoomControl: true,
                zoomAnimation: false,
                fadeAnimation: true,
                markerZoomAnimation: true,
                drawControl: true,
            }).setView([23.8187676, 90.4314902], 12);
            L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
                attribution: '',
                minZoom: 0,
                maxZoom: 22,
                maxNativeZoom: 18,
            }).addTo(this.map);

            this.polygons = new L.FeatureGroup().addTo(this.map)
            let drawControl = new L.Control.Draw({
                edit: false,
                draw: {
                    polygon: true,
                    polyline: false,
                    rectangle: false,
                    circle: false,
                    circlemarker: false,
                    marker: false,
                }
            });
            this.map.addControl(drawControl);

            this.map.on(L.Draw.Event.CREATED, (event) => {
                if (event.layerType === 'polygon') {
                    const latlngs = event.layer.getLatLngs();
                    const coods = latlngs.reduce((acc, cur) => {
                        return [
                            ...acc,
                            cur.reduce((point, curPoint, index) => {
                                if (index + 1 == cur.length) {
                                    return [
                                        ...point,
                                        [curPoint.lat, curPoint.lng],
                                        [cur[0].lat, cur[0].lng],
                                    ]
                                }
                                return [
                                    ...point,
                                    [curPoint.lat, curPoint.lng]
                                ];
                            }, [])
                        ];
                    }, []);
                    this.form.geom.coordinates = coods;
                }
                this.polygons.addLayer(event.layer);
            });

            let dom = document.getElementsByClassName('leaflet-draw-draw-polygon');
            if (dom.length > 0) {
                dom[0].click();
            }

        },

        handleMapMarker(lat, lng) {
            try {
                this.form.lat = lat;
                this.form.long = lng;

                if (this.marker || false) {
                    this.map.removeLayer(this.marker);
                }
                const defaultIcon = L.divIcon({
                    html: getMapMarker('#f73636'),
                    className: "",
                    iconSize: [24, 40],
                    iconAnchor: [12, 40],
                });

                this.marker = L.marker([lat, lng], { icon: defaultIcon, draggable: true }).addTo(this.map);
                this.marker.on('dragend', (event) => {
                    let markerItem = event.target;
                    let position = event.target.getLatLng();
                    markerItem.setLatLng(new L.LatLng(position.lat, position.lng), { draggable: 'true' });
                    this.map.panTo(new L.LatLng(position.lat, position.lng))
                    this.form.lat = position.lat;
                    this.form.long = position.lng;
                });
            } catch (_) {
                this.$toast.error(`Something went wrong!`, {
                    position: "top",
                });
            }
        },

        flytolocation(e) {
            this.latlng = e.target.value;
            const latlngarr = this.latlng.split(',');
            if (latlngarr.length == 2) {
                this.map.flyTo([latlngarr[0].trim(), latlngarr[1].trim()], 20);
                this.handleMapMarker(latlngarr[0].trim(), latlngarr[1].trim());
            }
        },

        async submit() {
            this.isSaving = true;
            if (this.validation()) {
                if (this.isEdit) {
                    this.handleUpdate();
                } else {
                    this.handleStore();
                }
            }
        },

        async handleUpdate() {
            let success = false;
            if (this.form.id || false) {
                const response = await apis.updateGeofence(this.form.poi);
                if (response.status || false) {
                    this.$toast.success(`Geofence update successfully`, {
                        position: "top",
                    });
                    success = true;
                } else {
                    if (response.message.length || false) {
                        this.$toast.error(`${response.message}`, {
                            position: "top",
                        });
                    } else {
                        this.$toast.error(`Something went wrong! please refresh.`, {
                            position: "top",
                        });
                    }
                }
            } else {
                this.$toast.error(`Something went wrong! please refresh.`, {
                    position: "top",
                });
            }

            if (success) {
                this.resetForm();
                this.$router.push({ name: 'company-geo-fence' });
            }
        },

        async handleStore() {
            let success = false;
            const response = await apis.storeGeofence(this.form);
            if (response.status || false) {
                this.$toast.success(`Geofence add successfully`, {
                    position: "top",
                });
                success = true;
            } else {
                if (response.message.length || false) {
                    this.$toast.error(`${response.message}`, {
                        position: "top",
                    });
                } else {
                    this.$toast.error(`Something went wrong! please refresh.`, {
                        position: "top",
                    });
                }
            }

            if (success) {
                this.resetForm();
                this.$router.push({ name: 'company-geo-fence' });
            }
        },

        validation() {
            const requriedField = [
                "name",
                "description",
            ];
            for (let key in this.form) {
                if (
                    requriedField.includes(key) &&
                    !(this.form[key] != '' && this.form[key] != null && this.form[key] != undefined)
                ) {
                    this.$toast.error(`${key} field is required`, {
                        position: "top",
                    });
                    this.isSaving = false;
                    return false;
                }
            }
            return true;
        },

        resetForm() {
            this.isEdit = false;
            this.form = {};
        },
    },

};
</script>

<style scoped>
#mapContainer {
    width: 100%;
    height: 89vh;
    z-index: 1;
}

.poi-form input {
    font-size: 12px;
}

.poi-form input::placeholder {
    font-size: 12px;
    color: #9ca3af;
}

.poi-form-sm {
    width: 300px;
}
</style>