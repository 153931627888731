import DepartmentsView from "@/views/company/department/Index.vue";
import DesignationsView from "@/views/company/designation/Index.vue";
import AttendanceView from "@/views/company/attendance/Index.vue";
import ShiftsWorkdaysView from "@/views/company/attendance/ShiftWorkday.vue";
import reportRoutes from './report';
import poiRoutes from './poi';
import monitorRoutes from './monitor';
import taskRoutes from './task';
import pageRoutes from './page';
import holidayRoutes from "./holiday";
import geofenceRoutes from "./geofence";

export default [
    {
        path: '/departments',
        name: 'company-departments',
        meta: {
            key: 'setting',
            title: 'Departments',
            auth: true,
        },
        component: DepartmentsView
    },
    {
        path: '/designations',
        name: 'company-designations',
        meta: {
            key: 'setting',
            title: 'Designations',
            auth: true,
        },
        component: DesignationsView
    },
    {
        path: '/attendance',
        name: 'company-attendance',
        meta: {
            key: 'attendance',
            title: 'Attendance',
            auth: true,
        },
        component: AttendanceView
    },
    {
        path: '/shifts-workdays',
        name: 'company-shifts-workdays',
        meta: {
            key: 'setting',
            title: 'Shifts & Workdays',
            auth: true,
        },
        component: ShiftsWorkdaysView
    },
    ...monitorRoutes,
    ...poiRoutes,
    ...taskRoutes,
    ...reportRoutes,
    ...pageRoutes,
    ...holidayRoutes,
    ...geofenceRoutes,
];