<template>
    <div>
        <div class="h-[100vh] w-[100px] absolute hover:w-[200px]">
            <SidenavteamMenu />
        </div>

        <HeaderNavbar title="Attendances" />

        <div class="ml-sidebar">
            <div class="mx-3">
                <div>
                    <div class="row my-3">
                        <div class="col-md-12">
                            <table class="table app-table">
                                <thead>
                                    <tr class="text-start">
                                        <th>Holiday Date</th>
                                        <th>Note</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="text-start" v-for="holiday in holidays" :key="holiday.id">
                                        <td :style="holiday.date_passed ? 'background-color: #ddd !important;' : ''">
                                            <div class="badge badge-purple">
                                                {{ new Date(
                                                    holiday.date
                                                ).toLocaleString("en-US", {
                                                    day: "numeric",
                                                    month: "long",
                                                    year: "numeric",
                                                }) }}
                                            </div>
                                        </td>
                                        <td class="fw-bold"
                                            :style="holiday.date_passed ? 'color: #1fa8e1 !important; background-color: #ddd !important;' : ''">
                                            {{
                                                holiday.name || '' }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div v-if="!addHoliday" class="text-start">
                        <button type="button" class="btn text-button" @click="toggleAddHoliday">
                            <i class="fa fa-plus text-info"></i> Add another
                        </button>
                    </div>
                    <form v-else @submit.prevent="submit" class="form-layout my-3 text-start">
                        <div class="row">
                            <div class="col-md-12">
                                <h4 class="text-bold">Add Holiday:</h4>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="text-start">Date</label>
                                    <input type="date" class="form-control" v-model="form.holiday.date" />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="text-start">Note</label>
                                    <input type="text" class="form-control" placeholder="Type Note"
                                        v-model="form.holiday.name" />
                                </div>
                            </div>
                        </div>
                        <div class="text-end">
                            <button type="submit" class="btn btn-outline-primary" :disabled="loading">Save changes</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import SidenavteamMenu from "@/components/menu/SideNavbarTeam.vue";
import HeaderNavbar from "@/components/menu/headerdashboard.vue";
import apis from "@/server/api/company/index";

export default {
    components: { SidenavteamMenu, HeaderNavbar },

    data: () => ({
        loading: false,
        holidays: [],
        addHoliday: false,
        form: {
            holiday: {
                date: null,
                name: '',
                is_company_holiday: true,
            }
        },
    }),

    mounted() {
        this.init();
    },

    methods: {
        async init() {
            this.holidays = await apis.getHolidays();
        },

        async submit() {
            const response = await apis.storeHoliday(this.form.holiday);
            if (response.status || false) {
                this.$toast.success(`Holiday add successfully`, {
                    position: "top",
                });
                this.resetForm();
            } else {
                if (response.message.length || false) {
                    this.$toast.error(`${response.message}`, {
                        position: "top",
                    });
                } else {
                    this.$toast.error(`Something went wrong! please refresh.`, {
                        position: "top",
                    });
                }
            }

            this.toggleAddHoliday();
            this.init();
        },



        resetForm() {
            this.form = {
                holiday: {
                    date: null,
                    name: '',
                    is_company_holiday: true,
                },
            };
        },

        toggleAddHoliday() {
            this.addHoliday = !this.addHoliday;
        },

    }
};
</script>
