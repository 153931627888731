import api from "@/boot/axios";

export default {
    getDepartments: async () => {
        const response = await api
            .get(`api/company/v1/department/`)
            .then((res) => {
                return res.data;
            });

        return response;
    },

    storeDepartment: async (data) => {
        try {
            await api.post(`api/company/v1/department/`, data)
            return { status: true };
        } catch (err) {
            let msg = '';
            for (let e in err.response.data) {
                msg += err.response.data[e].join(' ');
            }
            return { status: false, message: msg }
        }
    },

    updateDepartment: async (data) => {
        try {
            if (data.id || false) {
                await api.put(`api/company/v1/department/${data.id}/`, data)
                return { status: true };
            }
            return { status: false };
        } catch (err) {
            let msg = '';
            for (let e in err.response.data) {
                msg += err.response.data[e].join(' ');
            }
            return { status: false, message: msg }
        }
    },

    deleteDepartment: async (id) => {
        try {
            if (id || false) {
                await api.delete(`api/company/v1/department/${id}/`)
                return { status: true };
            }
            return { status: false };
        } catch (err) {
            let msg = '';
            if (err.response.data instanceof Array) {
                for (let e in err.response.data) {
                    msg += err.response.data[e].join(' ');
                }
                return { status: false, message: msg }
            } else {
                return { status: false, message: err.response.data }
            }
        }
    }
}
