import MonitorView from "@/views/company/monitor/MonitorVue.vue";
import MonitorSingleUserView from "@/views/company/monitor/MonitorSingleUser.vue";
import TeamView from "@/views/company/team/TeamView.vue";
import ManageEmployeeView from "@/views/company/user/UserListView.vue";

export default [
    {
        path: '/monitor',
        name: 'monitor',
        meta: {
            key: 'monitor',
            title: 'Monitor',
            auth: true,
        },
        component: MonitorView
    },
    {
        path: "/monitor/single-user/:id",
        name: "monitor-single-user",
        meta: {
            key: 'monitor',
            title: 'Monitor Single User',
            auth: true,
        },
        component: MonitorSingleUserView
    },
    {
        path: "/team",
        name: "team",
        meta: {
            key: 'my-team',
            title: 'My Team',
            auth: true,
        },
        component: TeamView
    },
    {
        path: "/manage-employee",
        name: "Userlist",
        meta: {
            key: 'manage-employee',
            title: 'Manage Employee',
            auth: true,
        },
        component: ManageEmployeeView,
    },
];