<template>
    <div class="">
    <div
      class="bg-white border-black-200   rounded dark:bg-gray-900 custom-shadow w-72 text-white flex"
    >
    <div class="justify-start rounded-l-lg bg-blue-400 py-2.5 w-24">
            <div>Today</div>
            <div class=" text-xs">{{ date }}</div>
        </div>
      <div class="flex justify-between  rounded-md p-2 text-xs ml-7" >
        
        <div class="ml-3 p-2  rounded-md  bg-disable">
          <div class="text-secondary">Total Members</div>
          <div class="text-success">1500</div>
        </div>
        <div class="ml-3 p-2  rounded-md  bg-disable ">
          <div class="text-secondary">Gps Members</div>
          <div class="text-primary">1500</div>
        </div>
        <div class="ml-3 p-2  rounded-md  bg-warning ">
          <div class="text-white">Gps Members</div>
          <div class="text-white">1500</div>
        </div>
        <div class="ml-3 p-2  rounded-md  bg-error ">
          <div class="text-white">Gps Members</div>
          <div class="text-white">1500</div>
        </div>
        <div class="ml-3 p-2  rounded-md  bg-primary ">
          <div class="text-white">Gps Members</div>
          <div class="text-white">1500</div>
        </div>
        <div class="ml-3 p-2  rounded-md  bg-purple-500 ">
          <div class="text-white">Gps Members</div>
          <div class="text-white">1500</div>
        </div>
        <div class="ml-3 p-2  rounded-md  bg-blue-700 ">
          <div class="text-white">Gps Members</div>
          <div class="text-white">1500</div>
        </div>
      </div>
    </div>
</div>
  </template>
 
  <style>
  .custom-shadow{
      background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.07);
   

}
.w-72{
        width: 71%;
    }

  </style>
    <script >

  export default {
    data(){
      return{
        date: new Date().toLocaleString("en-US", {timeZone: "Asia/Dhaka"}).substr(0, 9)
      }
    }
  
  };
  </script>
