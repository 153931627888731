<script>
import SideNavbar from "@/components/menu/SideNavbarTeam.vue";
import CompanyApi from "@/server/api/company";
import api from "@/boot/axios.js";
import Multiselect from "@vueform/multiselect";
import HeaderDashboard from "@/components/menu/headerdashboard.vue";

export default {
  data() {
    return {
      companyApi: new CompanyApi(),
      Taskid: "",
      taskList: "",
      showPopup: false,
      assignedTaskList: [],
      employeeList: [],
      employeeList02: [],
      idAndNameList: [],
      allDeartments: [],
      allDesignation: [],
      selectedDepartment: null,
      selectedDesignation: null,
      query: '',
      searchQuery: "",
      form: {
        lat: "",
        long: "",
        status: "Assigned",
        task: "",
        task_assign_to: [],
      },
      assignToInput: "",
      isSaving: false,
      task: {},
      allEmpTaskAssign: false,
    };
  },
  components: {
    SideNavbar,
    Multiselect,
    HeaderDashboard,
  },
  created() {
    this.userList();
    this.getDepartments();
    this.getDesignation();
  },
  mounted() {
    this.Taskid = this.$route.params.id;
    this.form.task = this.$route.params.id;
    this.getTask();
  },
  watch: {
    assignToInput(newValue) {
      this.form.task_assign_to = newValue
        .split(",")
        .map((item) => Number(item.trim()));
    },
  },
  computed: {
    filteredEmployees() {
      return this.employeeList02.filter((emp) => {
        const name = `${emp.first_name} ${emp.last_name}`.toLowerCase();
        const phone = emp.phone.toLowerCase();
        const email = emp.email.toLowerCase();
        const searchQuery = this.searchQuery.toLowerCase();
        const departmentFilter = this.selectedDepartment
          ? emp?.user_department?.id === this.selectedDepartment
          : true;
        const designationFilter = this.selectedDesignation
          ? emp?.user_designation?.id === this.selectedDesignation
          : true;

        return (
          (name.includes(searchQuery) ||
            phone.includes(searchQuery) ||
            email.includes(searchQuery)) &&
          departmentFilter &&
          designationFilter
        );
      });
    },
  },
  methods: {
    async getTask() {
      const taskId = this.$route.params.id;
      await api.get(`api/task/v1/task-detail/${taskId}/`).then((res) => {
        this.task = res.data.task_detail[0] || {};
        this.assignedTaskList = res.data.task_assignee || [];
      });
    },
    async userList() {
      this.companyApi.getUserListByCompany().then((res) => {
        this.employeeList02 = res;
        console.log(this.employeeList02, "employee02");
        this.employeeList = res;
        console.log(res, "GET all user of the company");
        this.idAndNameList = this.employeeList.map(
          ({ id, first_name, last_name }) => ({
            id,
            first_name,
            last_name,
          })
        );
        console.log(this.idAndNameList, "chekcing name and name");
      });
    },
    updateTaskAssignTo() {
      this.form.task_assign_to = this.employeeList02
        .filter((emp) => emp.checked)
        .map((emp) => emp.id);
    },

    checkedAllTaskAssignTo() {
      console.log(this.allEmpTaskAssign)
      if (this.allEmpTaskAssign) {
        this.form.task_assign_to = this.employeeList02
          .map((emp) => {
            this.toggleEmployeeCheck(emp)
            return emp.id
          });
      } else {
        this.employeeList02
          .map((emp) => {
            this.toggleEmployeeCheck(emp)
            return emp.id
          });
        this.form.task_assign_to = [];
      }
    },

    toggleEmployeeCheck(emp) {
      emp.checked = !emp.checked;
      this.updateTaskAssignTo();
    },
    async assignedTask() {
      await api.get("api/task/v1/task-assign/").then((res) => {
        this.assignedTaskList = res.data.results;
        console.log(res.data?.results, "GET all the assigned task list");
      });
    },
    async getDepartments() {
      await api.get("api/company/v1/department/").then((res) => {
        this.allDeartments = res.data;
        console.log(res.data, "checking the department list");
      });
    },
    async getDesignation() {
      await api.get("api/company/v1/designation/").then((res) => {
        this.allDesignation = res.data;
        console.log(res.data, "checking the designation list");
      });
    },

    async getAssignEmp() {
      await this.getTask();
      this.assignedTaskList = this.assignedTaskList.filter((item) => {
        return item.employee_id == this.query ||
          item.first_name.toLowerCase().includes(this.query) ||
          item.last_name.toLowerCase().includes(this.query) ||
          item.phone.includes(this.query);
      });
    },

    submit() {
      this.isSaving = true;
      api
        .post("api/task/v1/task-assign/", this.form)
        .then((res) => {
          console.log(res.status);
          this.$toast.success(`Assigned successfully`, {
            position: "top",
          });
        })
        .finally(() => {
          this.isSaving = false;
          this.showPopup = false;
          this.form = {
            lat: "",
            long: "",
            status: "Assigned",
            task: "",
            task_assign_to: [],
          }
          this.getTask();
          this.$router.go();
        })
        .catch((err) => {
          this.isSaving = false;
          this.showPopup = false;
          this.form = {
            lat: "",
            long: "",
            status: "Assigned",
            task: "",
            task_assign_to: [],
          }
          this.getTask();
          this.$router.go();
          console.log(err.status);
        });
    },
  },
};
</script>

<style scoped>
.personDetailsButton {
  border-radius: 5px;
  color: rgba(106, 106, 106, 0.346);
  border: solid rgba(106, 106, 106, 0.346) 1px;
  margin-top: 20px;
  font-size: 12px;
  padding: 10px 20px;
}

.popupContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.242);
}
</style>

<template>
  <div>
    <!-- popup  -->
    <div v-if="showPopup">
      <div class="popupContainer">
        <div class="bg-white w-[50%] py-7 px-8 z-30">
          <div class="w-full flex justify-end">
            <button class="" @click="showPopup = !showPopup">
              <img :src="require(`@/assets/icons/close.svg`)" alt="searchLogo" class="h-[16px] w-[16px]" />
            </button>
          </div>
          <div class="text-sm dark:text-gray-600 text-start">
            Assign Employee
          </div>

          <hr class="mt-2" />
          <div class="mt-4 pl-5">
            <form @submit.prevent="submit" class="form-layout my-3 text-start">
              <div class="container">
                <div class="row text-xs">
                  <div class="col-4">
                    <div class="input-group">
                      <label class="text-start"> Search</label>
                      <div class="input-group">
                        <input v-model="searchQuery" type="text" class="form-control" placeholder="Type Employee Name"
                          aria-label="Username" aria-describedby="basic-addon1" />
                      </div>
                    </div>
                  </div>
                  <div class="col-4">
                    <label class="text-start"> Department</label>
                    <select class="form-control" v-model="selectedDepartment">
                      <option value="">All</option>
                      <option :value="allDep.id" v-for="allDep in allDeartments" :key="allDep.id">
                        {{ allDep.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-4">
                    <label class="text-start"> Designation</label>
                    <select class="form-control" v-model="selectedDesignation">
                      <option value="">All</option>
                      <option :value="allDes.id" v-for="allDes in allDesignation" :key="allDes.id">
                        {{ allDes.title }}
                      </option>
                    </select>
                  </div>

                  <div class="col-12">
                    <div class="h-[300px] overflow-scroll">
                      <table class="table text-xs">
                        <thead>
                          <tr class="text-gray-500">
                            <th scope="col">
                              <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" v-model="allEmpTaskAssign"
                                  @change="checkedAllTaskAssignTo" />
                              </div>
                            </th>
                            <th scope="col">Employee name</th>
                            <th scope="col">Contact No.</th>
                            <th scope="col">Email Address</th>
                            <th scope="col">Department</th>
                            <th scope="col">Designation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="emp in filteredEmployees" :key="emp.id">
                            <tr @click="toggleEmployeeCheck(emp)" class="cursor-pointer">
                              <td>
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" :id="emp.id" v-model="emp.checked"
                                    @change="updateTaskAssignTo" />
                                </div>
                              </td>
                              <td>{{ emp.first_name }} {{ emp.last_name }}</td>
                              <td>{{ emp.phone }}</td>
                              <td>{{ emp.email }}</td>
                              <td>{{ emp.user_department?.name }}</td>
                              <td>{{ emp.user_designation?.title }}</td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-end">
                <button type="submit" class="btn btn-outline-primary" :disabled="isSaving" @click="submit">
                  {{ isSaving ? "Saving..." : "Save changes" }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- End of popup  -->
    <SideNavbar />
    <HeaderDashboard title="Assigned-Task" />
    <div></div>
    <div class="ml-sidebar">
      <div class="mx-4 app-table mt-4">
        <table class="table" v-if="task || false">
          <thead>
            <tr class="text-start">
              <th>Title</th>
              <th>Client Name</th>
              <th>Description</th>
              <th>Address</th>
              <th>Lat</th>
              <th>Long</th>
              <th>Priority</th>
              <th>Total Assign</th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-start" v-if="task || false">
              <td>{{ task.title }}</td>
              <td>{{ task.client_name }}</td>
              <td>{{ task.description }}</td>
              <td>
                <div class="">{{ task.address }}</div>
              </td>
              <td>
                <div class="badge badge-success">{{ task.lat }}</div>
              </td>
              <td>
                <div class="badge badge-orange">{{ task.long }}</div>
              </td>
              <td>
                <div class="badge badge-purple">
                  {{ task.priority }}
                </div>
              </td>
              <td>{{ assignedTaskList.length || 0 }}</td>
            </tr>
          </tbody>
        </table>
        <div class="my-4">
          <div class="d-flex justify-content-between">
            <div class="form-group flex">
              <input type="text" class="form-control me-2" v-model="query" placeholder="emp id, name, phone ... " />
              <button type="button" class="btn btn-outline-primary" @click="getAssignEmp">Search</button>
            </div>
            <button type="button" @click="showPopup = !showPopup">
              <i class="fa fa-plus text-info"></i> Add user
            </button>
          </div>
        </div>
        <table class="table table-striped">
          <thead>
            <tr class="text-start">
              <th>Emp Id</th>
              <th>Assigned to</th>
              <th>Contact No.</th>
              <th>Email</th>
              <th>Department</th>
              <th>Designation</th>
              <th>Status</th>
              <th>Note</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="task_assign in assignedTaskList" :id="task_assign.id">
              <tr class="text-start">
                <td>
                  {{ task_assign?.employee_id }}
                </td>
                <td>
                  {{ task_assign?.first_name }}
                  {{ task_assign?.last_name }}
                </td>
                <td>
                  {{ task_assign?.phone }}
                </td>
                <td>
                  {{ task_assign?.email }}
                </td>
                <td>
                  {{ task_assign?.user_department || '' }}
                </td>
                <td>
                  {{ task_assign?.user_designation || '' }}
                </td>
                <td>
                  <div v-if="task_assign?.task_status == 'Assigned'" class="badge badge-orange">
                    {{ task_assign?.task_status }}
                  </div>
                  <div v-else-if="task_assign?.task_status == 'Completed'" class="badge badge-success">
                    {{ task_assign?.task_status }}
                  </div>
                  <div v-else class="badge badge-purple">
                    {{ task_assign?.task_status }}
                  </div>
                </td>
                <td>
                  <template v-if="task_assign?.note">
                    {{ task_assign?.note }}
                  </template>
                  <template v-else> N/A </template>
                </td>
                <td>
                  {{ formateDateString(new Date(task_assign?.created_at)) }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
