import ComingSoonView from "@/views/pages/coming-soon/Index.vue";
import MapClusterView from "@/views/company/monitor/MapClusterView.vue";

export default [
    {
        path: '/visit',
        name: 'company-visit',
        meta: {
            key: 'visit',
            title: 'Visit',
            auth: true,
        },
        component: ComingSoonView
    },
    {
        path: '/leave',
        name: 'company-leave',
        meta: {
            key: 'leave',
            title: 'Leave',
            auth: true,
        },
        component: ComingSoonView
    },
    {
        path: '/expense',
        name: 'company-expense',
        meta: {
            key: 'expense',
            title: 'Expenses',
            auth: true,
        },
        component: ComingSoonView
    },
    {
        path: '/customer',
        name: 'company-customer',
        meta: {
            key: 'customer',
            title: 'Customer',
            auth: true,
        },
        component: ComingSoonView
    },
    {
        path: '/insight',
        name: 'company-insight',
        meta: {
            key: 'insight',
            title: 'Insight',
            auth: true,
        },
        component: ComingSoonView
    },
    {
        path: '/form',
        name: 'company-forms',
        meta: {
            key: 'form',
            title: 'Forms',
            auth: true,
        },
        component: ComingSoonView
    },
    {
        path: '/settings',
        name: 'company-settings',
        meta: {
            key: 'settings',
            title: 'Settings',
            auth: true,
        },
        component: ComingSoonView
    },
    {
        path: '/map-cluster',
        name: 'company-map-cluster',
        meta: {
            key: 'map-cluster',
            title: 'MapCluster',
            auth: true,
        },
        component: MapClusterView
    },
];