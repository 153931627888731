<template>
  <div>
    <div class="justify-center w-80 bg-gray-100 rounded-md mb-4">
      <div class="flex flex-col rounded-lg w-80 pl-2 pt-2">
        <div class="flex w-full justify-between">
          <div class="flex">
            <img
              class="w-16 h-16 object-cover md:rounded-lg"
              :src="`${employee?.avatar}`"
              alt=""
            />
            <div class="text-start ml-4">
              <div class="text-sm">
                {{ employee?.first_name + " " + employee?.last_name }}
              </div>
              <div class="text-xs">{{ employee?.username }}</div>
              <button
                class="text-xs bg-green-500 rounded-md p-0.5 w-20 text-white"
              >
                Available
              </button>
            </div>
          </div>

          <!-- icons -->
          <!-- user icon -->
          <div class="flex mr-4">
            <div class="">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 75"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="50" height="50" rx="10" fill="#5D5FEF" />
                <path
                  d="M25.0313 26.3958C24.8021 26.3854 24.5729 26.375 24.3333 26.375C21.8125 26.375 19.4583 27.0729 17.4479 28.2708C16.5313 28.8125 16 29.8333 16 30.9062V32.625C16 33.1979 16.4688 33.6667 17.0417 33.6667H25.6458C24.9101 32.6171 24.4656 31.3913 24.3577 30.1142C24.2497 28.837 24.4821 27.554 25.0313 26.3958Z"
                  fill="white"
                />
                <path
                  d="M24.3334 25.3333C26.6346 25.3333 28.5001 23.4679 28.5001 21.1667C28.5001 18.8655 26.6346 17 24.3334 17C22.0322 17 20.1667 18.8655 20.1667 21.1667C20.1667 23.4679 22.0322 25.3333 24.3334 25.3333Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M35.7703 25.4797C35.9656 25.675 35.9656 25.9915 35.7703 26.1868L29.7703 32.1868C29.575 32.3821 29.2585 32.3821 29.0632 32.1868L26.5632 29.6868C26.3679 29.4915 26.3679 29.175 26.5632 28.9797C26.7585 28.7844 27.075 28.7844 27.2703 28.9797L29.4167 31.1261L35.0632 25.4797C35.2585 25.2844 35.575 25.2844 35.7703 25.4797Z"
                  fill="white"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <!-- Ping location  -->
            <div @click="pingUserLocation({ employee, index })">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 75"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="50" height="50" rx="10" fill="#2EA5EA" />
                <g clip-path="url(#clip0_0_1)">
                  <path
                    d="M33.94 24C33.48 19.83 30.17 16.52 26 16.06V14H24V16.06C19.83 16.52 16.52 19.83 16.06 24H14V26H16.06C16.52 30.17 19.83 33.48 24 33.94V36H26V33.94C30.17 33.48 33.48 30.17 33.94 26H36V24H33.94ZM25 32C21.13 32 18 28.87 18 25C18 21.13 21.13 18 25 18C28.87 18 32 21.13 32 25C32 28.87 28.87 32 25 32Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_0_1">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="translate(13 13)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <!-- show route -->
            <!-- <div class="" @click="trackUserHistory(employee)">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 75"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="50" height="50" rx="10" fill="#2EA5EA" />
                <path
                  d="M27.0625 18.7292C28.2083 18.7292 29.1458 17.7917 29.1458 16.6458C29.1458 15.5 28.2083 14.5625 27.0625 14.5625C25.9167 14.5625 24.9792 15.5 24.9792 16.6458C24.9792 17.7917 25.9167 18.7292 27.0625 18.7292ZM21.8451 22.801C22.4356 22.5714 23.0468 23.0841 22.9234 23.7055L20.5289 35.7636C20.4061 36.382 20.8793 36.9583 21.5098 36.9583H21.6792C22.1469 36.9583 22.5521 36.6341 22.6548 36.1778L23.9668 30.3467C24.1373 29.5886 25.0694 29.3062 25.6321 29.842L26.2313 30.4128C26.4295 30.6015 26.5417 30.8632 26.5417 31.1369V35.9583C26.5417 36.5106 26.9894 36.9583 27.5417 36.9583H27.625C28.1773 36.9583 28.625 36.5106 28.625 35.9583V29.5744C28.625 29.3007 28.5128 29.039 28.3147 28.8503L26.8365 27.4425C26.5881 27.2059 26.4783 26.8586 26.5456 26.5222L26.6624 25.938C26.8254 25.1228 27.8996 24.8185 28.5935 25.2764C29.5298 25.8942 30.6224 26.3195 31.7945 26.4756C32.3419 26.5485 32.7917 26.094 32.7917 25.5417V25.4583C32.7917 24.906 32.3401 24.4682 31.7969 24.3683C30.2617 24.0861 29.0019 23.1648 28.3125 21.9583L27.2708 20.2917C26.8542 19.6667 26.2292 19.25 25.5 19.25C25.2596 19.25 25.0808 19.3116 24.8689 19.3401C24.7362 19.3579 24.6031 19.381 24.4799 19.4332L19.8604 21.3876C19.4904 21.5441 19.25 21.9069 19.25 22.3086V25.5417C19.25 26.094 19.6977 26.5417 20.25 26.5417H20.3333C20.8856 26.5417 21.3333 26.094 21.3333 25.5417V23.5491C21.3333 23.2181 21.5366 22.921 21.8451 22.801Z"
                  fill="white"
                />
              </svg>
            </div> -->
          </div>
        </div>
      </div>
      <div class="text-xs text-start pl-2">
        <div class="text-xs font-bold flex justify-between">
          <div>Last Location</div>
          <div>
            {{ getFormatedtime(employee?.last_location?.created_at) }}
          </div>
        </div>
        <div v-if="employee.last_location.poi.landmark" class="text-xs">
          {{ employee?.last_location?.poi?.landmark }},{{
            employee?.last_location?.poi?.name
          }},{{ employee?.last_location?.poi?.address }},
          {{
            employee?.last_location?.poi?.union === "N/A"
              ? employee?.last_location?.poi?.union + ", "
              : ""
          }}
          {{ employee?.last_location?.poi?.thana }} ,
          {{ employee?.last_location?.poi?.district }}
        </div>
        <div v-else class="text-xs">
          {{ employee?.last_location?.lat }},
          {{ employee?.last_location?.long }}
        </div>
      </div>
      <div class="flex w-full justify-between">
        <div class="ml-4 pt-2 pb-2">
          <button class="text-xs bg-black rounded-md p-0.5 w-16 text-white">
            {{ employee?.last_location?.distance }} M
          </button>
        </div>
        <div class="flex mr-4 pt-2 pb-2">
          <button
            class="font-xxs bg-blue-400 rounded-md pl-0.5 pr-0.5 w-24 text-white mr-3"
          >
            {{ employee?.last_location?.lat }}
          </button>
          <button
            class="font-xxs bg-green-500 rounded-md pl-0.5 pr-0.5 w-24 text-white"
          >
            {{ employee?.last_location?.long }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Utils from "../../utils/utils";
export default {
  props: ["employee", "index"],
  data() {
    return {
      employeeInfo: null,
      utils: new Utils(),
    };
  },
  setup(props) {
    // setup() receives props as the first argument.
    console.log(props.employee);
    console.log(props.index);
  },
  methods: {
    pingUserLocation(employee, index) {
      this.$emit("userToPing", { employee, index });
    },
    trackUserHistory(props) {
      this.$emit("userIdForHistory", props.id);
    },
    getFormatedtime(datetime) {
      return this.utils.formatTime(datetime);
    },
  },
};
</script>

<style>
.emp-card-max-width {
  max-width: 20rem;

  border-radius: 0.5rem;
}
.font-xxs {
  font-size: 0.75rem;
}
</style>
