<template>
  <div class="">
    <div class="h-[100vh] w-[100px] absolute hover:w-[200px]">
      <SidenavteamMenu />
    </div>
    <!-- Top right Profile information -->
    <div class="topNavTeam flex justify-end items-center bg-transparent absolute px-5 py-5">
      <div class="mx-3 font-sans font-[500] text-gray-500 hover:text-black cursor-pointer">
        Hello, {{ user?.name }}
      </div>
      <img class="w-7 h-7 rounded-xl" :src="`${user?.avatar}`" alt="" />
    </div>
    <!-- End of Top right Profile information -->

    <!-- Right sidebar -->
    <div id="mySidebar" class="sidebar">
      <!-- selected employees Information details -->
      <div class="h-auto w-[300px] bg-white" v-if="selectedEmployee">
        <div class="shadow-2xl">
          <div v-if="selectedEmployee || false">
            <div class="bg-black flex items-center justify-between px-2 py-2 text-white">
              <div class="w-full text-start">
                <div class="text-sm font-semibold">
                  {{ selectedEmployee.first_name }}
                  {{ selectedEmployee.last_name }}
                </div>
                <div class="text-xs flex">
                  <div>Emp.ID:</div>
                  <div>
                    {{ selectedEmployee.mobile || selectedEmployee.username || '' }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Employee Status Timeline -->
          <div class="timelineContainer scrollbar-hidden">
            <ul class="sessions h-auto overflow-scroll px-2 py-12">
              <template v-for="(lh, index) in locationHistory" :key="lh.id">
                <p class="sessionTIME">
                  {{
                    new Date(lh?.created_at).toLocaleString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                    })
                  }}
                </p>

                <div :class="{
                      sessionDistance: true,
                      'red-text': index === locationHistory.length - 1,
                    }">
                  {{ lh?.distance }}m
                </div>

                <li class="sessionsLi" @mouseover="flyToLocationInMap(lh)">
                  <div class="flex ml-[12px]">
                    <div class="pointyShape"></div>
                    <div class="sessionsCard" :class="{ 'idle_color': lh?.travelling_status == 'Idle' }">
                      <!-- Index -->

                      <div class="numbericValues" :style="{
                        backgroundColor:
                          index === 0
                            ? '#4caf50'
                            : lh?.travelling_status == 'Idle'
                              ? '#f44336' :
                              index === locationHistory.length - 1
                                ? '#f44336'
                                : '#3f51b5',
                      }">
                        {{
                          index === 0
                          ? "E"
                          : index === locationHistory.length - 1
                            ? "S"
                            : locationHistory.length - index - 1
                        }}
                      </div>

                      <div class="flex justify-between items-center text-center">
                        <div class="text-[15px]">
                          <i class="fa fa-user" v-if="lh?.travelling_status == 'Idle'"></i>
                          <i class="fa fa-car" v-else></i>
                          {{ lh?.travelling_status }}
                        </div>
                        <div class="text-[15px] font-bold">
                          {{ convertToHMS(lh?.duration) }}
                        </div>
                      </div>
                      <div class="flex justify-between items-center text-[13px]">
                        <div>
                          <svg class="inline" width="14" height="14" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C21.9939 17.5203 17.5203 21.9939 12 22ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C19.995 7.58378 16.4162 4.00496 12 4ZM17 13H11V7H13V11H17V13Z"
                              fill="#2E3A59" />
                          </svg>

                          {{
                            new Date(lh?.created_at).toLocaleString(
                              "en-US",
                              {
                                hour: "numeric",
                                minute: "numeric",
                              }
                            )
                          }}
                        </div>
                        to
                        <div>
                          <svg class="inline" width="12" height="12" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C21.9939 17.5203 17.5203 21.9939 12 22ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C19.995 7.58378 16.4162 4.00496 12 4ZM17 13H11V7H13V11H17V13Z"
                              fill="#2E3A59" />
                          </svg>

                          {{
                            new Date(lh?.updated_at).toLocaleString(
                              "en-US",
                              {
                                hour: "numeric",
                                minute: "numeric",
                              }
                            )
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </template>
            </ul>
          </div>
          <!-- End of Employee Status Timeline -->
        </div>
      </div>
      <!-- End of selected employees Information details -->
    </div>
    <!-- End of the right Sidebar -->

    <!-- Moving button for sidebar to open and close -->
    <div id="main" class="openbtn" @click="openNav()">
      <svg width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_0_1825)">
          <path
            d="M23 12L15.929 19.071L14.515 17.657L20.172 12L14.515 6.34296L15.929 4.92896L23 12ZM3.828 12L9.485 17.657L8.071 19.071L1 12L8.071 4.92896L9.485 6.34296L3.828 12Z"
            fill="black" />
        </g>
        <defs>
          <clipPath id="clip0_0_1825">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
    <!-- End of the moving butotn for sidebar to open and close -->

    <div class="ml-sidebar">
      <div id="mapContainer">
        <div class="map-full-screen">
          <button class="btn" @click="handleFullScreenMap">
            <i class="fas fa-expand"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet-arrowheads";

import SidenavteamMenu from "@/components/menu/SideNavbarTeam.vue";

import CompanyApi from "@/server/api/company";

export default {
  components: {
    SidenavteamMenu,
  },

  data() {
    return {
      markers: [],
      locationHistory: [],
      selectedEmployee: null,
      user: null,
    };
  },

  mounted() {
    this.user = {
      name: localStorage.getItem("fullname"),
      role: localStorage.getItem("role"),
      avatar: localStorage.getItem("avatar"),
    };
    this.initializedMap();
    this.getUserLocations();
    this.openNav();
  },

  methods: {
    async getUserLocations() {
      if (this.$route.params.id || false) {
        const companyApi = new CompanyApi();
        const response = await companyApi.getLocationByUser(
          this.$route.params.id
        );
        this.locationHistory = response.slice(0, 20);
        if (this.locationHistory.length > 0) {
          this.selectedEmployee = this.locationHistory[0].user || null;
        }
        this.createMarkers();
      }
    },

    initializedMap() {
      this.map = L.map("mapContainer").setView([23.8187676, 90.4314902], 12);
      L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        minZoom: 0,
        maxZoom: 22,
        maxNativeZoom: 18,
      }).addTo(this.map);
    },

    createMarkers() {
      // Clear any existing markers and polylines on the map and from the corresponding layers
      this.markerLayer?.clearLayers();
      this.polylineLayer?.clearLayers();
      this.markers = [];

      // Create a layer group to hold the markers and polylines
      if (!this.markerLayer) {
        this.markerLayer = L.layerGroup().addTo(this.map);
      }
      if (!this.polylineLayer) {
        this.polylineLayer = L.layerGroup().addTo(this.map);
      }

      const reversedLocationHistory = [...this.locationHistory].reverse(); // Reverse the location history array

      reversedLocationHistory.forEach((location, index) => {
        const lat = location.lat;
        const lng = location.long;
        const status = location?.travelling_status;

        const number = index + 1;

        let markerText;

        if (index === 0) {
          markerText = "Start"; // Set marker text as "Start" for the first response
        } else if (index === reversedLocationHistory.length - 1) {
          markerText = "End"; // Set marker text as "End" for the last response
        } else {
          markerText = index; // Use the index as the marker text for other responses
        }

        let markerColor = "#FFA500"; // Default orange color

        if (index === 0) {
          markerColor = "#FF0000"; // Red color for Start marker
        } else if (index === reversedLocationHistory.length - 1) {
          markerColor = "#00FF00"; // Green color for End marker
        } else if (status === "Idle") {
          markerColor = "#FFA500"; // Orange color for idel locations
        } else {
          markerColor = "#2EA5EA"; // Blue color for other markers
        }

        const defaultIcon = L.divIcon({
          html: `
                <div>
                <svg width="25" height="40" viewBox="0 0 35 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.5 0C7.825 0 0 7.825 0 17.5C0 30.625 17.5 50 17.5 50C17.5 50 35 
                    30.625 35 17.5C35 7.825 27.175 0 17.5 0Z" fill="${markerColor}"/>
                    <text x="50%" y="40%" dominant-baseline="middle" text-anchor="middle" fill="white" font-size="14">${markerText}</text>
                </svg>
                </div>
            `,
          className: "",
          iconSize: [24, 40],
          iconAnchor: [12, 40],
        });

        const marker = L.marker([lat, lng], { icon: defaultIcon }).addTo(
          this.markerLayer
        );
        const popupContent = `
        <div class="leafLetCard">
          <div class="mapLocationName">
            <div class="svgIconClass">
              <svg width="14" height="14" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C21.9939 17.5203 17.5203 21.9939 12 22ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C19.995 7.58378 16.4162 4.00496 12 4ZM17 13H11V7H13V11H17V13Z"
                  fill="#117abf" />
              </svg>
            </div>
            <div>
              ${formateDateTime(new Date(location.created_at))}
            </div>
          </div> 
            <div class="mapLocationName">
              <div class="svgIconClass">
                <svg width="15" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 23.728L5.636 17.364C4.37734 16.1053 3.52018 14.5017 3.17292 12.7558C2.82567 11.01 3.0039 9.20041 3.68509 7.55588C4.36629 5.91136 5.51984 4.50575 6.99988 3.51683C8.47992 2.5279 10.22 2.00006 12 2.00006C13.78 2.00006 15.5201 2.5279 17.0001 3.51683C18.4802 4.50575 19.6337 5.91136 20.3149 7.55588C20.9961 9.20041 21.1743 11.01 20.8271 12.7558C20.4798 14.5017 19.6227 16.1053 18.364 17.364L12 23.728ZM16.95 15.95C17.9289 14.971 18.5955 13.7237 18.8655 12.3659C19.1356 11.008 18.9969 9.60058 18.4671 8.32154C17.9373 7.0425 17.04 5.94929 15.8889 5.18015C14.7378 4.41101 13.3844 4.00049 12 4.00049C10.6156 4.00049 9.26221 4.41101 8.11108 5.18015C6.95995 5.94929 6.06275 7.0425 5.53291 8.32154C5.00308 9.60058 4.86441 11.008 5.13445 12.3659C5.40449 13.7237 6.0711 14.971 7.05 15.95L12 20.9L16.95 15.95ZM12 13C11.4696 13 10.9609 12.7893 10.5858 12.4142C10.2107 12.0391 10 11.5304 10 11C10 10.4696 10.2107 9.96084 10.5858 9.58577C10.9609 9.2107 11.4696 8.99998 12 8.99998C12.5304 8.99998 13.0391 9.2107 13.4142 9.58577C13.7893 9.96084 14 10.4696 14 11C14 11.5304 13.7893 12.0391 13.4142 12.4142C13.0391 12.7893 12.5304 13 12 13Z" fill="#117abf"/>
                </svg>
              </div>
              <div>
                ${this.getPoiString(location.poi)}
                <div>${lat || ''}, ${lng || ''}</div>
              </div>
            </div> 
        </div>`;
        marker.bindPopup(popupContent);

        location.marker = marker;
        location.defaultIcon = defaultIcon;

        this.markers.push(marker); // Store the marker in the markers array
      });

      // Create a polyline based on the marker locations
      if (this.markers.length > 1) {
        const latLngs = this.markers.map((marker) => marker.getLatLng());
        const polyline = L.polyline(latLngs, { color: "blue" })
          .arrowheads({
            frequency: "250px",
            size: "12px",
            fill: true,
          })
          .addTo(this.polylineLayer);
      }
    },

    flyToLocation(lat, lng) {
      this.map.flyTo([lat, lng], 17);
    },

    flyToLocationInMap(location) {
      this.map.flyTo([location.lat, location.long], 17);

      const marker = location.marker;
      marker.openPopup();
      marker.setZIndexOffset(99999);
    },

    openNav() {
      const sidebar = document.getElementById("mySidebar");
      const main = document.getElementById("main");
      // const topBar = document.getElementById("topBar");

      if (sidebar.style.width === "300px") {
        // If the sidebar is already open, close it
        sidebar.style.width = "0";
        main.style.marginRight = "0";
        // topBar.style.marginTop = "-300px";
      } else {
        // If the sidebar is closed, open it
        sidebar.style.width = "300px";
        main.style.marginRight = "300px";
        // topBar.style.marginTop = "20px";
      }
    },

    convertToHMS(duration) {
      const hours = Math.floor(duration / 3600);
      const minutes = Math.floor((duration % 3600) / 60);
      const seconds = duration % 60;
      return `${hours}h ${minutes}m ${seconds}s`;
    },
  },
};
</script>

<style scoped>
/* Right sidebar css */

.sidebar {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 5;
  top: 0;
  right: 0;
  background-color: #ffffff36;
  transition: 0.5s;
}

.sidebar .contentOfSidebar {
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  color: #f1f1f1;
}

.sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #0d6efd47;
  color: white;
  padding: 15px 5px;
  border: none;
  border-radius: 10px 0 10px 0;
  box-shadow: -4px 0px 10px 6px;
}

#main {
  transition: margin-right 0.5s;

  position: absolute;
  top: 50%;
  right: 0%;
  z-index: 5;
}

@media screen and (max-height: 350px) {
  .sidebar {
    padding-top: 15px;
  }

  .sidebar a {
    font-size: 18px;
  }
}

/* end of Right sidebar css */

/* Inside Rightbar Timeline Style */

.sessions,
.sessionsLi {
  list-style: none;
}

.sessionTIME {
  position: absolute;
  font-size: 12px;
  font-weight: bolder;
}

.sessionDistance {
  position: absolute;
  font-size: 10px;
  font-weight: bolder;
  height: 100%;
  margin-top: 43px;
  width: 60px;
  display: flex;
  margin-left: -2px;
  text-align: end;
  justify-content: end;
}

.red-text {
  display: none;
}

/* .timelineContainer {
  padding: 1rem;
  border-radius: 15px;
  background-color: rgb(250, 250, 250);
} */

.sessions {
  border-radius: 12px;
  position: relative;
}

.sessionsLi {
  padding-bottom: 50px;
  border-left: 4px solid #3f51b5;
  position: relative;
  padding-left: 20px;
  margin-left: 72px;

  &:last-child {
    border: 0px;
    padding-bottom: 0;
  }

  &:before {
    content: "";
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid #000000;

    border-radius: 50%;
    position: absolute;
    left: -13px;
    top: 0px;
  }
}

.sessionsCard {
  min-height: 50px;
  min-width: 180px;
  text-align: start;
  margin-left: -5px;
  color: #3f51b5;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  font-family: sans-serif;
  line-height: 1.5;
  font-size: 12px;
  z-index: 2;
  border-radius: 5px;
  margin-top: -31px;
  width: 100%;
  font-weight: bolder;
  box-shadow: 5px 5px 10px 1px #e6e6e6;
}

.idle_color {
  color: #ff5722 !important;
}

.pointyShape {
  height: 2px;
  width: 56px;
  background: #9e9e9e57;
  color: red;
  /* z-index: 20; */
  position: absolute;
  /* top: 190px; */
  margin-top: 9px;
  /* left: 511px; */
  right: 154px;
}

.numbericValues {
  position: absolute;
  height: 22px;
  width: 22px;
  margin-left: -52px;
  top: -1px;
  border-radius: 20px;
  background: #4caf50;
  font-weight: bolder;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

/* End of Inside Rightbar Timeline Style */

/* Top right Profile information */
.topNavTeam {
  right: 0%;
  z-index: 3;
}

/* End of Top right Profile information */

#mapContainer {
  width: 100vw;
  height: 100vh;
  z-index: 1;
}

.sideNavTeam {
  position: absolute;
  left: 0%;
  z-index: 3;
}

.sideNavTeamFirst {
  height: 100vh;
  width: 408px;
  background-color: rgba(255, 255, 255);
  box-shadow: 1px 2px #eeeeee;
}

.topNavTeam {
  right: 0%;
  z-index: 3;
}

.sideNavTeamElement {
  display: flex;
  align-items: center;
  height: 100vh;
  margin-left: -10px;
  z-index: -1;
}

.topNavTeam {
  right: 0%;
}

.topBarEmployeeStatus {
  position: fixed;
  width: 200px;
  top: 2%;
  left: 66%;
  z-index: 1;
  margin-top: -300px;
  transition: 0.3s;
  background-color: white;
}

.secondcontainerCard {
  position: absolute;
  /* Position the container div absolutely */
  top: 20%;
  /* Shift it down by 50% of the wrapper height */
  left: 20%;
  z-index: 2;
  opacity: 0.95;

  /* Vertically center the container div */
}

.employeeContainerCard {
  height: 92vh;
}

.nameCardContainer {
  font-size: 13px;
  text-align: start;
  font-weight: bolder;

  background: #cac9c9;
}

.cardDetailsContainer {
  font-size: 13px;
  text-align: start;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.cardDetailsContainerLocation {
  font-size: 13px;
  text-align: start;
}

.latlongCardMyTeam {
  font-size: 12px;
}

.inputCard {
  background: transparent !important;
  border: none;
  outline: none;
}

th {
  background: #1fa8e1 !important;
}

th,
td {
  text-align: left;
  padding: 8px;
}

.btn-blue {
  background: #1fa8e1 !important;
}
</style>
