<template>
    <div>
        <div class="h-[100vh] w-[100px] absolute hover:w-[200px]">
            <SidenavteamMenu />
        </div>

        <HeaderNavbar title="Task Report" />

        <div class="ml-sidebar">
            <div class="">
                <div class="mx-4 my-2">
                    <div class="row">
                        <div class="col-md-3">
                            <VueDatePicker @closed="handleDateTimeRange" v-model="dates" range :partial-range="false"
                                :enableTimePicker="true" :placeholder="datePlaceholder"
                                :min-date="new Date(Date.now() - 3888000000)" ref="datepicker">
                                <template #left-sidebar>
                                    <div style="min-width: 100px;">
                                        <button type="button" class="btn btn-sm btn-outline-primary w-100"
                                            @click="pickToday">
                                            Today
                                        </button>
                                        <button type="button" class="btn btn-sm btn-outline-primary-rev w-100 mt-1"
                                            @click="pickYesterday">
                                            Yesterday
                                        </button>
                                        <button type="button" class="btn btn-sm btn-outline-primary-rev w-100 mt-1"
                                            @click="pickLast7Days">
                                            Last 7 Days
                                        </button>
                                        <button type="button" class="btn btn-sm btn-outline-primary-rev w-100 mt-1"
                                            @click="pickLast30Days">
                                            Last 30 Days
                                        </button>
                                    </div>
                                </template>
                            </VueDatePicker>
                        </div>
                        <div class="col-md-9 text-end">
                            <div>
                                <button type="button" class="btn btn-outline-primary-rev px-4 py-1" @click="exportToExcel"
                                    :disabled="isExporting">
                                    Export
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mx-4 mt-2 mb-4">
                    <hr>
                    <div class="row text-start my-2">
                        <div class="col-md-2">
                            <h2 class="text-xl">
                                Total Task <span class="text-info fw-semi-bold ml-2" style="font-size: 28px">{{
                                    taskReport.length }}</span>
                            </h2>
                        </div>
                        <div class="col-md-2">
                            <h2 class="text-xl">
                                Total Assigned <span class="text-info fw-semi-bold ml-2" style="font-size: 28px">
                                    {{ taskReport.reduce((acc, cur) => acc + cur.task_assigns_list.length, 0) }}
                                </span>
                            </h2>
                        </div>
                        <div class="col-md-2">
                            <h2 class="text-xl">
                                Pending <span class="text-info fw-semi-bold ml-2" style="font-size: 28px">
                                    {{ taskReport.reduce((acc, cur) => {
                                        const temp = cur.task_assigns_list.reduce((a, c) => {
                                            let i = 0;
                                            if (c.task_status == 'Pending') {
                                                i++
                                            }
                                            return a + i;
                                        }, 0)
                                        return acc + temp;
                                    }, 0) }}
                                </span>
                            </h2>
                        </div>
                        <div class="col-md-2">
                            <h2 class="text-xl">
                                On Progress <span class="text-info fw-semi-bold ml-2" style="font-size: 28px">
                                    {{ taskReport.reduce((acc, cur) => {
                                        const temp = cur.task_assigns_list.reduce((a, c) => {
                                            let i = 0;
                                            if (c.task_status == 'OnProgess') {
                                                i++
                                            }
                                            return a + i;
                                        }, 0)
                                        return acc + temp;
                                    }, 0) }}
                                </span>
                            </h2>
                        </div>
                        <div class="col-md-2">
                            <h2 class="text-xl">
                                Completed <span class="text-info fw-semi-bold ml-2" style="font-size: 28px">
                                    {{ taskReport.reduce((acc, cur) => {
                                        const temp = cur.task_assigns_list.reduce((a, c) => {
                                            let i = 0;
                                            if (c.task_status == 'Completed') {
                                                i++
                                            }
                                            return a + i;
                                        }, 0)
                                        return acc + temp;
                                    }, 0) }}
                                </span>
                            </h2>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="mx-4 report-table mt-2">
                    <div class="appscroll" style="height: 62vh;">
                        <table class="table table-poi table-bordered " id="task-report-table">
                            <thead class="position-sticky top-0 text-xs">
                                <tr class="text-start">
                                    <th>SL</th>
                                    <th style="min-width: 100px;">Date</th>
                                    <th>ID</th>
                                    <th>Priority</th>
                                    <th style="max-width: 500px; min-width: 230px;">Task Details</th>
                                    <th style="min-width: 70px;">Emp ID</th>
                                    <th style="min-width: 100px;">Emp Name</th>
                                    <th>Emp Mobile No</th>
                                    <th>Department</th>
                                    <th>Designation</th>
                                    <th>Status</th>
                                    <th style="max-width: 500px; min-width: 180px;">Update Details</th>
                                    <th style="min-width: 80px;">Update At</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-if="taskReport.length > 0">
                                    <template v-for="(item, index) in taskReport" :key="item.id">
                                        <tr>
                                            <td
                                                :rowspan="item.task_assigns_list.length > 0 ? item.task_assigns_list.length : ''">
                                                {{ index + 1 }}</td>
                                            <td :rowspan="item.task_assigns_list.length > 0 ? item.task_assigns_list.length : ''"
                                                style="min-width: 100px;">
                                                {{ formateDate(item.task_date) }}
                                            </td>
                                            <td
                                                :rowspan="item.task_assigns_list.length > 0 ? item.task_assigns_list.length : ''">
                                                {{ item.task_id }}</td>
                                            <td
                                                :rowspan="item.task_assigns_list.length > 0 ? item.task_assigns_list.length : ''">
                                                <div class="fw-bold" :class="{
                                                    'text-danger': item.task_priority == 'High',
                                                    'text-success': item.task_priority == 'Low',
                                                    'text-warning': item.task_priority == 'Medium',
                                                }">
                                                    {{ item.task_priority }}
                                                </div>
                                            </td>
                                            <td :rowspan="item.task_assigns_list.length > 0 ? item.task_assigns_list.length : ''"
                                                style="max-width: 500px; min-width: 230px;">
                                                {{ item.task_name }}:
                                                {{ item.task_details }}
                                            </td>

                                            <template v-if="item.task_assigns_list.length > 0">
                                                <td>{{ item.task_assigns_list[0].employee_id }}</td>
                                                <td>{{ item.task_assigns_list[0].employee_name }}</td>
                                                <td>{{ item.task_assigns_list[0].employee_mobile_number }}</td>
                                                <td>{{ item.task_assigns_list[0].employee_department }}</td>
                                                <td>{{ item.task_assigns_list[0].employee_designation }}</td>
                                                <td>
                                                    <div class="fw-bold" :class="{
                                                        'text-danger': item.task_assigns_list[0].task_status == 'Pending',
                                                        'text-success': item.task_assigns_list[0].task_status == 'Completed',
                                                        'text-info': item.task_assigns_list[0].task_status == 'Assigned',
                                                    }">
                                                        {{ item.task_assigns_list[0].task_status }}
                                                    </div>
                                                </td>
                                                <td>{{ item.task_assigns_list[0].task_update }}</td>
                                                <td style="min-width: 100px;">{{
                                                    formateDate(item.task_assigns_list[0].task_updated_at) }}</td>
                                                <td>
                                                    <a :href="`https://www.google.com/maps/place/${item.task_assigns_list[0].task_update_lat},${item.task_assigns_list[0].task_update_lon}`"
                                                        target="_blank" class="text-primary">
                                                        <i class="fa fa-map-pin"></i>
                                                    </a>
                                                </td>
                                            </template>
                                        </tr>
                                        <template v-if="item.task_assigns_list.length > 1">
                                            <template v-for="(taskAssign, index) in item.task_assigns_list">
                                                <tr v-if="index != 0">
                                                    <td>{{ taskAssign.employee_id }}</td>
                                                    <td>{{ taskAssign.employee_name }}</td>
                                                    <td>{{ taskAssign.employee_mobile_number }}</td>
                                                    <td>{{ taskAssign.employee_department }}</td>
                                                    <td>{{ taskAssign.employee_designation }}</td>
                                                    <td>
                                                        <div class="fw-bold" :class="{
                                                            'text-danger': taskAssign.task_status == 'Pending',
                                                            'text-success': taskAssign.task_status == 'Completed',
                                                            'text-info': taskAssign.task_status == 'Assigned',
                                                        }">
                                                            {{ taskAssign.task_status }}
                                                        </div>
                                                    </td>
                                                    <td style="max-width: 500px; min-width: 180px;">
                                                        {{ taskAssign.task_update }}
                                                    </td>
                                                    <td style="min-width: 80px;">
                                                        {{ formateDate(taskAssign.task_updated_at) }}
                                                    </td>
                                                    <td>
                                                        <a :href="`https://www.google.com/maps/place/${taskAssign.task_update_lat},${taskAssign.task_update_lon}`"
                                                            target="_blank" class="text-primary">
                                                            <i class="fa fa-map-pin"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </template>
                                        </template>
                                    </template>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td colspan="14">
                                            <div class="my-3 text-start">No data available in table</div>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>

                </div>
                <div class="mx-4 my-3">
                    <div class="d-flex justify-content-between">
                        <div>
                            <div class="form-group">
                                <div class="input-group">
                                    Showing
                                    <select @change="handlePageLimit" class="border mx-2" :value="pageLimit">
                                        <option value="10">10</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="500">500</option>
                                    </select> of {{ totalCount }} entries
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end">
                            <div class="mr-2">
                                <button @click="fetchPageData('previous')" :disabled="pagination.prev == ''"
                                    class="btn btn-outline-primary">
                                    Previous
                                </button>
                            </div>
                            <div>
                                <button @click="fetchPageData('next')" :disabled="pagination.next == ''"
                                    class="btn btn-outline-primary">
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SidenavteamMenu from "@/components/menu/SideNavbarTeam.vue";
import HeaderNavbar from "@/components/menu/headerdashboard.vue";
import apis from "@/server/api/company/index";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import XLSX from "xlsx-js-style";

export default {
    components: { SidenavteamMenu, HeaderNavbar, VueDatePicker },

    data() {
        return {
            totalCount: null,
            pageUrl: null,
            pageLimit: 100,
            startingDate: null,
            endingDate: null,
            exportedTaskReport: [],
            taskReport: [],
            dates: null,
            datePlaceholder: 'Select Date',
            pagination: {
                next: '',
                prev: '',
            },
            isExporting: false,
        }
    },

    methods: {
        async getTaskReport() {
            const response = await apis.getTaskReport({
                startingDate: this.startingDate,
                endingDate: this.endingDate,
                limit: this.pageLimit,
                url: this.pageUrl,
            });
            this.taskReport = response.results.content;
            this.pagination.next = response.next || response.links?.next || '';
            this.pagination.prev = response.previous || response.links?.previous || '';
            this.totalCount = response.count;
        },

        handlePageLimit(e) {
            this.pageLimit = e.target.value;
            this.getTaskReport();
        },

        formateDate(date) {
            return formateDateTime(new Date(date))
        },

        async handleDateTimeRange() {
            if (!this.dates) {
                return;
            }
            try {
                this.startingDate = this.dates[0].toISOString();
                this.endingDate = this.dates[1].toISOString();
                this.getTaskReport();
            } catch (_) {
                return;
            }
        },

        fetchPageData(status) {
            if (status == 'previous' && this.pagination.prev != '') {
                this.pageUrl = this.pagination.prev;
                this.getTaskReport();
            } else if (status == 'next' && this.pagination.next != '') {
                this.pageUrl = this.pagination.next;
                this.getTaskReport();
            }
        },

        pickToday() {
            this.datePlaceholder = 'Today';
            this.startingDate = new Date().toISOString();
            this.endingDate = new Date().toISOString();
            this.getTaskReport();
            this.$refs.datepicker.closeMenu();
        },

        pickYesterday() {
            this.datePlaceholder = 'Yesterday';
            const now = new Date();
            this.startingDate = new Date(now.setDate(now.getDate() - 1)).toISOString();
            this.endingDate = new Date().toISOString();
            this.getTaskReport();
            this.$refs.datepicker.closeMenu();
        },

        pickLast7Days() {
            this.datePlaceholder = 'Last 7 Days';
            const now = new Date();
            this.startingDate = new Date(now.setDate(now.getDate() - 7)).toISOString();
            this.endingDate = new Date().toISOString();
            this.getTaskReport();
            this.$refs.datepicker.closeMenu();
        },

        pickLast30Days() {
            this.datePlaceholder = 'Last 30 Days';
            const now = new Date();
            this.startingDate = new Date(now.setDate(now.getDate() - 30)).toISOString();
            this.endingDate = new Date().toISOString();
            this.getTaskReport();
            this.$refs.datepicker.closeMenu();
        },

        async exportToExcel() {
            this.isExporting = true;
            this.$toast.info('Loading ...', {
                position: "top",
                duration: false,
            });

            const response = await apis.getTaskReport({
                startingDate: this.startingDate,
                endingDate: this.endingDate,
                limit: this.totalCount,
                url: this.pageUrl,
            });
            this.exportedTaskReport = response.results.content || [];

            if (this.exportedTaskReport.length == 0) {
                this.isExporting = false;
                this.$toast.error('No Data Found', {
                    position: "top",
                });
                return
            }

            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet([]);
            XLSX.utils.sheet_add_json(ws, [{
                header: {
                    v: 'FieldForce  Task Report',
                    s: {
                        font: { bold: true, sz: 18 },
                        alignment: { horizontal: "center" },
                    }
                }
            }], { skipHeader: true, origin: 0 });

            const cellStyles = {
                font: { sz: 10 },
                alignment: { vertical: "center", horizontal: "center", wrapText: true },
            }
            const headerCellStyles = {
                ...cellStyles,
                fill: { fgColor: { rgb: "9bc2e6" } },
                border: {
                    top: { style: 'thin', color: { rgb: '00000000' } },
                    bottom: { style: 'thin', color: { rgb: '00000000' } },
                    left: { style: 'thin', color: { rgb: '00000000' } },
                    right: { style: 'thin', color: { rgb: '00000000' } }
                }
            };

            XLSX.utils.sheet_add_aoa(ws, [[
                { v: 'Sr. No.', s: headerCellStyles },
                { v: 'Date', s: headerCellStyles },
                { v: 'Task ID', s: headerCellStyles },
                { v: 'Task Priority', s: headerCellStyles },
                { v: 'Task Details', s: headerCellStyles },
                { v: 'Employee ID', s: headerCellStyles },
                { v: 'Employee Name', s: headerCellStyles },
                { v: 'Emp Mobile No.', s: headerCellStyles },
                { v: 'Employee Department', s: headerCellStyles },
                { v: 'Employee Designation', s: headerCellStyles },
                { v: 'Task Status', s: headerCellStyles },
                { v: 'Update Details', s: headerCellStyles },
                { v: 'Task Updated Date & Time', s: headerCellStyles },
                { v: 'Task Update Location', s: headerCellStyles },
            ]], { origin: -1 });

            let cellMerges = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 13 } }];

            let lastRowMerge = 1;
            this.exportedTaskReport.forEach((item, index) => {

                const rows = [];

                const row = [
                    { v: (index + 1), s: cellStyles },
                    { v: this.formateDate(item.task_date), s: cellStyles },
                    { v: item.task_id, s: cellStyles },
                    { v: item.task_priority, s: cellStyles },
                    { v: `${item.task_name}: ${item.task_details}`, s: cellStyles },
                ];

                const tempEndRow = lastRowMerge + (item.task_assigns_list.length || 1);
                cellMerges = [
                    ...cellMerges,
                    { s: { r: lastRowMerge + 1, c: 0 }, e: { r: tempEndRow, c: 0 } },
                    { s: { r: lastRowMerge + 1, c: 1 }, e: { r: tempEndRow, c: 1 } },
                    { s: { r: lastRowMerge + 1, c: 2 }, e: { r: tempEndRow, c: 2 } },
                    { s: { r: lastRowMerge + 1, c: 3 }, e: { r: tempEndRow, c: 3 } },
                    { s: { r: lastRowMerge + 1, c: 4 }, e: { r: tempEndRow, c: 4 } },
                ]
                lastRowMerge = tempEndRow;

                if (item.task_assigns_list.length > 0) {
                    const taskAssign = item.task_assigns_list[0] || false;
                    if (taskAssign) {
                        row.push(
                            { v: taskAssign.employee_id, s: cellStyles },
                            { v: taskAssign.employee_name, s: cellStyles },
                            { v: taskAssign.employee_mobile_number, s: cellStyles },
                            { v: taskAssign.employee_department, s: cellStyles },
                            { v: taskAssign.employee_designation, s: cellStyles },
                            { v: taskAssign.task_status, s: cellStyles },
                            { v: taskAssign.task_update || '', s: cellStyles },
                            { v: this.formateDate(taskAssign.task_updated_at), s: cellStyles },
                            {
                                v: 'View On Map', s: cellStyles,
                                l: { Target: `https://www.google.com/maps/place/${taskAssign.task_update_lat},${taskAssign.task_update_lon}` }
                            },
                        );
                    }

                    rows.push(row);

                    for (let i = 1; i < item.task_assigns_list.length; i++) {
                        const taskAssign = item.task_assigns_list[i];
                        if (taskAssign) {
                            rows.push([
                                '', '', '', '', '',
                                { v: taskAssign.employee_id, s: cellStyles },
                                { v: taskAssign.employee_name, s: cellStyles },
                                { v: taskAssign.employee_mobile_number, s: cellStyles },
                                { v: taskAssign.employee_department, s: cellStyles },
                                { v: taskAssign.employee_designation, s: cellStyles },
                                { v: taskAssign.task_status, s: cellStyles },
                                { v: taskAssign.task_update, s: cellStyles },
                                { v: this.formateDate(taskAssign.task_updated_at), s: cellStyles },
                                {
                                    v: 'View On Map', s: cellStyles,
                                    l: { Target: `https://www.google.com/maps/place/${taskAssign.task_update_lat},${taskAssign.task_update_lon}` }
                                },
                            ])
                        }
                    }
                } else {
                    rows.push(row);
                }

                XLSX.utils.sheet_add_aoa(ws, rows, { origin: -1 });
            });

            ws['!merges'] = [...cellMerges];


            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, 'task-report.xlsx', { bookType: 'xlsx', type: 'binary' });
            this.isExporting = false;
            this.$toast.clear();
            this.$toast.success('Successfully Exported', {
                position: "top",
            });
        }
    },

};
</script>
  