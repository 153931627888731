import TasksView from "@/views/company/task/TaskView.vue";
import TaskAssignsView from "@/views/company/task/AssignedTaskView.vue";

export default [
    {
        path: "/task",
        name: "Task",
        meta: {
            key: 'task',
            title: 'Task',
            auth: true,
        },
        component: TasksView
    },
    {
        path: "/assigned-task/:id",
        name: "Assigned-Task",
        meta: {
            key: 'task',
            title: 'Assigned Task',
            auth: true,
        },
        component: TaskAssignsView
    },
];