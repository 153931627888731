<template>
  <div style="background: rgba(128, 128, 128, 0.1);">
    <div class="container">
      <div class="header-items">
        <!-- Your content here -->
        {{ this.title || "Dashboard" }}
      </div>
      <!-- <div class="header-items-profile flex items-center justify-center">
        <div class="text-center flex justify-between items-center">
          <img :src="`${user?.avatar}`" class="w-10 h-10 ml-3 rounded-3xl mx-2" alt="" />
          <div class="vl"></div>
          <div>Hello,<span class="spaceContainer"></span>{{ user?.name }}</div>
        </div>
      </div> -->
      <div>
        <div class="dropdown">
          <a class="dropdown-toggle d-flex align-items-center" href="#" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            <div class="d-flex align-items-center">
              <div class="mr-2">Hello,<span class="spaceContainer"></span>{{ user?.name }}</div>
              <img class="w-10 h-10 ml-3 rounded-3xl mx-2" :src="`${user?.avatar}`" alt="" />
            </div>
          </a>
          <ul class="dropdown-menu">
            <li>
              <router-link to="/account/change-password" class="dropdown-item" href="#">Change Password</router-link>
            </li>
            <li>
              <a @click="logoutUser" class="dropdown-item" href="#">Logout</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- <div class="w-full border-b-2 border-gray-300"></div> -->
  </div>
</template>

<style scoped>
.container {
  margin-left: 80px;
  padding: 10px 20px;
  width: 100%;
  height: auto;
  max-width: 92%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-items {
  font-size: 20px;
  color: rgb(102, 201, 250);
}

.header-items-profile {
  display: flex;
  font-size: 14px;
  text-align: center;
  color: rgb(169, 169, 169);
}

.vl {
  border-left: 0.5px solid rgb(189, 186, 186);
  margin-right: 20px;
  height: 20px;
}

.spaceContainer {
  padding-left: 9px;
}
</style>
<script>
import AuthApi from "@/server/auth/auth";
import BurgerMenu from "../ui/BurgerMenu.vue";

export default {
  props: ["title"],

  components: { BurgerMenu },
  data() {
    return {
      user: null,
      authApi: new AuthApi(),
    };
  },
  mounted() {
    this.user = {
      name: localStorage.getItem("fullname"),
      role: localStorage.getItem("role"),
      avatar: localStorage.getItem("avatar"),
    };
    console.log(this.user);
  },
  methods: {
    logoutUser() {
      this.authApi.logoutApi().then((res) => {
        console.log(res, "logout");
        this.$router.push("/login");
      });
    },
  },
};
</script>
