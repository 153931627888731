<template>
    <div>
        <div class="h-[100vh] w-[100px] absolute hover:w-[200px]">
            <SidenavteamMenu />
        </div>

        <HeaderNavbar title="Error Log Report" />

        <div class="ml-sidebar">
            <div class="">
                <div class="mx-4 my-2">
                    <div class="row">
                        <div class="col-md-9">
                            <div class="row">
                                <!-- <div class="col-md-3">
                                    <input type="date" v-model="date">
                                </div> -->
                                <div class="col-md-5">
                                    <div class="d-flex">
                                        <input type="text" v-model="search" class="form-control mx-2" placeholder="search">
                                        <div>

                                            <button type="button" class="btn btn-outline-primary-rev px-4 py-1"
                                                @click="getErrorLogReport">
                                                Search</button>
                                        </div>
                                    </div>
                                    <div class="text-start">
                                            <span class="text-danger">Search example: 2024-01-01 / hasan /
                                                01XXXXX</span>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mx-4 report-table mt-2">
                    <div class="appscroll" style="height: 75vh;">
                        <table class="table table-poi table-bordered " id="task-report-table">
                            <thead class="position-sticky top-0 text-xs">
                                <tr class="text-start">
                                    <th>SL</th>
                                    <th>User Name</th>
                                    <th>User Phone</th>
                                    <th>Code</th>
                                    <th>Exception</th>
                                    <th>Screen Name</th>
                                    <th>Battery Level</th>
                                    <th>Created At</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-if="errorLogReport.length > 0">
                                    <tr v-for="(item, index) in errorLogReport" :key="item.id">
                                        <td>{{ index + 1 }}</td>
                                        <td>{{ item.user_details?.user_name || '' }}</td>
                                        <td>{{ item.user_details?.user_phone || '' }}</td>
                                        <td>{{ item.exception_code }}</td>
                                        <td>{{ item.error_exception }}</td>
                                        <td>{{ item.screen_name }}</td>
                                        <td>{{ item.battery_level }}</td>
                                        <td>{{ formateDate(item.created_at) }}</td>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td colspan="14">
                                            <div class="my-3 text-start">No data available in table</div>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>

                </div>
                <div class="mx-4 my-3">
                    <div class="d-flex justify-content-between">
                        <div>
                            <div class="form-group">
                                <div class="input-group">
                                    Showing
                                    <select @change="handlePageLimit" class="border mx-2" :value="pageLimit">
                                        <option value="10">10</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="500">500</option>
                                    </select> of {{ totalCount }} entries
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end">
                            <div class="mr-2">
                                <button @click="fetchPageData('previous')" :disabled="pagination.prev == ''"
                                    class="btn btn-outline-primary">
                                    Previous
                                </button>
                            </div>
                            <div>
                                <button @click="fetchPageData('next')" :disabled="pagination.next == ''"
                                    class="btn btn-outline-primary">
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SidenavteamMenu from "@/components/menu/SideNavbarTeam.vue";
import HeaderNavbar from "@/components/menu/headerdashboard.vue";
import apis from "@/server/api/company/index";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import XLSX from "xlsx-js-style";
import FilterButton from "@/components/report/FilterButton.vue";

import { mapActions } from "vuex";

export default {
    components: { SidenavteamMenu, HeaderNavbar, VueDatePicker, FilterButton },

    data() {
        return {
            totalCount: null,
            pageUrl: null,
            pageLimit: 100,
            startingDate: null,
            endingDate: null,
            errorLogReport: [],
            date: null,
            search: '',
            datePlaceholder: 'Select Date',
            pagination: {
                next: '',
                prev: '',
            },
        }
    },

    methods: {
        ...mapActions("report", ["getErrorLogReportData", "resetReportFilter"]),

        async getErrorLogReport() {
            const response = await this.getErrorLogReportData({
                startingDate: this.date,
                endingDate: this.endingDate,
                limit: this.pageLimit,
                url: this.pageUrl,
                search: this.search,
            });
            this.errorLogReport = response.results;
            this.pagination.next = response.next || response.links?.next || '';
            this.pagination.prev = response.previous || response.links?.previous || '';
            this.totalCount = response.count;
        },

        handlePageLimit(e) {
            this.pageLimit = e.target.value;
            this.getErrorLogReport();
        },

        formateDate(date) {
            return formateDateTime(new Date(date))
        },

        async handleDateTimeRange() {
            if (!this.dates) {
                return;
            }
            try {
                this.startingDate = this.dates[0].toISOString();
                this.endingDate = this.dates[1].toISOString();
                this.getErrorLogReport();
            } catch (_) {
                return;
            }
        },

        fetchPageData(status) {
            if (status == 'previous' && this.pagination.prev != '') {
                this.pageUrl = this.pagination.prev;
                this.getErrorLogReport();
            } else if (status == 'next' && this.pagination.next != '') {
                this.pageUrl = this.pagination.next;
                this.getErrorLogReport();
            }
        },

        pickToday() {
            this.datePlaceholder = 'Today';
            this.startingDate = new Date().toISOString();
            this.endingDate = new Date().toISOString();
            this.getErrorLogReport();
            this.$refs.datepicker.closeMenu();
        },

        pickYesterday() {
            this.datePlaceholder = 'Yesterday';
            const now = new Date();
            this.startingDate = new Date(now.setDate(now.getDate() - 1)).toISOString();
            this.endingDate = new Date().toISOString();
            this.getErrorLogReport();
            this.$refs.datepicker.closeMenu();
        },

        pickLast7Days() {
            this.datePlaceholder = 'Last 7 Days';
            const now = new Date();
            this.startingDate = new Date(now.setDate(now.getDate() - 7)).toISOString();
            this.endingDate = new Date().toISOString();
            this.getErrorLogReport();
            this.$refs.datepicker.closeMenu();
        },

        pickLast30Days() {
            this.datePlaceholder = 'Last 30 Days';
            const now = new Date();
            this.startingDate = new Date(now.setDate(now.getDate() - 30)).toISOString();
            this.endingDate = new Date().toISOString();
            this.getErrorLogReport();
            this.$refs.datepicker.closeMenu();
        },

        toggleDropDown() {
            let toggleDropdown = document.getElementById("dropdownSearch");
            toggleDropdown.classList.toggle("hidden");
        },

        resetFilter() {
            this.errorLogReport = [];
            this.pagination.next = '';
            this.pagination.prev = '';
            this.totalCount = 0;
            this.resetReportFilter();
            const dom = document.getElementById('dropdownSearch');
            const domElem = dom.getElementsByClassName('form-check-input');
            for (let i = 0; i < (domElem.length || 0); i++) {
                domElem[i].checked = false;
            }
            this.toggleDropDown();
        },

    },

};
</script>
  