<template>
    <div class="">
        <div class="h-[100vh] w-[100px] absolute hover:w-[200px]">
            <SidenavteamMenu />
        </div>

        <HeaderNavbar title="Reports" />

        <div class="ml-sidebar">
            <div class="h-screen">
                <div class="mx-4 app-table mt-5">
                    <div class="row text-start mb-3">
                        <h2 class="fw-bold text-info mb-3" style="font-size: 22px;">Select report for analysis</h2>
                        <p>This report furnishes statistics about selected customer for selected month range regarding
                            monthwise changes in total number of employees, SMS usage, revenue earned along with complete
                            details of employees on current date.</p>
                    </div>
                    <div class="row">
                        <div class="col-md-4 py-1" v-for="report in reports" :key="report.id">
                            <router-link :to="{ name: report.route || 'home' }">
                                <div class="card shadow-md">
                                    <div class="card-header" style="background: #117abf;">
                                        <h5 class="card-title text-light">{{ report.title }}</h5>
                                    </div>
                                    <div class="card-body" style="min-height: 120px;">
                                        <p class="">{{ report.description || '' }}</p>
                                    </div>
                                    <div class="card-footer">
                                        <button type="button" class="btn btn-sm btn-outline-primary btn-option-blue">
                                            View Report
                                        </button>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import SidenavteamMenu from "@/components/menu/SideNavbarTeam.vue";
import HeaderNavbar from "@/components/menu/headerdashboard.vue";

export default {
    components: { SidenavteamMenu, HeaderNavbar },

    data() {
        return {
            reports: [
                {
                    id: 1,
                    title: 'Track History Report',
                    description: 'Track-history report shows you where your employees have been at all times by showing you the "Location Summary".',
                    route: 'user-location-report'
                },
                {
                    id: 2,
                    title: 'Task Report',
                    description: 'This report shows you all the task of an employee by viewing "Task Summary".',
                    route: 'company-task-report'
                },
                {
                    id: 3,
                    title: 'Attendance Report',
                    description: 'This report shows Attendance Report for particular time duration.',
                    route: 'company-attendance-report'
                },
                {
                    id: 4,
                    title: 'Error Log Report',
                    description: 'This report shows Error Log for particular user.',
                    route: 'company-error-log-report'
                },
                {
                    id: 5,
                    title: 'Device Log Report',
                    description: 'This report shows Device Log for particular user.',
                    route: 'company-device-log-report'
                },
                {
                    id: 6,
                    title: 'Punch In Out Report',
                    description: 'This report shows Punch In Out for particular user.',
                    route: 'company-punch-in-out-report'
                },
                {
                    id: 7,
                    title: 'Geofench Violation Report',
                    description: 'This report shows Geofench Violation',
                    route: 'company-geofench-violation-report'
                }
            ]
        }
    },

};
</script>
  