import { createRouter, createWebHistory } from "vue-router";
import AuthApi from "@/server/auth/auth";
// import pages here
import Dashboard from "../views/company/dashboard/Dashboard";

import companyRoutes from "./company/index";

import store from "@/store";

const routes = [
  {
    path: "/login",
    name: "login",
    meta: {
      key: 'login',
      title: 'Login',
      auth: false,
    },
    component: () => import("../views/auth/login/login.vue"),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("user")) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/account/change-password",
    name: "account-change-password",
    meta: {
      key: 'change-password',
      title: 'Change Password',
      auth: true,
    },
    component: () => import("../views/auth/profile/ChangePassword.vue"),
    beforeEnter: (_, __, next) => {
      if (localStorage.getItem("user")) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/",
    name: "home",
    meta: {
      key: 'dashboard',
      title: 'Dashboard',
      auth: true,
    },
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      const auth = new AuthApi();
      auth.validateUser().then((res) => {
        if (res) {
          if (localStorage.getItem("role") === "Company") {
            next();
          } else {
            next("/");
          }
        } else {
          next("/login");
        }
      });
    },
  },

  ...companyRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, _, next) => {
  if (to.meta.auth || false) {
    const auth = new AuthApi();
    auth.validateUser().then((res) => {
      if (res) {
        if (localStorage.getItem("role") === "Company") {
          store.dispatch("core/onChangeSidebarMenu", { key: to.meta.key || '' }, { root: true })
          next();
        } else {
          next("/");
        }
      } else {
        next("/login");
      }
    });
  } else {
    next();
  }
});

export default router;
