<template>
  <div class="">
    <div class="h-[100vh] w-[100px] absolute hover:w-[200px]">
      <SidenavteamMenu />
    </div>

    <HeaderNavbar title="POI" />

    <div class="ml-sidebar">
      <div class="mx-4 my-3">
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center">
            <h3 class="mb-0 mr-2 fw-bold">Map View</h3>
            <toggle-button @change="toggleMapView" />
          </div>
          <json-excel class="btn btn-outline-primary-rev px-4 py-1" :data="mapPois" name="company-poi">
            Export
          </json-excel>
        </div>
      </div>
      <div v-if="loading">
        Loading...
      </div>
      <div v-else>
        <div v-if="mapView" class="mx-4 my-3">
          <PoiMapView :pois="pois" />
        </div>
        <div v-else class="mx-4 app-table my-2">
          <PoiTableView :pois="pois" @init="init" />
        </div>
        <div class="mx-4 my-3">
          <div class="d-flex justify-content-between">
            <div>
              <div class="form-group">
                <div class="input-group">
                  Showing {{ pois.length || '0' }} of {{ data.count }} entries
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <div class="mr-2">
                <button @click="fetchPageData('previous')" :disabled="data.prev == ''" class="btn btn-outline-primary">
                  Previous
                </button>
              </div>
              <div>
                <button @click="fetchPageData('next')" :disabled="data.next == ''" class="btn btn-outline-primary">
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="mx-4 mb-5">
          <div class="d-flex justify-content-between align-items-center">
            <router-link :to="{ name: 'company-poi-create' }" class="btn text-button">
              <i class="fa fa-plus text-info"></i> Create POI
            </router-link>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import SidenavteamMenu from "@/components/menu/SideNavbarTeam.vue";
import HeaderNavbar from "@/components/menu/headerdashboard.vue";
import PoiTableView from "./PoiTableView.vue";
import PoiMapView from "./PoiMapView.vue";
import apis from "@/server/api/company/index";

import JsonExcel from "vue-json-excel3";

export default {
  components: { SidenavteamMenu, HeaderNavbar, PoiTableView, PoiMapView, JsonExcel },

  data: () => ({
    loading: false,
    mapView: false,
    data: {},
    pois: [],
    mapPois: [],
    pageUrl: '',
  }),

  mounted() {
    this.init();
  },

  methods: {
    async init() {
      this.loading = true;
      this.getPois();
      this.loading = false;
    },

    async getPois() {
      this.data = await apis.getPois(this.pageUrl);
      this.pois = this.data.results;
      this.mapPois = this.pois.map((poi) => ({
        'Name': poi.name,
        'Address': poi.address,
        'Landmark': poi.landmark,
        'Latitude Longitude': `${poi.lat}, ${poi.long}`,
        'Category': poi.poi_category_details?.name || '',
        'Union': poi.union,
        'Thana': poi.thana,
        'District': poi.district,
        'Division': poi.division,
      }));
    },

    toggleMapView() {
      this.mapView = !this.mapView;
    },

    fetchPageData(status) {
      if (status == 'previous' && this.data.prev != '') {
        this.pageUrl = this.data.prev;
        this.getPois();
      } else if (status == 'next' && this.data.next != '') {
        this.pageUrl = this.data.next;
        this.getPois();
      }
    },


  },
};
</script>
