import api from "@/boot/axios";

export default {
    getShifts: async () => {
        const response = await api
            .get(`api/attendance/v1/shift/`)
            .then((res) => {
                return res.data.results;
            });

        return response;
    },

    storeShift: async (data) => {
        try {
            await api.post(`api/attendance/v1/shift/`, data)
            return { status: true };
        } catch (err) {
            let msg = '';
            for (let e in err.response.data) {
                msg += err.response.data[e].join(' ');
            }
            return { status: false, message: msg }
        }
    },

    updateShift: async (data) => {
        try {
            if (data.id || false) {
                await api.put(`api/attendance/v1/shift/${data.id}/`, data)
                return { status: true };
            }
            return { status: false };
        } catch (err) {
            let msg = '';
            for (let e in err.response.data) {
                msg += err.response.data[e].join(' ');
            }
            return { status: false, message: msg }
        }
    },

    deleteShift: async (id) => {
        try {
            if (id || false) {
                await api.delete(`api/attendance/v1/shift/${id}/`)
                return { status: true };
            }
            return { status: false };
        } catch (err) {
            let msg = '';
            for (let e in err.response.data) {
                msg += err.response.data[e].join(' ');
            }
            return { status: false, message: msg }
        }
    },

    updateShiftAssign: async (data) => {
        try {
            await api.post(`api/attendance/v1/shift-assign/`, data)
            return { status: true };
        } catch (err) {
            let msg = '';
            for (let e in err.response.data) {
                msg += err.response.data[e].join(' ');
            }
            return { status: false, message: msg }
        }
    },

    storeShiftHoliday: async (data) => {
        try {
            await api.post(`api/attendance/v1/holiday/`, data)
            return { status: true };
        } catch (err) {
            let msg = '';
            for (let e in err.response.data) {
                msg += err.response.data[e].join(' ');
            }
            return { status: false, message: msg }
        }
    },

    getWorkdays: async () => {
        const response = await api
            .get(`api/attendance/v1/office-workingdays/`)
            .then((res) => {
                return res.data.results;
            });

        return response;
    },

    updateWorkdays: async (data) => {
        try {
            await api.put(`api/attendance/v1/office-workingdays/${data.id}/`, data)
            return { status: true };
        } catch (err) {
            let msg = '';
            for (let e in err.response.data) {
                msg += err.response.data[e].join(' ');
            }
            return { status: false, message: msg }
        }
    },

    getUserAttendances: async ({ url }) => {
        const response = await api
            .get(url || `api/attendance/v1/user-attendance/`)
            .then((res) => {
                return res.data;
            });

        return response;
    },
}