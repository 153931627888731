import HolidaysView from "@/views/company/holiday/Index.vue";

export default [
    {
        path: '/holidays',
        name: 'company-holidays',
        meta: {
            key: 'holidays',
            title: 'Holidays',
            auth: true,
        },
        component: HolidaysView
    },
];