<template>
    <div class="">
      <div class="h-[100vh] w-[100px] absolute hover:w-[200px]">
        <SidenavteamMenu />
      </div>
  
      <HeaderNavbar title="Geofences Assignment" />
  
      <div class="ml-sidebar">
        <div v-if="loading">
          Loading...
        </div>
        <div v-else>
          <div class="mx-4 app-table my-2">
            <div v-if="geofencesAssignments.length > 0" class="appscroll" style="max-height: 67vh;">
                <table class="table table-poi table-striped">
                    <thead class="position-sticky top-0">
                        <tr class="text-start">
                            <th>Employees</th>
                            <th>Geofences</th>
                            <th>Start Time</th>
                            <th>End Time</th>
                            <!-- <th></th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-start" v-for="item in geofencesAssignments" :key="item.id">
                            <td>
                                <div v-for="emp in item.employees || []">
                                    {{ `${emp.first_name || ''} ${emp.last_name || ''}` }}
                                </div>
                            </td>
                            <td>
                                <div v-for="geo in item.geofences || []">
                                    {{ geo.name }}
                                </div>
                            </td>
                            <td>{{ formateDateTimeString(item.start_time || '') }}</td>
                            <td>{{ formateDateTimeString(item.end_time || '') }}</td>
                            <!-- <td>
                                <button type="button" class="btn" @click="() => deleteGeofenceAssignment(item)">
                                    <i class="fa fa-trash text-danger"></i>
                                </button>
                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
          </div>
          <div class="mx-4 my-3">
            <div class="d-flex justify-content-between">
              <div>
                <div class="form-group">
                  <div class="input-group">
                    Showing {{ geofencesAssignments.length || '0' }} 
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mx-4 mb-5">
            <div class="d-flex justify-content-between align-items-center">
              <router-link :to="{ name: 'company-geo-fence-assignments-assign' }" class="btn text-button">
                <i class="fa fa-plus text-info"></i> Assign Geofence
              </router-link>
            </div>
          </div>
        </div>
      </div>
  
    </div>
  </template>
  
  <script>
  import SidenavteamMenu from "@/components/menu/SideNavbarTeam.vue";
  import HeaderNavbar from "@/components/menu/headerdashboard.vue";
  import apis from "@/server/api/company/index";
  
  export default {
    components: { SidenavteamMenu, HeaderNavbar},
  
    data: () => ({
      loading: false,
      geofencesAssignments: [],
    }),
  
    mounted() {
      this.init();
    },
  
    methods: {
      async init() {
        this.loading = true;
        this.getData();
        this.loading = false;
      },
  
      async getData() {
        this.geofencesAssignments = await apis.getGeofenceAssignment();
      },

      async deleteGeofenceAssignment(item) {
            if (item.id || false) {
                if (confirm(`are you sure, you want to delete ${item.name || ""}?`)) {
                    // const response = await apis.deleteGeofence(item.id);
                    // if (response.status || false) {
                    //     this.$toast.success(`Geofence delete successfully`, {
                    //         position: "top",
                    //     });
                    // } else {
                    //     if (response.message.length || false) {
                    //         this.$toast.error(`${response.message}`, {
                    //             position: "top",
                    //         });
                    //     } else {
                    //         this.$toast.error(`Something went wrong! please refresh.`, {
                    //             position: "top",
                    //         });
                    //     }
                    // }
                }
                this.init();
            }

        },
  
    },
  };
  </script>
  