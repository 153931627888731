import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/tailwind.css";
import Toaster from "@meforma/vue-toaster";
import Multiselect from '@vueform/multiselect'
import ToggleButton from "@/components/ui/ToggleButton.vue";
import VueBlocksTree from 'vue3-blocks-tree';
import 'vue3-blocks-tree/dist/vue3-blocks-tree.css';
import '@vueform/multiselect/themes/default.css';

import GlobalMixin from "./mixins/GlobalMixin";

import vClickOutside from 'v-click-outside';


import store from "./store";

const app = createApp(App);
app.use(router);
app.use(store);
app.use(Toaster);
app.use(vClickOutside);
app.component('vue-multiselect', Multiselect);
app.component('toggle-button', ToggleButton);
app.component('vue-app-tree', VueBlocksTree);
app.mixin(GlobalMixin);

app.mount('#app');