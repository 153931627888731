<template>
    <div class="">
      <div class="h-[100vh] w-[100px] absolute hover:w-[200px]">
        <SidenavteamMenu />
      </div>
  
      <HeaderNavbar title="Geofences" />
  
      <div class="ml-sidebar">
        <div v-if="loading">
          Loading...
        </div>
        <div v-else>
          <div class="mx-4 app-table my-2">
            <div class="d-flex justify-content-end mb-2">
              <router-link :to="{ name: 'company-geo-fence-assignments' }" class="btn text-button">
                <i class="fa fa-pen text-info"></i> Assign Geofence
              </router-link>
            </div>
            <div v-if="geofences.length > 0" class="appscroll" style="max-height: 67vh;">
                <table class="table table-poi table-striped">
                    <thead class="position-sticky top-0">
                        <tr class="text-start">
                            <th>Name</th>
                            <th>Description</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-start" v-for="item in geofences" :key="item.id">
                            <td>{{ item.name || '' }}</td>
                            <td>{{ item.description || '' }}</td>
                            <td>
                                <!-- <router-link :to="{ name: 'company-geo-fence-edit', params: { id: item.id } }" class="btn">
                                    <i class="fa fa-edit text-info"></i>
                                </router-link> -->
                                <button type="button" class="btn" @click="() => deleteGeofence(item)">
                                    <i class="fa fa-trash text-danger"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
          </div>
          <div class="mx-4 my-3">
            <div class="d-flex justify-content-between">
              <div>
                <div class="form-group">
                  <div class="input-group">
                    Showing {{ geofences.length || '0' }} 
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mx-4 mb-5">
            <div class="d-flex justify-content-between align-items-center">
              <router-link :to="{ name: 'company-geo-fence-create' }" class="btn text-button">
                <i class="fa fa-plus text-info"></i> Create Geofence
              </router-link>
            </div>
          </div>
        </div>
      </div>
  
    </div>
  </template>
  
  <script>
  import SidenavteamMenu from "@/components/menu/SideNavbarTeam.vue";
  import HeaderNavbar from "@/components/menu/headerdashboard.vue";
  import apis from "@/server/api/company/index";
  
  export default {
    components: { SidenavteamMenu, HeaderNavbar},
  
    data: () => ({
      loading: false,
      geofences: [],
    }),
  
    mounted() {
      this.init();
    },
  
    methods: {
      async init() {
        this.loading = true;
        this.getData();
        this.loading = false;
      },
  
      async getData() {
        this.data = await apis.getGeofences();
        this.geofences = this.data;
      },

      async deleteGeofence(item) {
            if (item.id || false) {
                if (confirm(`are you sure, you want to delete ${item.name || ""}?`)) {
                    const response = await apis.deleteGeofence(item.id);
                    if (response.status || false) {
                        this.$toast.success(`Geofence delete successfully`, {
                            position: "top",
                        });
                    } else {
                        if (response.message.length || false) {
                            this.$toast.error(`${response.message}`, {
                                position: "top",
                            });
                        } else {
                            this.$toast.error(`Something went wrong! please refresh.`, {
                                position: "top",
                            });
                        }
                    }
                }
                this.init();
            }

        },
  
    },
  };
  </script>
  