<script>
import SidenavteamMenu from "@/components/menu/SideNavbarTeam.vue";
import HeaderNavbar from "@/components/menu/headerdashboard.vue";
import CompanyApi from "@/server/api/company";
import api from "@/boot/axios.js";

export default {
  data() {
    return {
      isFavSort: false,
      isSort: false,
      isSaving: false,
      companyApi: new CompanyApi(),
      employeeList: [],
      userDesignationList: [],
      allDeartments: [],
      allDesignation: [],
      selectedDepartment: null,
      selectedDesignation: null,
      showPopup: false,
      selected: "",
      modalId: "",
      searchQuery: "",
      form: {
        first_name: "",
        last_name: "",
        user_designation: "",
        user_department: "",
        is_department_head: false,
        is_favorite: false,
      },
      employeeTreeList: {
        label: '',
        expand: true,
        children: []
      },
      showTreeView: false,
    };
  },
  components: {
    SidenavteamMenu,
    HeaderNavbar,
  },
  computed: {
    filteredEmployees() {
      return this.employeeList.filter((emp) => {
        // const mobile = `${emp.mobile}`;

        // return mobile.includes(this.searchQuery);
        const name = `${emp.first_name} ${emp.last_name}`.toLowerCase();
        const phone = emp.phone?.toLowerCase() || emp.username?.toLowerCase() || '';
        const email = emp.email?.toLowerCase() || '';
        const searchQuery = this.searchQuery.toLowerCase() || '';
        const departmentFilter = this.selectedDepartment
          ? emp?.user_department?.id === this.selectedDepartment
          : true;
        const designationFilter = this.selectedDesignation
          ? emp?.user_designation?.id === this.selectedDesignation
          : true;

        const id = emp.id?.toString() || '';

        return (
          (name.includes(searchQuery) ||
            phone.includes(searchQuery) ||
            email.includes(searchQuery) ||
            id.includes(searchQuery)) &&
          departmentFilter &&
          designationFilter
        );
      });
    },
  },
  mounted() {
    this.userList();
    this.getDepartments();
    this.getDesignation();
  },
  methods: {
    async userList() {
      await api.get("api/core/v1/company-user-list/").then((res) => {
        this.employeeList = res.data?.results;
        this.handleEmployeeTree();
      });
    },

    sort() {
      if (this.isSort) {
        this.employeeList = this.employeeList.sort((a, b) => {
          if (a.id === undefined || b.id === undefined) {
            return a.id === undefined ? 1 : -1;
          }
          return b.id - a.id
        })
      } else {
        this.employeeList = this.employeeList.sort((a, b) => {
          if (a.id === undefined || b.id === undefined) {
            return a.id === undefined ? 1 : -1;
          }
          return a.id - b.id
        })
      }
      this.isSort = !this.isSort;
    },

    favSort() {
      if (this.isFavSort) {
        this.employeeList = this.employeeList.sort((a, b) => {
          const aValue = a['is_favorite'] !== undefined ? a['is_favorite'] : false;
          const bValue = b['is_favorite'] !== undefined ? b['is_favorite'] : false;
          return aValue - bValue;
        })
      } else {
        this.employeeList = this.employeeList.sort((a, b) => {
          const aValue = a['is_favorite'] !== undefined ? a['is_favorite'] : false;
          const bValue = b['is_favorite'] !== undefined ? b['is_favorite'] : false;
          return bValue - aValue;
        })
      }
      this.isFavSort = !this.isFavSort;
    },

    propertySort(propertyName) {
      this.employeeList = this.employeeList.sort((a, b) => {
        if (a[propertyName] === undefined || b[propertyName] === undefined) {
          return a[propertyName] === undefined ? 1 : -1;
        }
        return a[propertyName].toLowerCase().localeCompare(b[propertyName].toLowerCase());
      })
    },

    nestedPropertySort(key1, key2) {
      this.employeeList = this.employeeList.sort((a, b) => {
        if (!(a[key1] && b[key1])) {
          return -1;
        }
        if (a[key1][key2] === undefined || b[key1][key2] === undefined) {
          return a[key1][key2] === undefined ? 1 : -1;
        }
        return a[key1][key2].toLowerCase().localeCompare(b[key1][key2].toLowerCase());
      })
    },

    async getDepartments() {
      await api.get("api/company/v1/department/").then((res) => {
        this.allDeartments = res.data;
      });
    },

    async getDesignation() {
      await api.get("api/company/v1/designation/").then((res) => {
        this.allDesignation = res.data;
      });
    },

    userDesignationPut(empId) {
      this.showPopup = true;
      this.modalId = empId;
      for (const emp of this.employeeList) {
        if (empId == emp.id) {
          this.form.first_name = emp.first_name;
          this.form.last_name = emp.last_name;
          this.form.user_department = emp.user_department?.id || '';
          this.form.user_designation = emp.user_designation?.id || '';
          this.form.is_department_head = emp.is_department_head || false;
          this.form.is_favorite = emp.is_favorite || false;
          this.form.phone = emp.phone || emp.username || '';
        }
      }
    },

    submit() {
      this.isSaving = true;
      let data = {
        first_name: this.form.first_name,
        last_name: this.form.last_name,
        is_department_head: this.form.is_department_head,
        is_favorite: this.form.is_favorite,
        phone: this.form.phone,
      };
      if (this.form.user_department || false) {
        data['user_department'] = this.form.user_department;
      }
      if (this.form.user_designation || false) {
        data['user_designation'] = this.form.user_designation;
      }
      api
        .patch(`api/core/v1/company-user-update/${this.modalId}/`, data)
        .then((res) => {
          this.modalId = "";
          this.form.first_name = "";
          this.form.last_name = "";
          this.form.is_department_head = false;
          this.form.is_favorite = false;
          this.form.phone = '';
          this.$toast.success(`Assigned successfully`, {
            position: "top",
          });
          setTimeout(() => {
            this.$router.go();
          }, 800);
        });
    },

    toggleShowTreeView() {
      this.showTreeView = !this.showTreeView;
    },

    handleEmployeeTree() {
      const tree = this.employeeList
        .filter((item) => item.user_department !== null && item.user_department !== undefined)
        .map((item) => item.user_department)
        .reduce((acc, item) => {
          acc[item.id] = item;
          acc[item.id]['label'] = `D: ${item.name}`;
          acc[item.id]['children'] = this.employeeList
            .filter((emp) => emp.user_department?.id == item.id)
            .map((emp) => {
              emp['label'] = emp.first_name;
              return emp;
            })
          return acc
        }, {})

      this.employeeTreeList.children = Object.values(tree);

    },

    handleSelectFav(userId, value) {
      api
        .patch(`api/core/v1/company-user-update/${userId}/`, { is_favorite: value })
        .then((_) => {
          this.$toast.success(`Successfully Done`, {
            position: "top",
          });
          this.userList();
        });

    },

  },
};
</script>
<style scoped>
.personDetailsButton {
  border-radius: 5px;
  color: rgba(106, 106, 106, 0.346);
  border: solid rgba(106, 106, 106, 0.346) 1px;
  margin-top: 20px;
  font-size: 12px;
  padding: 10px 20px;
}

.popupContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.242);
}

.inputCard {
  background: transparent !important;
  border: none;
  outline: none;
}
</style>

<template>
  <div>
    <!-- popup  -->
    <div v-if="showPopup">
      <div class="popupContainer" style="z-index: 9;">
        <div class="bg-white w-[50%] h-[70%] py-7 px-8 z-30">
          <div class="w-full flex justify-end">
            <button class="" @click="showPopup = !showPopup">
              <img :src="require(`@/assets/icons/close.svg`)" alt="searchLogo" class="h-[16px] w-[16px]" />
            </button>
          </div>
          <div class="text-sm dark:text-gray-600">
            Assign Designation & Department
          </div>

          <hr class="mt-4" />
          <div class="mt-4 pl-5">
            <form @submit.prevent="submit" class="form-layout my-3 text-start">
              <div class="row my-4">
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="text-start">First name</label>
                    <input type="text" class="form-control" placeholder="First Name" v-model="form.first_name" required />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="text-start">Last Name</label>
                    <input type="text" class="form-control" placeholder="Last Name" v-model="form.last_name" required />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="text-start">Phone</label>
                    <input type="text" class="form-control" placeholder="Phone" disabled :value="form.phone" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-start">Assign Department</label>
                    <select class="form-control" v-model="form.user_department">
                      <option value="">N/A</option>
                      <option required :value="allDep.id" v-for="allDep in allDeartments" :key="allDep.id">
                        {{ allDep.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-start">Assign Designation</label>
                    <select class="form-control" v-model="form.user_designation">
                      <option value="">N/A</option>
                      <option required :value="allDes.id" v-for="allDes in allDesignation" :key="allDes.id">
                        {{ allDes.title }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-check">
                    <input class="form-check-input app-checkbox" type="checkbox" id="is_dep_head_emp"
                      v-model="form.is_department_head">
                    <label class="form-check-label mt-1 ml-3" for="is_dep_head_emp">Is Department Head</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-check">
                    <input class="form-check-input app-checkbox" type="checkbox" id="is_fav_emp"
                      v-model="form.is_favorite">
                    <label class="form-check-label mt-1 ml-3" for="is_fav_emp">Is Favorite</label>
                  </div>
                </div>
              </div>

              <div class="text-end">
                <button type="submit" class="btn btn-outline-primary" :disabled="isSaving">
                  {{ isSaving ? "Saving..." : "Save changes" }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- End of popup  -->
    <div class="h-[100vh] w-[100px] absolute hover:w-[200px]">
      <SidenavteamMenu />
    </div>
    <HeaderNavbar title="Employee-List"> </HeaderNavbar>
    <div>
      <div class="ml-sidebar">
        <div class="mx-4 my-3">
          <div class="d-flex align-items-center">
            <h3 class="mb-0 mr-2 fw-bold">Tree View</h3>
            <toggle-button @change="toggleShowTreeView" />
          </div>
        </div>
        <div v-if="showTreeView" class="mx-4 mt-5 app-tree appscroll" style="height: 70vh;">
          <vue-app-tree :data="employeeTreeList" :horizontal="false" :collapsable="false">
          </vue-app-tree>
        </div>
        <div v-else class="mx-4 app-table mt-3">
          <div class="d-flex align-items-center mb-1">
            <div class="d-flex align-items-center mr-3">
              <div class="mr-2">Search:</div>
              <div class="border">
                <input class="inputCard ml-2" type="text" v-model="searchQuery"
                  placeholder="Enter employee contact number, name, id" />
              </div>
            </div>
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center mr-3">
                <div class="mr-2">Department:</div>
                <div class="border">
                  <select class="inputCard" v-model="selectedDepartment">
                    <option value="">All</option>
                    <option :value="allDep.id" v-for="allDep in allDeartments" :key="allDep.id">
                      {{ allDep.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center mr-3">
                <div class="mr-2">Designation:</div>
                <div class="border">
                  <select class="inputCard" v-model="selectedDesignation">
                    <option value="">All</option>
                    <option :value="allDes.id" v-for="allDes in allDesignation" :key="allDes.id">
                      {{ allDes.title }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="appscroll" style="height: 75vh;">
            <table class="table table-striped table-bordered table-py">
              <thead class="position-sticky top-0">
                <tr class="text-center">
                  <th class="text-start" @click="sort">
                    Emp ID <i class="fas" :class="{
                      'fas fa-angle-down': !isSort,
                      'fas fa-angle-up': isSort
                    }"></i>
                  </th>
                  <th @click="() => propertySort('first_name')">Name</th>
                  <th>Cell No.</th>
                  <th @click="() => nestedPropertySort('user_department', 'name')" class="text-start">Department</th>
                  <th @click="() => nestedPropertySort('user_designation', 'title')">Designation</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Shift</th>
                  <th @click="favSort">Favorite
                    <i class="fas" :class="{
                      'fas fa-angle-down': !isFavSort,
                      'fas fa-angle-up': isFavSort
                    }"></i>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center" v-for="emp in filteredEmployees" :key="emp.id">
                  <td>{{ emp.id }}</td>
                  <td class="text-start">
                    <div class="d-flex">
                      <div v-if="emp.avatar || false" class="whr-50">
                        <img :src="emp.avatar" alt="" class="img-fluid">
                      </div>
                      <div>
                        <p class="fw-bold">
                          {{ `${emp.first_name || ''} ${emp.last_name || ''}` }}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>{{ emp.phone }}</td>
                  <td class="text-start">
                    {{ emp.user_department?.name || '' }}
                    <div class="badge badge-success">{{ emp.is_department_head || false ? 'Department Head' : '' }}
                    </div>
                  </td>
                  <td>
                    {{ emp.user_designation?.title || '' }}
                  </td>
                  <td>
                    {{ emp.email || '' }}
                  </td>
                  <td>{{ emp.role }}</td>
                  <td>
                    <div v-for="(shift, index) in JSON.parse(emp.shift) || []" :key="index" class="badge m-1" :class="{
                      'badge-purple': index % 2 == 0,
                      'badge-orange': index % 2 == 1,
                    }">
                      {{ shift }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div v-if="emp.is_favorite || false">
                      <button class="btn" @click="() => handleSelectFav(emp.id, false)">
                        <i class="fa fa-heart text-danger"></i>
                      </button>
                    </div>
                    <div v-else>
                      <button class="btn" @click="() => handleSelectFav(emp.id, true)">
                        <i class="fa fa-heart-o"></i>
                      </button>
                    </div>
                  </td>
                  <td>
                    <div class="text-start text-gray-400 hover:text-gray-600">
                      <button type="button" @click="userDesignationPut(emp.id)">
                        <i class="fa fa-edit text-info"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="text-xs text-start mt-1">Showing {{ filteredEmployees.length || '0' }} entires</p>
        </div>
      </div>
    </div>
  </div>
</template>
