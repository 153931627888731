import api from "@/boot/axios";

export default {
    getPois: async (url) => {
        const response = await api
            .get(url || `api/company/v1/company-poi/`)
            .then((res) => {
                return res.data;
            });

        return response;
    },

    getPoi: async (id) => {
        const response = await api
            .get(`api/company/v1/company-poi/${id}/`)
            .then((res) => {
                return res.data;
            });
        console.log(response)
        return response;
    },

    storePoi: async (data) => {
        try {
            await api.post(`api/company/v1/company-poi/`, data)
            return { status: true };
        } catch (err) {
            let msg = '';
            for (let e in err.response.data) {
                msg += err.response.data[e].join(' ');
            }
            return { status: false, message: msg }
        }
    },

    updatePoi: async (data) => {
        try {
            if (data.id || false) {
                await api.put(`api/company/v1/company-poi/${data.id}/`, data)
                return { status: true };
            }
            return { status: false };
        } catch (err) {
            let msg = '';
            for (let e in err.response.data) {
                msg += err.response.data[e].join(' ');
            }
            return { status: false, message: msg }
        }
    },

    deletePoi: async (id) => {
        try {
            if (id || false) {
                await api.delete(`api/company/v1/company-poi/${id}/`)
                return { status: true };
            }
            return { status: false };
        } catch (err) {
            let msg = '';
            for (let e in err.response.data) {
                msg += err.response.data[e].join(' ');
            }
            return { status: false, message: msg }
        }
    },

    getCompanyPoiCategory: async () => {
        const response = await api
            .get(`api/company/v1/company-poi-category/`)
            .then((res) => {
                return res.data.results;
            });

        return response;
    }
}
