import api from "@/boot/axios";

export default {
    getTaskReport: async ({ startingDate, endingDate, limit, url }) => {
        const companyId = localStorage.getItem("company");
        let datestr = '';
        if (startingDate && endingDate) {
            datestr = `&start_date_time=${startingDate}&end_date_time=${endingDate}`;
        }
        const response = await api
            .get(url || `api/report/v1/task-assign-report/?company=${companyId}${datestr}&page_size=${limit || 100}`)
            .then((res) => {
                return res.data;
            });

        return response;
    },

    getAttendanceReport: async ({ userid, startingDate, endingDate, limit, url }) => {
        const companyId = localStorage.getItem("company");
        let datestr = '';
        if (startingDate && endingDate) {
            datestr = `&start_date_time=${startingDate}&end_date_time=${endingDate}`;
        }
        let userstr = '';
        if (userid || false) {
            userstr = `&user__in_data=${userid}`;
        }
        const response = await api
            .get(url || `api/report/v1/attendance-report/?company=${companyId}&${datestr}${userstr}&page_size=${limit || 100}`)
            .then((res) => {
                return res.data;
            });

        return response;
    },

    getErrorLogReport: async ({ userid, startingDate, endingDate, limit, url, search }) => {
        const companyId = localStorage.getItem("company");
        let datestr = `&search=${search}`;
        if (startingDate) {
            datestr = `?date=${startingDate}`;
        }
        let userstr = '';
        if (userid || false) {
            userstr = `&user__in_data=${userid}`;
        }
        const response = await api
            .get(url || `api/log/v1/company/error-log/?company=${companyId}&${datestr}${userstr}&page_size=${limit || 100}`)
            .then((res) => {
                return res.data;
            });

        return response;
    },

    getDeviceLogReport: async ({ userid, startingDate, endingDate, limit, url, search }) => {
        const companyId = localStorage.getItem("company");
        let datestr = `&search=${search}`;
        if (startingDate) {
            datestr = `?date=${startingDate}`;
        }
        let userstr = '';
        if (userid || false) {
            userstr = `&user__in_data=${userid}`;
        }
        const response = await api
            .get(url || `api/log/v1/company/device-log/?company=${companyId}&${datestr}${userstr}&page_size=${limit || 100}`)
            .then((res) => {
                return res.data;
            });

        return response;
    },

    getPunchInOutLogReport: async ({ userid, startingDate, endingDate, limit, url, search }) => {
        const companyId = localStorage.getItem("company");
        let datestr = `&search=${search}`;
        if (startingDate) {
            datestr = `?date=${startingDate}`;
        }
        let userstr = '';
        if (userid || false) {
            userstr = `&user__in_data=${userid}`;
        }
        const response = await api
            .get(url || `api/log/v1/company/punch-in-out/?company=${companyId}&${datestr}${userstr}&page_size=${limit || 100}`)
            .then((res) => {
                return res.data;
            });

        return response;
    },

    getGeofenchViolationReport: async ({ userid, startingDate, endingDate, limit, url, search }) => {
        const companyId = localStorage.getItem("company");
        // let datestr = `&search=${search}`;
        // if (startingDate) {
        //     datestr = `?date=${startingDate}`;
        // }
        // let userstr = '';
        // if (userid || false) {
        //     userstr = `&user__in_data=${userid}`;
        // }
        // const uri = url || `api/log/v1/company/violations/?company=${companyId}&${datestr}${userstr}&page_size=${limit || 100}`;
        const uri = url || `api/geofence/v1/violations/?search=${search}&page_size=${limit || 100}`;
        const response = await api
            .get(uri)
            .then((res) => {
                return res.data;
            });

        return response;
    },

}
