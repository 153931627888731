<template>
    <input type="checkbox" id="switch" @change="handleChange" /><label for="switch"></label>
</template>

<style scoped>
input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
}

label {
    cursor: pointer;
    text-indent: -9999px;
    width: 50px;
    height: 25px;
    background: #ddd;
    display: block;
    border-radius: 100px;
    position: relative;
}

label:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 15px;
    height: 15px;
    background: #fff;
    border-radius: 40px;
    transition: 0.3s;
}

input:checked+label {
    background: #1fa8e1;
}

input:checked+label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
}

label:active:after {
    width: 50px;
}
</style>

<script>
export default {
    emits: ['change'],

    methods: {
        handleChange() {
            this.$emit('change');
        }
    }
}
</script>