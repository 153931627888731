<template>
  <div>
    <span>Select height is limited to 8 options.</span>
    <div class="select-wrapper form-column form-column-field">
      <select
        data-no-search=""
        data-placeholder="Выбрать год"
        onfocus="this.size=8;"
        onblur="this.size=1;"
        onchange="this.size=1; this.blur();"
        class="select select-no-search"
      >
        <option disabled="disabled">
          <font style="vertical-align: inherit">-Select Year</font>
        </option>
        <option value="1922">
          <font style="vertical-align: inherit">1922</font>
        </option>
        <option value="1923">
          <font style="vertical-align: inherit">1923</font>
        </option>
        <option value="1922">
          <font style="vertical-align: inherit">1924</font>
        </option>
        <option value="1923">
          <font style="vertical-align: inherit">1925</font>
        </option>
        <option value="1922">
          <font style="vertical-align: inherit">1926</font>
        </option>
        <option value="1923">
          <font style="vertical-align: inherit">1927</font>
        </option>
        <option value="1922">
          <font style="vertical-align: inherit">1928</font>
        </option>
        <option value="1923">
          <font style="vertical-align: inherit">1929</font>
        </option>
        <option value="1922">
          <font style="vertical-align: inherit">1930</font>
        </option>
        <option value="1923">
          <font style="vertical-align: inherit">1931</font>
        </option>
        <option value="1922">
          <font style="vertical-align: inherit">1932</font>
        </option>
        <option value="1923">
          <font style="vertical-align: inherit">1933</font>
        </option>
      </select>
    </div>
    <div
      class="footer__block"
      style="background-color: wheat; height: 200px; border: 2px dashed green"
    >
      <h3>Popular tags:</h3>
      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dignissimos
      dolorem earum magnam sit minima incidunt nemo sed voluptates similique
      quia.Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dignissimos
      dolorem earum magnam sit minima incidunt nemo sed voluptates similique
      quia.
    </div>
  </div>
</template>
<style scoped>
.select-wrapper {
  height: 50px;
  overflow-y: visible;
}

.select {
  width: 100%;
  /* make it min-height*/
  min-height: 50px;
  border-radius: 25px;
  border-color: #555;
  padding: 10px;
}
</style>

<script></script>
