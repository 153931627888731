<template>
    <div>
        <div class="mx-4 app-table mt-2">
            <div class="text-end mb-3">
                <button type="button" class="btn text-button" data-bs-toggle="modal" data-bs-target="#shiftModal"
                    @click="resetForm">
                    <i class="fa fa-plus text-info"></i> Add shift
                </button>
            </div>
            <table v-if="shifts.length || false" class="table">
                <thead>
                    <tr class="text-start">
                        <th>Shift</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>Grace Time</th>
                        <th>Attendance Out</th>
                        <th>Created At</th>
                        <th>Updated At</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="text-start" v-for="shift in shifts" :key="shift.id">
                        <td>{{ shift.shift_name || '' }}</td>
                        <td>
                            {{ formateTime(shift.start_time) }}
                        </td>
                        <td>
                            {{ formateTime(shift.end_time) }}
                        </td>
                        <td>
                            {{ shift.grace_time || '0' }} mins
                        </td>
                        <td>
                            {{ formateTime(shift.auto_attendanceout_time) }}
                        </td>
                        <td>
                            {{ formateDate(shift.created_at) }}
                        </td>
                        <td>
                            {{ formateDate(shift.updated_at) }}
                        </td>
                        <td>
                            <button type="button" class="btn" data-bs-toggle="modal" data-bs-target="#shiftAssignModal"
                                @click="handleEdit(shift)">
                                <i class="fa fa-eye text-primary"></i>
                            </button>
                            <button type="button" class="btn" data-bs-toggle="modal" data-bs-target="#shiftModal"
                                @click="handleEdit(shift)">
                                <i class="fa fa-edit text-info"></i>
                            </button>
                            <button type="button" class="btn" @click="deleteShift(shift)">
                                <i class="fa fa-trash text-danger"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="modal fade" id="shiftModal" tabindex="-1" aria-labelledby="shiftModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">
                        <h1 class="modal-title fs-5 text-start">{{ form.isEdit ? 'Edit' : 'Add' }} Shift</h1>
                        <form @submit.prevent="submit" class="form-layout my-3 text-start">
                            <div class="form-group">
                                <label class="text-start">Shift Name</label>
                                <input type="text" class="form-control" placeholder="Type Shift Name"
                                    v-model="form.shift.shift_name" />
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="text-start">Start Time</label>
                                        <input type="time" class="form-control" v-model="form.shift.start_time" />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="text-start">End Time</label>
                                        <input type="time" class="form-control" v-model="form.shift.end_time" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="text-start">Grace Time (in minutes)</label>
                                        <input type="text" class="form-control" placeholder="Type Grace Time"
                                            v-model="form.shift.grace_time" />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="text-start">Attendance Out</label>
                                        <input type="time" class="form-control"
                                            v-model="form.shift.auto_attendanceout_time" />
                                    </div>
                                </div>
                            </div>
                            <div class="text-center">
                                <button type="submit" class="btn btn-outline-primary">Save changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="shiftAssignModal" tabindex="-1" aria-labelledby="shiftAssignModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content" style="min-height: 400px;">
                    <div class="modal-body">
                        <ul class="nav nav-tabs" id="shiftInfo" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="shift-assign-tab" data-bs-toggle="tab"
                                    data-bs-target="#shift-assign" type="button" role="tab" aria-controls="shift-assign"
                                    aria-selected="true">Assign Shift</button>
                            </li>
                        </ul>
                        <div class="tab-content" id="shiftInfoContent">
                            <div class="tab-pane fade show active" id="shift-assign" role="tabpanel"
                                aria-labelledby="shift-assign-tab">
                                <div v-if="form.shift?.shifts_assigned.length || false">
                                    <div class="row my-3">
                                        <div class="col-md-12" v-for="shiftAssign in (form.shift.shifts_assigned || [])"
                                            :key="shiftAssign.id">
                                            <table class="table app-table">
                                                <thead>
                                                    <tr class="text-start">
                                                        <th>POI Name</th>
                                                        <th>Address</th>
                                                        <th>Landmark</th>
                                                        <th>Lat</th>
                                                        <th>Lng</th>
                                                        <th>Category</th>
                                                        <th>Department</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr class="text-start">
                                                        <td>{{ shiftAssign.company_poi?.name || '' }}</td>
                                                        <td>{{ shiftAssign.company_poi?.address || '' }}</td>
                                                        <td>{{ shiftAssign.company_poi?.landmark || '' }}</td>
                                                        <td>
                                                            <div class="badge badge-success">
                                                                {{ shiftAssign.company_poi?.lat || '' }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="badge badge-success">
                                                                {{ shiftAssign.company_poi?.long || '' }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="badge badge-orange">
                                                                {{ shiftAssign.company_poi?.category || '' }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div v-for="dep in shiftAssign?.department || []"
                                                                class="badge badge-purple m-1">
                                                                {{ dep.name || '' }}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="!addShiftAssign" class="text-start">
                                    <button type="button" class="btn text-button" @click="toggleAddShiftAssign">
                                        <i class="fa fa-plus text-info"></i> Add another
                                    </button>
                                </div>
                                <form v-else @submit.prevent="submitShiftAssign" class="form-layout my-3 text-start">
                                    <div class="form-group">
                                        <label class="text-start">POI</label>
                                        <select class="form-control" v-model="form.shiftAssign.company_poi">
                                            <option value="">Select</option>
                                            <option v-for="poi in pois" :key="poi.id" :value="poi.id">
                                                {{ poi.address }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-start">Department</label>
                                        <select class="form-control" v-model="form.shiftAssign.department" multiple>
                                            <option v-for="department in departments" :key="department.id"
                                                :value="department.id">
                                                {{ department.name }}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="text-end">
                                        <button type="submit" class="btn btn-outline-primary">Save changes</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import SidenavteamMenu from "@/components/menu/SideNavbarTeam.vue";
import HeaderNavbar from "@/components/menu/headerdashboard.vue";
import apis from "@/server/api/company/index";

export default {
    components: { SidenavteamMenu, HeaderNavbar },

    data: () => ({
        pois: [],
        departments: [],
        shifts: [],
        addShiftAssign: false,
        addHoliday: false,
        form: {
            isEdit: false,
            shift: {
                shift_name: '',
                start_time: '',
                end_time: '',
                grace_time: '',
                auto_attendanceout_time: '',
                shifts_assigned: [],
            },
            shiftAssign: {
                shift: null,
                company_poi: null,
                department: [],
            },
            shiftHoliday: {
                shift: null,
                date: null,
                note: '',
            }
        },
    }),

    mounted() {
        this.init();
    },

    methods: {
        async init() {
            this.shifts = await apis.getShifts();
            this.departments = await apis.getDepartments();
            this.pois = await apis.getPois();
        },

        handleEdit(data) {
            this.form.isEdit = true;
            this.form.shift = { ...data };
            if (data.parent || false) {
                this.form.isChild = true;
            } else {
                this.form.isChild = false;
            }
        },

        async submit() {
            if (this.validation()) {
                if (this.form.isEdit) {
                    this.handleUpdate();
                } else {
                    this.handleStore();
                }
            }
        },

        async handleUpdate() {
            let success = false;
            if (this.form.shift.id || false) {
                const response = await apis.updateShift(this.form.shift);
                if (response.status || false) {
                    this.$toast.success(`Shift update successfully`, {
                        position: "top",
                    });
                    success = true;
                } else {
                    if (response.message.length || false) {
                        this.$toast.error(`${response.message}`, {
                            position: "top",
                        });
                    } else {
                        this.$toast.error(`Something went wrong! please refresh.`, {
                            position: "top",
                        });
                    }
                }
            } else {
                this.$toast.error(`Something went wrong! please refresh.`, {
                    position: "top",
                });
            }

            if (success) {
                this.resetForm();
                setTimeout(() => {
                    this.$router.go();
                }, 800);
            }
        },

        async handleStore() {
            let success = false;
            const response = await apis.storeShift(this.form.shift);
            if (response.status || false) {
                this.$toast.success(`Shift add successfully`, {
                    position: "top",
                });
                success = true;
            } else {
                if (response.message.length || false) {
                    this.$toast.error(`${response.message}`, {
                        position: "top",
                    });
                } else {
                    this.$toast.error(`Something went wrong! please refresh.`, {
                        position: "top",
                    });
                }
            }

            if (success) {
                this.resetForm();
                setTimeout(() => {
                    this.$router.go();
                }, 800);
            }
        },

        async deleteShift(shift) {
            if (shift.id || false) {
                if (confirm(`are you sure, you want to delete ${shift.name || ''}?`)) {
                    const response = await apis.deleteShift(shift.id);
                    if (response.status || false) {
                        this.$toast.success(`Shift delete successfully`, {
                            position: "top",
                        });
                    } else {
                        if (response.message.length || false) {
                            this.$toast.error(`${response.message}`, {
                                position: "top",
                            });
                        } else {
                            this.$toast.error(`Something went wrong! please refresh.`, {
                                position: "top",
                            });
                        }
                    }
                    this.init();
                }
            }
        },

        async submitShiftAssign() {
            let success = false;
            if (this.form.shift.id || false) {
                this.form.shiftAssign.shift = this.form.shift.id;
                const response = await apis.updateShiftAssign(this.form.shiftAssign);
                if (response.status || false) {
                    this.$toast.success(`Shift assigen successfully`, {
                        position: "top",
                    });
                    success = true;
                } else {
                    if (response.message.length || false) {
                        this.$toast.error(`${response.message}`, {
                            position: "top",
                        });
                    } else {
                        this.$toast.error(`Something went wrong! please refresh.`, {
                            position: "top",
                        });
                    }
                }
            } else {
                this.$toast.error(`Something went wrong! please refresh.`, {
                    position: "top",
                });
            }

            if (success) {
                this.resetForm();
                setTimeout(() => {
                    this.$router.go();
                }, 800);
            }
        },

        async submitHolidays() {
            let success = false;
            if (this.form.shift.id || false) {
                this.form.shiftHoliday.shift = this.form.shift.id;
                const response = await apis.storeShiftHoliday(this.form.shiftHoliday);
                if (response.status || false) {
                    this.$toast.success(`Holiday store successfully`, {
                        position: "top",
                    });
                    success = true;
                } else {
                    if (response.message.length || false) {
                        this.$toast.error(`${response.message}`, {
                            position: "top",
                        });
                    } else {
                        this.$toast.error(`Something went wrong! please refresh.`, {
                            position: "top",
                        });
                    }
                }
            } else {
                this.$toast.error(`Something went wrong! please refresh.`, {
                    position: "top",
                });
            }

            if (success) {
                this.resetForm();
                this.shifts = await apis.getShifts();
            }
        },

        validation() {
            const mapField = {
                'shift_name': 'Shift Name',
                'start_time': 'Start Time',
                'end_time': 'End Time',
                'grace_time': 'Grace Time',
                'auto_attendanceout_time': 'Attendance Out',
            }

            for (let key in this.form.shift) {
                if (Object.keys(mapField).includes(key) && !(this.form.shift[key] || false)) {
                    this.$toast.error(`${mapField[key]} field is required`, {
                        position: "top",
                    });
                    return false;
                }
            }

            return true;
        },

        resetForm() {
            this.form = {
                isEdit: false,
                shift: {
                    shift_name: '',
                    start_time: '',
                    end_time: '',
                    grace_time: '',
                    auto_attendanceout_time: '',
                    shifts_assigned: [],
                },
                shiftAssign: {
                    company_poi: null,
                    department: [],
                },
                shiftHoliday: {
                    shift: null,
                    date: null,
                    note: '',
                }
            };
        },

        toggleAddHoliday() {
            this.addHoliday = !this.addHoliday;
        },

        toggleAddShiftAssign() {
            this.addShiftAssign = !this.addShiftAssign;
        },

        formateDate(value) {
            if (!value) {
                return '';
            }

            const date = new Date(value)

            const months = [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];

            const day = date.getDate();
            const month = months[date.getMonth()];
            const year = date.getFullYear().toString().slice(-2);

            const formattedDate = `${day} ${month}, ${year}`;
            return formattedDate;
        },

        formateTime(value) {
            if (!value) {
                return '';
            }
            try {
                const date = value.split(':');

                const hours = date[0];
                const minutes = date[1];
                const period = hours >= 12 ? "PM" : "AM";
                const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
                const formattedMinutes = minutes.toString().padStart(2, "0");

                return `${formattedHours}:${formattedMinutes} ${period}`;
            } catch (_) {
                return '';
            }
        }


    }
};
</script>