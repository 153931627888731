<style scoped>
.personDetailsButton {
  border-radius: 5px;
  color: rgba(106, 106, 106, 0.346);
  border: solid rgba(106, 106, 106, 0.346) 1px;
  margin-top: 20px;
  font-size: 12px;
  padding: 10px 20px;
}

.popupContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.242);
}
</style>

<script>
import SideNavbar from "@/components/menu/SideNavbarTeam.vue";
import HeaderDashboard from "@/components/menu/headerdashboard.vue";
import api from "@/boot/axios.js";

export default {
  data() {
    return {
      employeeList: [],
      showPopup: false,
      selected: "",
      taskList: [],
      priorities: [{ name: "Low" }, { name: "Medium" }, { name: "High" }],
      form: {
        title: "",
        client_name: "",
        description: "",
        address: "",
        lat: "",
        long: "",
        priority: "",
      },
      currentPage: 1,
      itemsPerPage: 100,
      totalTasks: 0,
      isSaving: false,
      query: '',
    };
  },
  components: {
    SideNavbar,
    HeaderDashboard,
  },

  mounted() {
    this.getTask();
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalTasks / this.itemsPerPage);
    },
  },
  methods: {
    async getTask() {
      await api
        .get(`api/task/v1/task/?page=${this.currentPage}&search=${this.query}`)
        .then((res) => {
          this.taskList = res.data?.results;
          this.taskListID = res.data?.results?.id;
          this.totalTasks = res.data.count;
          console.log(this.taskList, "GET all task");
          console.log(this.taskListID, "GET id");
        });
    },
    modelPopon() {
      this.showPopup = true;
    },
    submit() {
      this.isSaving = true;
      api
        .post("api/task/v1/task/", this.form)
        .then((res) => {
          console.log(res.status, "POST OKK??");
          this.showPopup = false;
          this.$toast.success(`Assigned successfully`, {
            position: "top",
          });
          setTimeout(() => {
            window.location.reload();
          }, 800);
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    fetchPageData(pageDirection) {
      if (pageDirection === "previous" && this.currentPage > 1) {
        this.currentPage--;
      } else if (
        pageDirection === "next" &&
        this.currentPage < this.totalPages
      ) {
        this.currentPage++;
      }
      this.getTask();
    },
    async deleteTask(task) {
      if (task.id || false) {
        if (
          confirm(`are you sure, you want to delete ${task.title || ""}?`)
        ) {
          try {
            if (task.id || false) {
              await api.delete(`api/task/v1/task/${task.id}/`)
              this.$toast.success(`Task delete successfully`, {
                position: "top",
              });
            }
          } catch (err) {
            let msg = '';
            for (let e in err.response?.data || []) {
              msg += err.response.data[e].join(' ');
            }
            if (msg.length || false) {
              this.$toast.error(`${msg}`, {
                position: "top",
              });
            } else {
              this.$toast.error(`Something went wrong! please refresh.`, {
                position: "top",
              });
            }
          }
          this.getTask();
        }
      }

    },
  },
};
</script>

<template>
  <div>
    <div>
      <!-- popup  -->
      <div v-if="showPopup">
        <div class="popupContainer" style="z-index: 9;">
          <div class="bg-white w-[50%] h-[70%] py-7 px-8 z-30">
            <div class="w-full flex justify-end">
              <button class="" @click="showPopup = !showPopup">
                <img :src="require(`@/assets/icons/close.svg`)" alt="searchLogo" class="h-[16px] w-[16px]" />
              </button>
            </div>
            <div class="text-sm dark:text-gray-600">Add Task</div>
            <hr class="mt-4" />
            <div class="mt-4 pl-5">
              <form @submit.prevent="submit" class="form-layout my-3 text-start">
                <div class="row my-4">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="text-start">Client Name</label>
                      <input type="text" class="form-control" placeholder="Type Client Name" v-model="form.client_name"
                        required />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label class="text-start">Title</label>
                      <input type="text" class="form-control" placeholder="Title of the Task" v-model="form.title"
                        required />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label class="text-start">Priority</label>
                      <select class="form-control" v-model="form.priority">
                        <option :value="priority.name" v-for="priority in priorities" :key="priority.name">
                          {{ priority.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="text-start">Description</label>
                      <input type="text" class="form-control" placeholder="Type Description" v-model="form.description"
                        required />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="text-start">Address</label>
                      <input type="text" class="form-control" placeholder="Type Address" v-model="form.address"
                        required />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label class="text-start">Lat</label>
                      <input type="text" class="form-control" placeholder="Type Lat" v-model="form.lat" required />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label class="text-start">Long</label>
                      <input type="text" class="form-control" placeholder="Type Long" v-model="form.long" required />
                    </div>
                  </div>
                </div>

                <div class="text-end">
                  <button type="submit" class="btn btn-outline-primary" :disabled="isSaving" @click="submit">
                    {{ isSaving ? "Saving..." : "Save changes" }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- End of popup  -->
    </div>
    <SideNavbar />
    <HeaderDashboard title="Task" />
    <div></div>
    <div class="ml-sidebar">
      <div class="flex mt-5">
        <div class="text-end w-full flex justify-between mx-4">
          <div class="form-group flex">
            <input type="text" class="form-control me-2" v-model="query" placeholder="Task id, title, client name ... " />
            <button type="button" class="btn btn-outline-primary" @click="getTask">Search</button>
          </div>
          <div>
            <button type="button" class="btn text-button" @click="modelPopon">
              <i class="fa fa-plus text-info"></i> Add another
            </button>
          </div>
        </div>
      </div>
      <div class="mx-4 app-table mt-2">
        <div class="appscroll" style="height: 65vh;">
          <table class="table table-poi table-striped">
            <thead class="position-sticky top-0 text-xs">
              <tr class="text-start">
                <th>ID</th>
                <th>Title</th>
                <th>Client Name</th>
                <th>Description</th>
                <th>Address</th>
                <th>Lat</th>
                <th>Long</th>
                <th>Priority</th>
                <th>Total Assign</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-start" v-for="task in taskList" :key="task.id">
                <td>{{ task.id }}</td>
                <td>{{ task.title }}</td>
                <td>{{ task.client_name }}</td>
                <td>{{ task.description }}</td>
                <td>
                  <div class="">{{ task.address }}</div>
                </td>
                <td>
                  <div class="badge badge-success">{{ task.lat }}</div>
                </td>
                <td>
                  <div class="badge badge-orange">{{ task.long }}</div>
                </td>
                <td>
                  <div class="badge badge-purple">
                    {{ task.priority }}
                  </div>
                </td>
                <td>{{ task.task_assignee_count || 0 }}</td>
                <td>
                  <router-link class="btn" :to="`/assigned-task/${task.id}`">
                    <i class="fa fa-edit text-info"></i>
                  </router-link>
                  <button type="button" class="btn" @click="() => deleteTask(task)">
                    <i class="fa fa-trash text-danger"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
      <div class="flex mt-2 mb-5">
        <div class="w-full flex gap-1 justify-end mr-4">
          <div>
            <button @click="fetchPageData('previous')" :disabled="currentPage === 1" class="btn btn-outline-primary">
              Previous
            </button>
          </div>
          <div>
            <button @click="fetchPageData('next')" :disabled="currentPage === totalPages" class="btn btn-outline-primary">
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
