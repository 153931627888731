<template>
    <div class="">
        <div class="h-[100vh] w-[100px] absolute hover:w-[200px]">
            <SidenavteamMenu />
        </div>

        <HeaderNavbar title="Departments" />

        <div class="ml-sidebar">
            <div class="">
                <div class="mx-4 my-3">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="d-flex align-items-center">
                            <h3 class="mb-0 mr-2 fw-bold">Tree View</h3>
                            <toggle-button @change="toggleShowTreeView" />
                        </div>
                    </div>
                </div>
                <div v-if="!showTreeView" class="mx-4 app-table mt-2">
                    <div class="row justify-content-center">
                        <div class="col-md-6">
                            <div v-if="departments.length || false" class="appscroll" style="max-height: 75vh;">
                                <table class="table table-poi table-striped table-bordered shadow-md m-0">
                                    <thead class="position-sticky top-0">
                                        <tr class="text-center">
                                            <th @click="sort">
                                                SL <i class="fas" :class="{
                                                    'fas fa-angle-down': !isSort,
                                                    'fas fa-angle-up': isSort
                                                }"></i>
                                            </th>
                                            <th>Department Name</th>
                                            <th>Total Employees</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="text-center" v-for="(department, index) in departments"
                                            :key="department.id">
                                            <td>{{ isSort ? (departments.length - index) : (index + 1) }}</td>
                                            <td>{{ department.name || "" }}</td>
                                            <td>
                                                {{ department.total_employee || '' }}
                                            </td>
                                            <td>
                                                <button type="button" class="btn" data-bs-toggle="modal"
                                                    data-bs-target="#departmentModal" @click="handleEdit(department)">
                                                    <i class="fa fa-edit text-info"></i>
                                                </button>
                                                <button type="button" class="btn" @click="deleteDepartment(department)">
                                                    <i class="fa fa-trash text-danger"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="d-flex justify-content-between align-items-center">
                                <button type="button" class="btn text-button text-sm" data-bs-toggle="modal"
                                    data-bs-target="#departmentModal" @click="resetForm">
                                    <i class="fa fa-plus text-info"></i> Add Department
                                </button>
                                <p class="text-xs text-start">Showing {{ departments.length || '0' }} entires</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="mx-4 mt-5 app-tree appscroll" style="height: 70vh;">
                    <vue-app-tree :data="departmentsTree" :horizontal="false" :collapsable="true">
                        <template #node="{ data }" class="shadow-xl">
                            {{ data.label }}
                        </template>
                    </vue-app-tree>
                </div>
            </div>
        </div>

        <div class="modal fade" id="departmentModal" tabindex="-1" aria-labelledby="departmentModalLabel"
            aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">
                        <h1 class="modal-title fs-5 text-start">
                            {{ form.isEdit ? "Edit" : "Add" }} Department
                        </h1>
                        <form @submit.prevent="submit" class="form-layout my-3 text-start">
                            <div class="form-group">
                                <label class="text-start">Name</label>
                                <input type="text" class="form-control" placeholder="Type Name"
                                    v-model="form.department.name" />
                            </div>
                            <div class="form-group form-group-check">
                                <input type="checkbox" class="mr-2" id="isChild" v-model="form.isChild"
                                    @change="changeIsChild" />
                                <label for="isChild">Is Child</label>
                            </div>
                            <div v-show="form.isChild" class="form-group">
                                <label class="text-start">Parent</label>
                                <select class="form-control" v-model="form.department.parent">
                                    <option value="">Select</option>
                                    <option v-for="department in departments" :key="department.id" :value="department.id">
                                        {{ department.name || "" }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label class="text-start">Color</label>
                                <div class="d-flex">
                                    <div v-for="item in systemColors" :key="item.id">
                                        <div class="border m-1 text-center cursor-pointer"
                                            :class="{ 'border-primary': (form.department['color'] || false) == item.id }"
                                            @click="() => selectColorItem(item.id)">
                                            <img :src="`https://lbsadmin.zedtrack.com${item.icon}`" alt=""
                                                style="height: 30px; width: 30px;">
                                            {{ item.color_name || '' }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="text-end">
                                <button type="submit" class="btn btn-outline-primary" :disabled="isSaving" @click="submit">
                                    {{ isSaving ? "Saving..." : "Save changes" }}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import SidenavteamMenu from "@/components/menu/SideNavbarTeam.vue";
import HeaderNavbar from "@/components/menu/headerdashboard.vue";
import apis from "@/server/api/company/index";
import { mapState, mapActions } from "vuex";

export default {
    components: { SidenavteamMenu, HeaderNavbar },

    data: () => ({
        isSort: false,
        showTreeView: false,
        isSaving: false,
        departments: [],
        departmentsTree: {
            label: "",
            expand: true,
            children: [],
        },
        form: {
            isEdit: false,
            isChild: false,
            department: {
                name: "",
                parent: "",
            },
        },
    }),

    computed: {
        ...mapState("core", ["systemColors",]),
    },

    mounted() {
        this.init();
    },

    methods: {
        ...mapActions("core", [
            "getSystemColors",
        ]),

        async init() {
            this.departments = await apis.getDepartments();
            this.handleDepartmentsTree();
            this.getSystemColors();
        },

        handleEdit(data) {
            this.form.isEdit = true;
            this.form.department = { ...data };
            if (data.parent || false) {
                this.form.isChild = true;
            } else {
                this.form.isChild = false;
            }
        },

        async submit() {
            this.isSaving = true;
            if (this.validation()) {
                if (this.form.isEdit) {
                    this.handleUpdate();
                } else {
                    this.handleStore();
                }
            }
        },

        sort() {
            const temp = this.departments.reverse();
            this.departments = temp;
            this.isSort = !this.isSort;
        },

        async handleUpdate() {
            let success = false;
            if (this.form.department.id || false) {
                const response = await apis.updateDepartment(this.form.department);
                if (response.status || false) {
                    this.$toast.success(`Department update successfully`, {
                        position: "top",
                    });
                    success = true;
                } else {
                    if (response.message.length || false) {
                        this.$toast.error(`${response.message}`, {
                            position: "top",
                        });
                    } else {
                        this.$toast.error(`Something went wrong! please refresh.`, {
                            position: "top",
                        });
                    }
                }
            } else {
                this.$toast.error(`Something went wrong! please refresh.`, {
                    position: "top",
                });
            }

            if (success) {
                this.resetForm();
                setTimeout(() => {
                    this.$router.go();
                }, 800);
            }
        },

        async handleStore() {
            let success = false;
            const response = await apis.storeDepartment(this.form.department);
            if (response.status || false) {
                this.$toast.success(`Department add successfully`, {
                    position: "top",
                });
                success = true;
            } else {
                if (response.message.length || false) {
                    this.$toast.error(`${response.message}`, {
                        position: "top",
                    });
                } else {
                    this.$toast.error(`Something went wrong! please refresh.`, {
                        position: "top",
                    });
                }
            }

            if (success) {
                this.resetForm();
                setTimeout(() => {
                    this.$router.go();
                }, 800);
            }
        },

        async deleteDepartment(department) {
            if (department.id || false) {
                if (
                    confirm(`are you sure, you want to delete ${department.name || ""}?`)
                ) {
                    const response = await apis.deleteDepartment(department.id);
                    if (response.status || false) {
                        this.$toast.success(`Department delete successfully`, {
                            position: "top",
                        });
                    } else {
                        if (response.message.length || false) {
                            this.$toast.error(`${response.message}`, {
                                position: "top",
                            });
                        } else {
                            this.$toast.error(`Something went wrong! please refresh.`, {
                                position: "top",
                            });
                        }
                    }
                    this.init();
                }
            }
        },

        validation() {
            if (!(this.form.department.name.length || false)) {
                this.$toast.error(`Department name field is required`, {
                    position: "top",
                });
                return false;
            }

            if (this.form.department.parent == this.form.department.id) {
                this.$toast.error(`Department child cann't be same`, {
                    position: "top",
                });
                return false;
            }

            return true;
        },

        resetForm() {
            this.form = {
                isEdit: false,
                isChild: false,
                department: {
                    name: "",
                    parent: "",
                },
            };
        },

        changeIsChild(e) {
            this.$nextTick(() => {
                if (!this.form.isChild) {
                    this.form.department.parent = "";
                }
            });
        },

        handleDepartmentsTree() {
            this.departmentsTree.children = this.createNestedStructure(
                this.departments,
                null
            );
        },

        createNestedStructure(data, parentId = null) {
            const children = data.filter((item) => item.parent === parentId);

            if (children.length === 0) {
                return [];
            }

            return children.map((child) => ({
                ...child,
                label: child.name,
                children: this.createNestedStructure(data, child.id),
            }));
        },

        toggleShowTreeView() {
            this.showTreeView = !this.showTreeView;
        },

        selectColorItem(colorId) {
            this.form.department['color'] = colorId;
        },
    },
};
</script>