const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default class Utils {
  formatTime(dateTimeString) {
    const s = dateTimeString;
    let d = new Date(s);
    let date = d.toDateString();
    let timeString = d.toTimeString();

    let tDate = new Date(d);
    let fDate =
      tDate.getDate() +
      " " +
      month[tDate.getMonth()] +
      ", " +
      tDate.getFullYear();
    const [hourString, minute] = timeString.split(":");
    const hour = +hourString % 24;
    return (
      fDate +
      " " +
      (hour % 12 || 12) +
      ":" +
      minute +
      (hour < 12 ? " AM" : " PM")
    );
  }
}
