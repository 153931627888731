import DepartmentApi from "./department";
import DesignationApi from "./designation";
import PoiApi from "./poi";
import ShiftApi from "./shift";
import reportApi from "./report";
import appearanceApi from "./appearance";
import holidayApi from "./holiday";
import geofenceApi from "./geofence";

export default {
    ...DepartmentApi,
    ...DesignationApi,
    ...PoiApi,
    ...ShiftApi,
    ...reportApi,
    ...appearanceApi,
    ...holidayApi,
    ...geofenceApi,
}